@import "../../styles/common";

.account-register-container {
  background-color: #EFEFEF;
  height          : calc(100% - 200px);
  width           : 100%;

  display       : flex;
  align-items   : center;
  flex-direction: column;
  font-family   : "Roboto Condensed", sans-serif;


  input[type="radio"] {
    position    : relative;
    top         : 1px;
    margin-right: 5px;
    cursor      : pointer;
  }

  // input[type='radio']:checked:after {
  //   width           : 9px;
  //   height          : 9px;
  //   border-radius   : 13px;
  //   top             : -4px;
  //   left            : 2px;
  //   position        : relative;
  //   background-color: #FF6807;
  //   content         : '';
  //   display         : inline-block;
  //   visibility      : visible;
  //   border          : 1px solid grey;
  // }

  .commonTitle {
    color         : #283991;
    font-size     : 22px;
    font-weight   : 700;
    margin        : 10px 0 10px 0;
    text-transform: uppercase;
  }

  .section-title {
    display: flex;

    @include themify($themes) {
      background-color: themed("CreateAccount-section-title-bg");
      color           : themed("CreateAccount-section-title-color");
    }

    padding         : 10px 0px 10px 10px;
    font-size       : 20px;
    font-weight     : 500;
    justify-content : flex-start;
    border-radius   : 5px 5px 0 0;
  }

  .flow-Create-acc {
    display: flex;
    width  : 65%;

    @media (max-width: 767px) {
      width    : 100%;
      font-size: 10px;
    }

    justify-content: center;

    .wizard {
      width    : 100%;
      max-width: 100%;
      margin   : 0 0 10px 0;
    }
  }

  .content-register {
    width  : 100%;
    display: grid;
    color  : #212121;

    .section-stepbystep {
      margin-bottom: 0;
      height       : 100%;

      .required-asterisk {
        @include themify($themes) {

          color: themed("CreateAccount-section-asterisk-color");
        }
      }
    }

    .noteThing {
      @include themify($themes) {

        background-color: themed("CreateAccount-section-title-bg");
      }

      padding : 10px 10px 0 20px;

      hr {
        margin: 5px 0 0 0;
      }

      // Style checkbox
      .container-checkmark {
        display            : block;
        position           : relative;
        padding-left       : 35px;
        margin-bottom      : 0px;
        cursor             : pointer;
        font-size          : 22px;
        -webkit-user-select: none;
        -moz-user-select   : none;
        -ms-user-select    : none;
        user-select        : none;
      }

      .container-checkmark input {
        position: absolute;
        opacity : 0;
        cursor  : pointer;
        height  : 0;
        width   : 0;
      }

      .checkmark {
        position        : absolute;
        top             : 3px;
        left            : 2px;
        height          : 13px;
        width           : 13px;
        background-color: #cccccc;
        border-radius   : 3px;
      }

      .container-checkmark:hover input~.checkmark {
        border: 1px solid #00c087;
      }

      .container-checkmark:hover input:checked~.checkmark {
        border: none;
      }

      .container-checkmark input:checked~.checkmark {
        background-color: #00c087;
      }

      .checkmark:after {
        content : "";
        position: absolute;
        display : none;
      }

      .container-checkmark input:checked~.checkmark:after {
        display: block;
      }

      .container-checkmark .checkmark:after {
        left        : 4px;
        top         : 1px;
        width       : 5px;
        height      : 10px;
        border      : solid #252525;
        border-width: 0 2px 2px 0;
        transform   : rotate(45deg);
      }

      // End
      .importantThing {
        font-size : 11px;
        color     : rgb(255, 65, 72);
        margin-top: 5px;
      }
    }


    .all-buttons {
      padding-top: 30px;

      @media (min-width: 576px) {
        display        : flex;
        flex-direction : row;
        justify-content: center;
      }

      .btn-back {
        border-radius: 50px;
        font-family  : $heading-font;
        font-style   : normal;
        font-weight  : 400;
        font-size    : 20px;
        line-height  : 1.11;
        text-align   : center;
        color        : #212121;
        background   : #DFDFDF;
        padding      : 11px 45px;
        border       : none;
        outline      : none;
        opacity      : 1;
        margin-right : 30px;

        &:hover {
          opacity: 0.9;
        }

        @media (max-width: 576px) {
          width        : 100% !important;
          margin-bottom: 20px;
        }
      }

      .btn-continue {
        margin-left  : 20px;
        border-radius: 50px;
        font-family  : $heading-font;
        font-style   : normal;
        font-weight  : 400;
        font-size    : 20px;
        line-height  : 1.11;
        text-align   : center;
        color        : #FFFFFF;
        background   : linear-gradient(89.34deg, #FF4913 1.12%, #FF5C00 95.42%);
        padding      : 11px 45px;
        border       : none;
        outline      : none;
        opacity      : 1;

        @media (max-width: 576px) {
          width        : 100% !important;
          margin-bottom: 10px;
          margin-left  : 0;
        }

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }

  .bottomCreAcc {
    width          : 100%;
    display        : flex;
    justify-content: center;

    .stepbystep {
      width: 75%;

      @include themify($themes) {

        background-color: themed("CreateAccount-section-title-bg");
      }

      display         : flex;
      justify-content : space-between;
      padding         : 10px;
      margin-bottom   : 15px;
      border-radius   : 0 0 5px 5px;

      button {
        @include themify($themes) {

          color: themed("CreateAccount-section-btn-color");
        }
      }

      .letBack {
        @include themify($themes) {

          background-color: themed("AccountRegister-sectionBody-btnBack-bg");
        }
      }

      .btn-support {
        @include themify($themes) {

          background-color: themed("AccountRegister-sectionBody-btnBack-bg");
        }

        margin-right : 10px;
      }
    }

    .confirmOTP {
      display       : flex;
      width         : 22%;
      flex-direction: column;
      align-items   : center;

      @include themify($themes) {

        background: themed("AccountRegister-step5otp-bg-color");
      }

      border-radius : 0 0 5px 5px;

      button {
        width        : 80%;
        margin-bottom: 10px;
      }

      .button-cancel {
        @include themify($themes) {

          background-color: themed("AccountRegister-step5otp-btnCancel-color");
        }
      }
    }
  }

  .modal-clear-session {
    max-width: 750px !important;
  }
}