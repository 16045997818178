/* Variable */
body {
    margin : 0;
    padding: 0;
}

.footer-desktop {
    // position: relative;
    overflow: hidden;
}

.footer-desktop .row-container {
    padding: 0 8.33%;
}

.footer-desktop .title-row {
    font-family  : "UTM Avo", Arial, Helvetica, sans-serif;
    font-size    : 20px;
    font-weight  : bold;
    line-height  : 1.11;
    color        : #fff;
    margin-bottom: 5px;
}

.footer-desktop .content-row {
    font-family: "SVN-Gotham Book", Arial, Helvetica, sans-serif;
    font-style : normal;
    font-weight: 325;
    font-size  : 12px;
    line-height: 1.5416;
    color      : #fff;
}

.footer-desktop .title {
    font-family  : "Open Sans", Arial, Helvetica, sans-serif;
    font-style   : normal;
    font-weight  : 600;
    font-size    : 18px;
    line-height  : 2.00;
    color        : #fff;
    margin-bottom: 36px;
}

@media (max-width: 1279px) {
    .footer-desktop .title {
        margin-bottom: 20px;
    }
}

.footer-desktop .text {
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    font-style : normal;
    font-weight: 600;
    font-size  : 12px;
    line-height: 1.58;
    color      : #fff;
}

.footer-desktop a.text {
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    font-style : normal;
    font-weight: 600;
    font-size  : 12px;
    line-height: 1.58;
    color      : #fff;
    display    : block;
}

.footer-desktop a.text:hover {
    cursor         : pointer;
    color          : #FF4913;
    text-decoration: none;
}

.footer-desktop .top-footer {
    background    : linear-gradient(180deg, #283991 0%, #202E75 100%);
    padding-top   : 15px;
    padding-bottom: 40px;
}

@media (max-width: 767px) {
    .footer-desktop .top-footer {
        padding-top: 40px;
    }
}

.footer-desktop .top-footer .row-container {
    display    : flex;
    align-items: center;
}

@media (max-width: 767px) {
    .footer-desktop .top-footer .row-container {
        display: block;
    }
}

.footer-desktop .top-footer img {
    padding-right: 90px;
}

@media (max-width: 1439px) {
    .footer-desktop .top-footer img {
        padding-right: 60px;
    }
}

@media (max-width: 767px) {
    .footer-desktop .top-footer img {
        padding-right: 0;
    }
}

.footer-desktop .top-footer .title-footer {
    margin-top: 35px;
}

@media (max-width: 767px) {
    .footer-desktop .top-footer .title-footer {
        margin-top: 25px;
    }
}

.footer-desktop .footer-main {
    background: #283991;
}

.footer-desktop .footer-main .row-container {
    display       : flex;
    padding-top   : 100px;
    padding-bottom: 90px;
}

@media (max-width: 1279px) {
    .footer-desktop .footer-main .row-container {
        padding-top   : 70px;
        padding-bottom: 40px;
    }
}

@media (max-width: 767px) {
    .footer-desktop .footer-main .row-container {
        display       : block;
        padding-bottom: 20px;
    }
}

.footer-desktop .footer-main .box-footer1 {
    width        : 30%;
    padding-right: 15px;
    position     : relative;
}

@media (max-width: 767px) {
    .footer-desktop .footer-main .box-footer1 {
        width        : 100%;
        padding-right: 0;
        margin-bottom: 40px;
    }
}

.footer-desktop .footer-main .box-footer1:after {
    content   : '';
    position  : absolute;
    top       : 0;
    right     : 0;
    width     : 1px;
    height    : 100%;
    background: linear-gradient(180deg, rgba(69, 87, 177, 0) 0%, #4557B1 46.88%, rgba(69, 87, 177, 0) 100%);
}

@media (max-width: 767px) {
    .footer-desktop .footer-main .box-footer1:after {
        display: none;
    }
}

.footer-desktop .footer-main .box-footer1 .box-item {
    padding-left : 40px;
    position     : relative;
    margin-bottom: 19px;
}

.footer-desktop .footer-main .box-footer1 .box-item img {
    position: absolute;
    top     : 0;
    left    : 0;
}

.footer-desktop .footer-main .box-footer1 .box-23 {
    display: inline-block;
}

.footer-desktop .footer-main .box-footer1 .box-23 .text {
    padding-top: 5px;
}

.footer-desktop .footer-main .box-footer1 .box-connect {
    margin-top: 20px;
}

.footer-desktop .footer-main .box-footer1 .box-connect a {
    margin-right: 15px;
}

.footer-desktop .footer-main .box-footer2 {
    width  : 70%;
    padding: 0 0 0 8%;
}

@media (max-width: 767px) {
    .footer-desktop .footer-main .box-footer2 {
        width  : 100%;
        padding: 0;
    }
}

@media (max-width: 1279px) {
    .footer-desktop .footer-main .box-footer2 .box-menu-footer {
        margin-bottom: 20px;
    }
}

.footer-desktop .footer-main .box-footer2 ul {
    padding: 0;
    margin : 0;
}

.footer-desktop .footer-main .box-footer2 ul li {
    list-style: none;
}

.footer-desktop .footer-main .box-footer2 a.text {
    margin-bottom: 8px;
    color        : #7578BD;
    position     : relative;
    overflow     : hidden;
    max-width    : 100%;
    text-overflow: ellipsis;
    display      : inline-block;
}

.footer-desktop .footer-main .box-footer2 a.text:after {
    content         : "";
    position        : absolute;
    left            : 0px;
    right           : 0px;
    bottom          : 0px;
    height          : 1px;
    transition      : transform 0.3s ease-in-out 0s;
    background-color: #F04D22;
    opacity         : 0;
    transform       : translateX(-100%);
}

.footer-desktop .footer-main .box-footer2 a.text:before {
    content         : "";
    position        : absolute;
    left            : 0px;
    right           : 0px;
    bottom          : 0px;
    height          : 1px;
    transition      : transform 0.3s ease-in-out 0s;
    background-color: #F04D22;
    opacity         : 1;
    transform       : translateX(100%);
}

.footer-desktop .footer-main .box-footer2 a.text:hover {
    color: #fff;
}

.footer-desktop .footer-main .box-footer2 a.text:hover:after {
    opacity  : 1;
    transform: translateX(0px);
}

.footer-desktop .footer-main .box-footer2 a.text:hover:before {
    opacity  : 0;
    transform: translateX(0%);
}

.footer-desktop .footer-copyright .text-copy {
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    font-style : normal;
    font-weight: 600;
    font-size  : 13px;
    line-height: 1.7066;
    padding    : 17px 20px 13px;
    color      : #1E2F86;
    text-align : center;
}
//change logo
.top-footer {
    .row-container{
        .logo{
            // margin-top: 35px;
        }
    }
}

/*# sourceMappingURL=../temp/maps/footer.css.map */