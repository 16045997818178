.active-error,
.active-success {
    background: #E5E5E5;
    color     : #212121;



    .title {
        text-transform: uppercase;
        text-align    : center;
        font-size     : 18px;
        padding-top   : 22px;
    }

    .content {

        padding-top: 10px;
        text-align : center;
        font-size  : 18px;

        .child {
            padding-top: 10px;

            &.info {
                font-size     : 20px;
                text-transform: uppercase;
                color         : red;
            }

            &.second {
                padding-bottom: 30px;
            }
        }

    }
}

.active-success {}