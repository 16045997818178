@import "../../styles/common";


#content {
    height: 100%;
    margin: 0;

    .TestCamFace {
        // background-image   : url('../../../../src/assets/images/bg-account-register-container.png');
        background-position: center;
        background-repeat  : no-repeat;
        background-size    : cover;
        height             : 100%;
        width              : 100%;

        // display        : flex;
        display        : inherit !important;
        justify-content: center;
        align-items    : center;

        .carousel-root {

            .control-dots,
            .carousel-status {
                display: none !important;
            }

        }

        .carousel .slide img {
            width: auto !important;
        }



        .react-record-webcam__wrapper {
            position: relative;

            .div-btn-video {
                position     : absolute;
                margin-top   : 255px;
                /* text-align: center; */
                width        : 100%;
                z-index      : 10;
            }

            .react-record-webcam__video {
                position: absolute;
                z-index : 1;
            }
        }


        .fa-camera {
            font-size: 15px;
        }

        .title {
            color      : #FFFFFF !important;
            font-size  : 16px !important;
            font-weight: 700 !important;
        }

        .txt-content {
            // color: #FFFFFF;
            width      : 80%;
            padding-top: 10px;
            text-align : center;
            display    : inherit !important;
            margin     : 0 auto;
        }

        .lable_content {
            width  : 100%;
            padding: 5px;

            .lable_left {
                width     : 50%;
                height    : 25px;
                float     : left;
                padding   : 5px;
                text-align: center;
            }

            .lable_right {
                width     : 50%;
                height    : 25px;
                float     : left;
                padding   : 5px;
                text-align: center;
            }
        }

        .lbl_error {
            width     : 100%;
            color     : red;
            padding   : 5px;
            text-align: center;
        }

        .img_content {
            width     : 100%;
            // padding: 5px;
            height    : 300px;

            .img_center {
                margin       : 0 auto;
                width        : 50%;
                // max-width : 300px;
                max-height   : 300px;
                padding      : 5px;
                position     : relative;
            }

            .img_left {
                width        : 50%;
                // max-width : 300px;
                max-height   : 300px;
                float        : left;
                padding      : 5px;
                position     : relative;
            }

            .img_right {
                width        : 50%;
                // max-width : 300px;
                max-height   : 300px;
                float        : left;
                padding      : 5px;
                position     : relative;
            }

            .img-div {
                background-color: #515151;
            }

            .img {
                // background-color   : #515151;
                // border          : 1px solid rgba(255, 255, 255, 0.7);
                // height          : 300px;
                // margin          : 5px;
                position: absolute;
                width   : 100%;
                top     : 0;
                left    : 0;
            }

            .img-border {
                background-color: #515151;
                margin          : 0px 5px 0px 0px;
                border          : 1px solid rgba(255, 255, 255, 0.7);
                height          : 300px;

                span {
                    width: 100% !important;
                }


                // background-image   : url("../../../assets/images/bg-camera.svg");
                // background-position: center;
                // background-size    : 230px 230px;
                // background-repeat  : no-repeat;
            }

            .bg-camera {
                text-align   : center;
                position     : absolute;
                width        : 100%;
                // margin-top: 250px;
                // margin    : 0px 0px 0px 0px;
                height       : 300px;

                background-image   : url("../../assets/images/bg-camera.svg");
                background-position: center;
                background-size    : 230px 230px;
                background-repeat  : no-repeat;
            }

            .img-btn {
                text-align: center;
                position  : absolute;
                width     : 100%;
                margin-top: 250px;
            }

            .img-btn-clear {
                text-align: right;
                position  : absolute;
                width     : 100%;
                margin-top: 0px;
            }

            video {
                min-width: 100% !important;
                max-width: 100% !important;
                width    : 100% !important;
            }

            .floatR {
                float: right;
            }
        }

        @include themify($themes) {

            .label-btn-upload {
                background-color: #313131;
                color           : #FFFFFF 50%;
                height          : 30px;
                line-height     : 30px;
                @include border-radius(5px);
                cursor      : pointer;
                padding     : 0px 20px 5px 20px;
                margin-right: 10px;

                &:hover {
                    background-color: #404040;
                    color           : #FFFFFF 50%;
                }
            }

            .label-btn-take-photo {
                background-color: themed('section-body-text-acitve');
                color           : #F5F5F5;
                height          : 30px;
                line-height     : 30px;
                @include border-radius(5px);
                cursor          : pointer;
                padding         : 0px 20px 5px 20px;
                // margin-bottom: 0.5rem;


                &:hover {
                    background-color: themed('form-control-focus-color');
                    ;
                }
            }

            .label-btn-clear-photo {
                // background-color: themed('section-body-text-acitve');
                color      : #d25a5a;
                height     : 30px;
                line-height: 30px;
                @include border-radius(5px);
                cursor          : pointer;
                padding         : 0px 20px 5px 20px;
                // margin-bottom: 0.5rem;


                &:hover {
                    // background-color: themed('form-control-focus-color');
                    color: red;
                }
            }

            .btn-take-photo {
                background-color: themed('section-body-text-acitve');
                color           : #F5F5F5;
                height          : 30px;
                line-height     : 30px;
                @include border-radius(5px);
                cursor          : pointer;
                padding         : 0px 20px 5px 20px;
                // margin-bottom: 0.5rem;


                &:hover {
                    background-color: themed('form-control-focus-color');
                    ;
                }
            }
        }





        @include themify($themes) {
            .intro-content {
                // display          : flex;
                display          : inherit !important;
                flex-direction   : row;
                // width         : 90%;
                height           : 80%;
                border-radius    : 5px;

                .img-intro {
                    width            : 40%;
                    height           : auto;
                    background-image : url('../../../src/assets/images/intro-acc-register.png');
                    background-repeat: no-repeat;
                    background-size  : cover;
                    border-radius    : 5px 0 0 5px;
                }

                .content-intro {
                    display  : grid !important;
                    color    : themed('AccountRegister-sectionBody-text-color');
                    padding  : 20px;
                    font-size: themed('AccountRegister-header-stepname-size');

                    .title {
                        color      : themed('AccountRegister-section-header-btnStart-color');
                        font-size  : themed('AccountRegister-sectionBody-title-size');
                        font-weight: 700;
                    }

                    span {
                        margin-bottom: 10px;
                        display      : inline-block;
                    }

                    .check {
                        color: themed('AccountRegister-sectionBody-btnStart-bg');

                        &:hover {
                            cursor: pointer;
                            filter: brightness(150%);
                        }
                    }

                    background-color   : themed('AccountRegister-section-background');
                    // width           : 60%;
                    border-radius      : 0 5px 5px 0;

                    .control-button {
                        button {
                            font-size : themed('AccountRegister-header-stepname-size');
                            color     : themed('AccountRegister-sectionBody-text-color');
                            box-shadow: none;
                        }

                        .letStart {
                            margin-right               : 10px;
                            outline                    : none;
                            background-color           : themed('AccountRegister-sectionBody-btnStart-bg');
                            color                      : themed('AccountRegister-section-header-btnStart-color');
                            text-align                 : center;
                            font-size                  : themed('AccountRegister-header-stepname-size');
                            transition                 : all 0.5s;
                            position                   : relative;
                            -webkit-transition-duration: 0.4s;
                            /* Safari */
                            transition-duration        : 0.4s;
                            text-decoration            : none;
                            overflow                   : hidden;
                            cursor                     : pointer;
                        }

                        .letStart:after {
                            content     : "";
                            background  : themed('AccountRegister-sectionBody-btnStart-effect');
                            display     : block;
                            position    : absolute;
                            padding-top : 200%;
                            padding-left: 350%;
                            margin-left : -200px !important;
                            margin-top  : -100%;
                            opacity     : 0;
                            transition  : all 0.8s
                        }

                        .letStart:active:after {
                            padding   : 0;
                            margin    : 0;
                            opacity   : 1;
                            transition: 0s
                        }

                        .letStart span {
                            margin-bottom: 0;
                            cursor       : pointer;
                            display      : inline-block;
                            position     : relative;
                            transition   : 0.5s;
                        }

                        .letStart span:after {
                            content   : '\00bb';
                            position  : absolute;
                            opacity   : 0;
                            top       : 0;
                            right     : -20px;
                            transition: 0.5s;
                        }

                        .letStart:hover span {
                            padding-right: 15px;
                        }

                        .letStart:hover span:after {
                            opacity: 1;
                            right  : 0;
                        }

                        .letBack {
                            background-color: themed('AccountRegister-sectionBody-btnBack-bg');
                        }
                    }
                }
            }

        }
    }
}