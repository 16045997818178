@import "variables";

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-red {
    color: #e63830 !important;
}

.text-green {
    color: #00FF00 !important;
}

.text-yellow {
    color: #ffd900 !important;
}


@import "mixins/css3.mixins";
@import "variables";
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

@mixin themifyDark($themes: $themes) {

    @each $theme,
    $map in $themes {
        .theme-#{$theme} & {
            $theme-map: () !global;

            @each $key,
            $submap in $map {
                $value    : map-get(map-get($themes, dark), '#{$key}');
                $theme-map: map-merge($theme-map, ($key: $value)) !global;
            }

            @content;
            $theme-map: null !global;
        }

    }
}

@mixin themifyLight($themes: $themes) {

    @each $theme,
    $map in $themes {
        .theme-#{$theme} & {
            $theme-map: () !global;

            @each $key,
            $submap in $map {
                $value    : map-get(map-get($themes, light), '#{$key}');
                $theme-map: map-merge($theme-map, ($key: $value)) !global;
            }

            @content;
            $theme-map: null !global;
        }
    }
}

@mixin themify($themes: $themes) {

    @each $theme,
    $map in $themes {

        .theme-#{$theme} & {
            $theme-map: () !global;

            @each $key,
            $submap in $map {
                $value    : map-get(map-get($themes, $theme), '#{$key}');
                $theme-map: map-merge($theme-map, ($key: $value)) !global;
            }

            @content;
            $theme-map: null !global;
        }

    }
}

@mixin themifyBody($themes: $themes) {

    @each $theme,
    $map in $themes {

        &.theme-#{$theme} {
            $theme-map: () !global;

            @each $key,
            $submap in $map {
                $value    : map-get(map-get($themes, $theme), '#{$key}');
                $theme-map: map-merge($theme-map, ($key: $value)) !global;
            }

            @content;
            $theme-map: null !global;
        }

    }
}

@function themed($key) {
    // @if map-has-key($theme-map,  $key) {
    //   @error "cannot find variable warning " + $key;
    //   $value: map-get($theme-map, $key);
    //   @return $value;

    // } @else {
    //   @error "cannot find variable secondary";
    //   @return map-get($colors, secondary);
    // }
    $value: map-get($theme-map, $key);

    @if($value) {
        // @debug "find variable $key value $value " + $key + ' : ' + $value;
        @return $value;
    }

    @else {
        @debug "cannot find variable $key value $value "+$key+' : '+$value;
        // @error "cannot find variable " + $key;
        @return 'none';
    }
}

@mixin center($xy:xy) {
    @if $xy==xy {
        bottom: auto;
        left  : 50%;
        right : auto;
        top   : 50%;
        @include transform(translateX(-50%) translateY(-50%));
    }

    @else if $xy==x {
        left : 50%;
        right: auto;
        @include transform(translateX(-50%));
    }

    @else if $xy==y {
        bottom: auto;
        top   : 50%;
        @include transform(translateY(-50%));
    }
}