@import '../../styles/variables';
@import '../../styles/mixin';

// .page-dangkithongtin1 {
//     position        : relative;
//     overflow        : hidden;
//     background-color: white;
// }

.register-step1 {

    .row_container {
        padding   : 55px 8% 100px;
        margin    : 0 18%;
        background: #E5E5E5;

        input {
            padding: 0 5px;
        }

        @media screen and (max-width: 1919px) {
            margin: 0 14%;
        }

        @media (max-width: 767px) {
            margin: 0;
        }


        .box-tieude {
            text-align: center;

            .title-row {
                font-family   : $heading-font;
                font-style    : normal;
                font-weight   : bold;
                font-size     : 30px;
                line-height   : 1.11;
                text-align    : center;
                text-transform: uppercase;
                color         : #283991;
                padding-bottom: 15px;
                position      : relative;

                &:after {
                    content   : '';
                    position  : absolute;
                    bottom    : 0;
                    left      : 0;
                    width     : 100%;
                    height    : 1px;
                    background: linear-gradient(90deg, rgba(193, 193, 193, 0) 0.06%, #C1C1C1 53.4%, rgba(193, 193, 193, 0) 98.54%);
                    z-index   : 5;
                }

                @media (max-width: 767px) {
                    font-size: 20px;
                }

                @media (max-width: 320px) {
                    font-size: 18px;
                }
            }

            .box-thong-tin {
                .lien-he {
                    font-family  : $body-font;
                    font-style   : normal;
                    font-weight  : bold;
                    font-size    : 13px;
                    line-height  : 1.54;
                    text-align   : center;
                    color        : #212121;
                    padding-top  : 5px;
                    margin-bottom: 10px;

                    a {
                        color: #283991;
                    }
                }

                .o-p,
                .note {
                    font-family: $body-font;
                    font-style : normal;
                    font-weight: bold;
                    font-size  : 13px;
                    line-height: 1.54;
                    text-align : center;
                    color      : #283991;
                }


            }
        }

        .box-luu-y {
            font-family: $body-font;
            font-style : normal;
            font-weight: normal;
            font-size  : 14px;
            line-height: 20px;
            padding-top: 15px;

            p {
                font-weight  : 700;
                display      : inline-block;
                margin-bottom: 0;
                color        : #283991;
            }
        }
    }

    .row_body {
        padding-top: 50px;

        .o-label-1 {
            font-family: $body-font;
            font-style : normal;
            font-weight: 325;
            font-size  : 13px;
            line-height: 1.54;
            color      : #212121;
            cursor     : pointer;
        }

        .o-label {
            font-family: $body-font;
            font-style : normal;
            font-weight: bold;
            font-size  : 15px;
            line-height: 1.33;
            color      : #212121;
        }

        .dpn {
            @media screen and (max-width: 1439px) {
                display: none;
            }
        }

        .row-wrapper {
            padding      : 0 135px;
            margin-bottom: 15px;

            @media screen and (max-width: 1549px) {
                padding: 0 115px;
            }

            @media screen and (max-width: 1439px) {
                padding: 0 95px;
            }

            @media screen and (max-width: 1365px) {
                padding: 0 85px;
            }

            @media screen and (max-width: 1279px) {
                padding: 0 60px;
            }

            @media screen and (max-width: 991px) {
                padding: 0;
            }

            ::-webkit-input-placeholder {
                /* Edge */
                font-style: italic;
            }

            :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                font-style: italic;
            }

            ::placeholder {
                font-style: italic;
            }

        }

        .input-1 {
            width        : 100%;
            border       : 1px solid #C4C4C4;
            box-sizing   : border-box;
            border-radius: 5px;
            background   : #E5E5E5;
            height       : 30px;

            &.error {
                border: 1px solid red;
            }
        }

        .text-err {
            color     : red;
            font-style: italic;
        }

        .box-click {
            position     : relative;
            padding-top  : 45px;
            text-align   : center;
            padding-right: 0;

            @media (max-width: 767px) {
                padding-top: 0;
            }

            .button-submit {
                text-align   : center;
                border-radius: 50px;
                font-family  : $heading-font;
                font-style   : normal;
                font-weight  : 400;
                line-height  : 1.11;
                text-align   : center;
                color        : #FFFFFF;
                background   : linear-gradient(89.34deg, #FF4913 1.12%, #FF5C00 95.42%);
                padding      : 10px 85px 14px;
                font-family  : $heading-font;
                font-style   : normal;
                font-weight  : bold;
                font-size    : 14px;

                border : none;
                outline: none;
                opacity: 1;
                cursor : pointer;

                &:hover {
                    opacity: 0.9;
                }

                @media screen and (max-width: 1549px) {
                    font-size: 18px;
                    padding  : 10px 70px;
                }

                @media screen and (max-width: 1023px) {
                    padding: 10px 45px;
                }

                @media (max-width: 767px) {
                    width        : 100%;
                    margin-bottom: 20px;
                }
            }


        }
    }

    .banner-page {
        position: relative;
        overflow: hidden;

        .banner-mobile {
            display: none;

            @media (max-width: 767px) {
                display: block;
            }

            @media (max-width: 320px) {
                display   : block;
                margin-top: 10px;
            }
        }

    }

}

.container-step1-confirm {
    font-family: "SVN-Gotham Book", Arial, Helvetica, sans-serif;
    font-style : normal;
    font-weight: bold;
    font-size  : 15px;
    line-height: 1.33;
    color      : #212121;

    .title {
        height   : 60px;
        color    : #FF4E12;
        font-size: 20px;
    }

    .content {
        height       : 45px;
        border-bottom: 1px solid #C4C4C4;
        line-height  : 4;
    }

}