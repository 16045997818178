@mixin text-under-line {
    position: relative;
    overflow: hidden;
    padding-bottom: 7px;

    &::before {
        left: 0px;
        right: 0px;
        bottom: 0px;
        height: 2px;
        content: "";
        position: absolute;
        transition: transform 0.2s ease-in-out 0s;
        background-color: red;
        opacity: 0;
        transform: translateX(-100%);
    }

    &::after {
        left: 0px;
        right: 0px;
        bottom: 0px;
        height: 2px;
        content: "";
        position: absolute;
        transition: transform 0.2s ease-in-out 0s;
        background-color: red;

        opacity: 1;
        transform: translateX(100%);
    }

    &:hover {
        &::before {
            opacity: 1;
            transform: translateX(0px);
        }

        &::after {
            opacity: 0;
            transform: translateX(0%);
        }
    }
}


@mixin full-hd-1080p {
    @media (max-width: 1920px) {
        @content;
    }
}

@mixin full-hd-1080p-zoom-120 {
    @media (max-width: 1600px) {
        @content;
    }
}

@mixin laptop-hdpi {
    @media (max-width: 1440px) {
        @content;
    }
}

@mixin ipad-pro-landscape {
    @media (max-width: 1366px) {
        @content;
    }
}

@mixin laptop-mdpi {
    @media (max-width: 1280px) {
        @content;
    }
}


@mixin ipad-landscape {
    @media (max-width: 1024px) {
        @content;
    }
}

@mixin ipad-potrait {
    @media (max-width: 768px) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: 639px) {
        @content;
    }
}