@import '../../../styles/variables';
@import '../../../styles/mixin';

.container-banner {
    width              : 100%;
    // position        : relative;
    overflow           : hidden;
    background-color   : white;

    .banner-page {
        position: relative;
        overflow: hidden;



        .banner-mobile {
            display: none;

            @media (max-width: 767px) {
                display: block;
                width  : 100%;
            }
        }

        .banner-desktop {
            display: block;
            width  : 100%;

            @media (max-width: 767px) {
                display: none;
            }
        }

        .title-banner {
            text-align: center;
            position  : absolute;
            bottom    : 22px;
            left      : 50%;
            transform : translateX(-50%);
            width     : 100%;
            z-index   : 5;

            @media (max-width: 767px) {
                bottom: 10px;
            }

            h4 {
                font-family  : $heading-font;
                font-style   : normal;
                font-weight  : 400;
                font-size    : 25px;
                line-height  : 130%;
                text-align   : center;
                color        : #FF7802;
                margin-bottom: 2px;

                span {
                    font-weight: bold;
                }

                @media (max-width: 767px),
                (max-width: 768px) {
                    font-size: 18px;
                }
            }

            h3 {
                font-family   : $heading-font3;
                font-style    : normal;
                font-weight   : 400;
                font-size     : 40px;
                line-height   : 130%;
                text-align    : center;
                color         : $white;
                text-transform: uppercase;

                @media (max-width: 767px),
                (max-width: 768px) {
                    font-size: 25px;
                }

                @media (max-width: 320px) {
                    font-size: 12px;
                }
            }
        }
    }

}