@import "../styles/common.scss";

.not-right-container {
  .not-right-body {
    .not-right-content {
      text-align: center;
      margin-top: 20px;

      @include themify($themes) {
        color    : themed('NotRight-text-color');
        font-size: themed('NotRight-font-size');

        a {
          color          : themed('NotRight-a-color');
          font-size      : themed('NotRight-a-font-size');
          font-weight    : themed('NotRight-a-font-weight');
          text-decoration: underline;
          cursor         : pointer;
        }

        a:hover {
          color    : themed('NotRight-a-color-hover');
          font-size: themed('NotRight-a-hover-font-size');

        }
      }
    }

    .text_content {
      text-align: center;
      margin-top: 20px;

      @include themify($themes) {
        color    : themed('NotRight-text-color');
        font-size: themed('NotRight-font-size');

        a {
          color          : themed('NotRight-a-color');
          font-size      : 14px;
          font-weight    : themed('NotRight-a-font-weight');
          text-decoration: underline;
          // cursor: pointer;
        }
      }
    }
  }
}