@import '../../../styles/variables';
@import '../../../styles/mixin';

.header {
    position  : fixed;
    width     : 100%;
    z-index   : 99;
    background: #fff;
}

.header-upper {
    height         : 70px;
    position       : relative;
    z-index        : 100;
    display        : flex;
    align-items    : center;
    justify-content: space-between;


    @include mobile {
        flex-wrap  : wrap;
        height     : auto;
        padding-top: 10px;
    }

    .header-logo {
        width         : 155px;
        flex-shrink   : 0;
        margin-left   : 121px;
        //change logo
        padding-bottom: 13px;
    }

    .header-user {
        display        : flex;
        align-self     : stretch;
        justify-content: flex-end;
        width          : 480px;
        flex-shrink    : 0;

        @include mobile {
            width: 100%;
        }

        .user-btn {
            display        : flex;
            align-items    : center;
            justify-content: center;
            color          : inherit;
            width          : 48%;
            padding-left   : 10px;
            padding-right  : 10px;

            @include mobile {
                width : 50%;
                height: 75px;
            }

            .header-user-icon {
                width       : 22px;
                height      : 22px;
                position    : relative;
                overflow    : hidden;
                margin-right: 7px;

                img {
                    position: absolute;
                    left    : 0;
                    right   : 0;
                    width   : 100%;
                    height  : 100%;

                    &.white {
                        opacity   : 0;
                        visibility: hidden;
                    }
                }

            }

            .header-user-text {
                @include text-under-line;

                &::before {
                    background: white;
                }

                &::after {
                    background: white;
                }

                .user-title {
                    display       : block;
                    font-family   : $body-font2;
                    font-style    : normal;
                    font-weight   : 450;
                    font-size     : 13px;
                    line-height   : 152%;
                    text-transform: uppercase;
                    color         : #4A4A4A;
                }

                .user-note {
                    display    : block;
                    font-family: $body-font2;
                    font-style : normal;
                    font-weight: 325;
                    font-size  : 9px;
                    line-height: 152%;
                    color      : #ABABAB;
                }
            }



            &._secondary-color {
                background     : linear-gradient(188.07deg, #FFA31A -6.48%, #F04D22 106.95%);
                color          : white;
                justify-content: center;

                .user-title,
                .user-note {
                    color: white;
                }

                .header-user-icon {
                    img {
                        &.dark {
                            opacity   : 0;
                            visibility: hidden;
                        }

                        &.white {
                            opacity   : 1;
                            visibility: visible;
                        }
                    }
                }

                .header-user-text {
                    &::before {
                        opacity  : 1;
                        transform: translateX(0px);
                    }

                    &::after {
                        opacity  : 0;
                        transform: translateX(0%);
                    }
                }
            }
        }
    }

    .header-user-login {
        background : linear-gradient(180deg, #FFA31A -6.48%, #F04D22 106.95%);
        width      : 365px;
        flex-grow  : 0;
        display    : flex;
        height     : 100%;
        align-items: center;
        color      : white;
        font-family: $body-font2;
        font-style : normal;
        font-weight: 325;
        font-size  : 9px;
        line-height: 152%;
        cursor     : pointer;
        overflow   : visible;
        position   : relative;

        @include mobile {
            height: 74px;
            width : 100%;
        }

        .grid-icon-wrapper {
            padding-left : 42px;
            padding-right: 34px;
        }

        .user-loggedin {
            display  : flex;
            position : relative;
            flex-grow: 1;

            &::after {
                content         : '';
                display         : block;
                height          : 58px;
                width           : 1px;
                background-color: white;
                position        : absolute;
                right           : 0;
                top             : 50%;
                transform       : translateY(-50%);
            }

            .user-avatar {
                padding-right: 12px;
            }

            .user-hello {
                display        : flex;
                flex-direction : column;
                justify-content: center;

                .name {
                    font-size     : 13px;
                    font-weight   : 450;
                    text-transform: uppercase;
                }
            }
        }

        .logout-button {
            text-align   : center;
            padding-right: 14px;
            padding-left : 10px;
        }

        .user-menu {
            position      : absolute;
            left          : 0;
            right         : 0;
            top           : 100%;
            display       : flex;
            flex-wrap     : wrap;
            padding-left  : 30px;
            padding-right : 55px;
            padding-top   : 30px;
            padding-bottom: 25px;
            background    : #F25A20;
            text-align    : center;
            text-transform: uppercase;
            opacity       : 0;
            visibility    : hidden;

            .menu-item {
                padding-left : 10px;
                padding-right: 10px;
                margin-bottom: 20px;

                .img-wrapper {
                    background-color: white;
                    border-radius   : 6px;
                    width           : 70px;
                    height          : 70px;
                    display         : flex;
                    align-items     : center;
                    justify-content : center;
                    margin-left     : auto;
                    margin-right    : auto;
                }

                span {
                    margin-top: 7px;
                    display   : block;
                }
            }
        }

        &:hover {
            .user-menu {
                opacity   : 1;
                visibility: visible;
            }
        }
    }

}