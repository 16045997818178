/*
 * General styles
 */
body,
html {
  background-image : "#fff";
  background-repeat: no-repeat;
  background-size  : cover;
  font-family      : $fontmain;
  font-size        : $base-size - 2px;
  height           : 100vh;
  overflow         : hidden;
  color            : $common-text-color;
}

.btn {
  font-family        : 'Roboto Condensed';
  font-size          : 15px;
  font-weight        : 500;
  padding            : 0;
  border             : none;
  cursor             : pointer;
  height             : 30px;
  line-height        : 30px;
  -moz-user-select   : none;
  -webkit-user-select: none;
  user-select        : none;

  i {
    font-size: $base-size - 3px;
  }

  &:focus {
    box-shadow: none;
  }

  &.btn-cancel,
  &.btn-page {
    background-color: #3a3a3a;
    color           : $common-text-color;

    &:hover {
      color           : $common-text-color;
      background-color: lighten(#3a3a3a, 5);
    }
  }

  &.btn-edit {
    color           : #00C087;
    background-color: transparent;

    &:hover {
      background-color: transparent;
      color           : darken(#00C087, 5);
    }
  }

  &.btn-view {
    color           : #fff;
    background-color: transparent;

    &:hover {
      background-color: transparent;
      color           : darken(#fff, 5);
    }
  }

  &.btn-delete {
    color           : #ff3737;
    background-color: transparent;

    &:hover {
      background-color: transparent;
      color           : darken(#ff3737, 5);
    }
  }

  &.btn-add,
  &.btn-assign {
    color           : $common-white;
    background-color: #00C087;

    &:hover {
      color           : $common-white;
      background-color: darken(#00C087, 5);
    }
  }
}

//ẩn nút tăng giảm input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin            : 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

label {
  color: darken($common-text-color, 20)
}

#password:invalid {
  font-family: $fontmain; // Fixed TH hien thi placeholder cho EDGE
}

#password:-webkit-input-placeholder {
  font-family: $fontmain; // CHROME 1
}

#password::-webkit-input-placeholder {
  font-family: $fontmain; //CHROME 2   EDGE 1
}

#password:-moz-placeholder {
  font-family: $fontmain; // Firefox 4-18
}

#password::-moz-placeholder {
  font-family: $fontmain; // Firefox 19+
}

#password:-ms-input-placeholder {
  font-family: $fontmain; // IE 10-11
}

#password::-ms-input-placeholder {
  font-family: $fontmain; // EDGE 2
}

#password::placeholder {
  font-family: $fontmain;
}

.content-container {
  // padding: 10px;


  // .title {
  //   font-weight: bold;
  //   font-size: $base-size;
  //   color: #fff;
  //   background-color: #121212;
  //   height: 40px;
  //   line-height: 40px;
  //   padding-left: 10px;
  //   border-radius: 4px 4px 0 0;
  // }

  // .content {
  //   background-color: #272727;

  //   .pagination {
  //     background-color: #1e1e1e;
  //     height: 45px;
  //     padding: 8px 0;

  //     select {
  //       color: #ffffff80;
  //       background-color: #313131;
  //       background-image: url("../assets/images/dropdown-white.svg") !important;
  //     }
  //   }
  // }
}

.modal {
  .modal-dialog {
    // margin: 0 auto !important;

    @media (max-width: 767px),
    (max-width: 768px) {
      margin: 0 auto !important;
    }

    .modal-content {
      box-shadow        : 2px 2px 4px 2px $box-shadow-color;
      -webkit-box-shadow: 2px 2px 4px 2px $box-shadow-color;

      .modal-header {
        padding         : 0 10px;
        height          : 48px;
        background-color: $header_rgb;
        color           : $common-white;
        border-bottom   : none;

        .modal-title {
          line-height: 48px;
          font-weight: 500;
          font-size  : $base-size + 1px;
        }

        .btn-close {
          color   : $common-white;
          position: absolute;

          i {
            line-height: 48px;
            font-size  : $base-size + 2px;
          }
        }
      }

      .modal-body {
        background-color: #252525;
        padding-bottom  : 0;

        .custom-form-group {

          input,
          select {
            color           : $text-in-light;
            background-color: #ccc;

            &.readonly,
            &:disabled {
              background-color: #9a9a9a;
            }
          }
        }
      }

      .modal-btn-container {
        height: 52px;

        .btn-container {
          height          : 52px;
          line-height     : 52px;
          padding-right   : 10px;
          background-color: #1e1e1e;

          .btn {
            width: 80px;

            &:first-child {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

.flex-scroll-content {
  flex    : 1 1 auto;
  position: relative;

  &.fixed {
    flex  : none;
    height: 217px;
  }
}

.flex-scroll-content-wrapper {
  bottom  : 10px;
  left    : 0;
  position: absolute;
  right   : 0;
  top     : 0;
}

.table {
  padding-bottom: 5px;
  color         : $common-text-color;

  thead {
    th {
      background-color: #1e1e1e;
      color           : $common-white;
      border          : none;
      border-top      : 1px solid #444;
      border-left     : 1px solid #444;
      border-bottom   : 1px solid #444;

      &:last-child {
        border-right: 1px solid #444;
      }
    }
  }

  tbody {
    tr {
      height: 32px;

      &:nth-child(odd) {
        background: #272727;
      }

      &:nth-child(even) {
        background: #1e1e1e;
      }

      td {
        border-left  : 1px solid #444;
        border-bottom: 1px solid #444;

        &:last-child {
          border-right: 1px solid #444;
        }
      }

      &:last-child {
        border-bottom: 1px solid #444;
      }
    }
  }
}

.react-bootstrap-table {
  .form-control {
    background-color: #ccc;
    color           : $common-text-color;
    border          : 1px solid $border;
  }

  input {
    &.form-control {
      color: $text-in-light;
    }

    &.custom-form-control {
      height      : 25px;
      padding-left: 5px;
    }
  }
}

::-webkit-scrollbar {
  height: 0;
  width : 5px;
}

::-webkit-scrollbar-track {
  background: darken($bg-scrollbar, 50);
}

::-webkit-scrollbar-thumb {
  background   : darken($bg-scrollbar, 20);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: darken($bg-scrollbar, 10);
}