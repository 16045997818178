@import '../../styles/variables';
@import '../../styles/mixin';


.register-step3 {
    position: relative;
    overflow: hidden;

    a {
        text-decoration: none;
        opacity        : 1;

        &:hover {
            opacity: 0.9;
        }
    }

    .row_container {
        padding   : 38px 5% 32px;
        margin    : 0 auto;
        max-width : 1192px;
        background: linear-gradient(90.12deg, #EFEFEF 22.13%, #F8F8F8 96.25%);

        @media screen and (max-width: 1919px) {
            margin: 0 14%;
        }

        @media screen and (max-width: 1280px) {
            margin: 0 10%;
        }

        @media (max-width: 767px) {
            margin: 0;
        }


        .box-tieude {
            text-align    : center;
            padding-bottom: 54px;

            .title-row {
                font-family   : $heading-font;
                font-style    : normal;
                font-weight   : bold;
                font-size     : 30px;
                line-height   : 1.11;
                text-align    : center;
                text-transform: uppercase;
                color         : #283991;
                padding-bottom: 18px;
                position      : relative;
                margin-bottom : 40px;

                &:after {
                    content   : '';
                    position  : absolute;
                    bottom    : 0;
                    left      : 10%;
                    width     : 80%;
                    height    : 1px;
                    background: linear-gradient(90deg, rgba(193, 193, 193, 0) 0.06%, #C1C1C1 53.4%, rgba(193, 193, 193, 0) 98.54%);
                    z-index   : 5;
                }
            }

        }

        .note-create-fail {
            position           : relative;
            padding-top        : 35px;
            padding-left       : 45px;
            padding-right      : 45px;
            padding-bottom     : 35px;
            min-height         : 240px;
            background-color   : white;
            max-width          : 530px;
            // text-align      : center;
            border-radius      : 10px;
            margin             : 0 auto;

            // justify-content: center;
            // align-items    : center;
            // display        : flex;
            .title {
                font-size: 20px;
                padding  : 10px 0;
            }

            font-size: 16px;
            color    : #2E6FB6;


        }
    }

    .box-luu-y {
        font-family: $body-font2;
        padding-top: 30px;
        font-family: $body-font;
        font-style : italic;
        font-weight: 300;
        font-size  : 12px;
        line-height: 20px;
        color      : #000000;
        display    : flex;

        p {
            font-weight  : 700;
            display      : inline-block;
            margin-bottom: 0;
            color        : #283991;
            padding-right: 10px;
            width        : 100px;
            text-align   : center;
        }
    }

    .text-xac-thuc {
        font-family   : $body-font;
        font-style    : normal;
        font-weight   : bold;
        font-size     : 14px;
        line-height   : 20px;
        color         : #212121;
        padding-bottom: 10px;
    }

    .box-content {
        display    : flex;
        align-items: center;

        @media (max-width: 991px) {
            display: block;
        }

        .box-img-video {
            width   : 50%;
            position: relative;
            overflow: hidden;

            @media (max-width: 991px) {
                width        : 100%;
                margin-bottom: 40px;
            }

            // &:after {
            //     content      : '';
            //     position     : absolute;
            //     background   : rgba(100, 100, 100, 0.4);
            //     border-radius: 10px;
            //     top          : 0;
            //     left         : 0;
            //     width        : 100%;
            //     height       : 100%;
            //     z-index      : 1;
            // }
        }

        .box-button {
            width: 50%;

            @media (max-width: 991px) {
                width: 100%;
            }
        }

        .img-avatar {
            border-radius: 10px;
            width        : 100%;
        }

        .img-play-video {
            position : absolute;
            top      : 50%;
            left     : 50%;
            transform: translate(-50%, -50%);
            z-index  : 2;
            cursor   : pointer;
            font-size: 50px;
        }

        .note {
            position   : absolute;
            bottom     : 0;
            left       : 0;
            padding    : 0 20px 8px;
            font-family: $body-font2;
            font-style : normal;
            font-weight: bold;
            font-size  : 14px;
            line-height: 20px;
            color      : #212121;

        }
    }

    .box-button {
        .button-click {
            background   : linear-gradient(89.34deg, #FF4913 1.12%, #FF7801 95.42%);
            border-radius: 10px;
            padding      : 13px 30px;
            display      : inline-block;
            margin-bottom: 5px;
            cursor       : pointer;
        }

        a {
            font-family: $body-font;
            font-style : normal;
            font-weight: bold;
            font-size  : 14px;
            line-height: 20px;
            color      : #FFFFFF;
        }

        text-align: center;
        padding   : 0 50px;

        @media (max-width: 1349px) {
            padding: 0 30px;
        }

        .icon-camera {
            padding-right: 13px;
        }

        .micro-chua-kich-hoat {
            background-color: rgba(#C4C4C4, 0.3);
            border-radius   : 10px;
            text-align      : center;
            padding         : 45px 20px 36px;
            margin-bottom   : 28px;

            .img-no-came {
                display: block;
                margin : 0 auto 12px;
            }
        }
    }

    .submit {
        text-align    : center;
        padding-top   : 70px;
        padding-bottom: 30px;

        .btn {
            border-radius: 50px;
            padding      : 12px 30px 11px;
            display      : inline-block;
            font-family  : $body-font;
            font-style   : normal;
            font-weight  : bold;
            font-size    : 14px;
            line-height  : 20px;
            min-width    : 250px;
            margin       : 10px 10px;
            background   : #DFDFDF;
            color        : #212121;
            height       : 43px;

            &:hover {
                // background: linear-gradient(89.34deg, #FF4913 1.12%, #FF7801 95.42%);
                // color     : #FFFFFF;
                opacity: 0.9;
            }
        }

        a {
            border-radius: 50px;
            padding      : 12px 30px 11px;
            display      : inline-block;
            font-family  : $body-font;
            font-style   : normal;
            font-weight  : bold;
            font-size    : 14px;
            line-height  : 20px;
            min-width    : 250px;
            margin       : 0 10px;
            background   : #DFDFDF;
            color        : #212121;

            &:hover {
                background: linear-gradient(89.34deg, #FF4913 1.12%, #FF7801 95.42%);
                color     : #FFFFFF;
            }
        }

        .gui {
            color     : #fff;
            background: linear-gradient(89.34deg, #FF4913 1.12%, #FF7801 95.42%) !important;
        }


        .gui-disable {
            background     : #DFDFDF !important;
            color          : #212121 !important;
            text-decoration: none;

            &:hover {
                background     : #DFDFDF !important;
                color          : #212121 !important;
                text-decoration: none;
            }
        }
    }


    .img_content {
        width     : 100%;
        // padding: 5px;
        height    : 300px;

        .img_center {
            margin             : 0 auto;
            // width        : 50%;
            // max-width : 300px;
            max-height         : 300px;
            // padding         : 5px;
            position           : relative;
        }

        .img_left {
            width        : 50%;
            // max-width : 300px;
            max-height   : 300px;
            float        : left;
            padding      : 5px;
            position     : relative;
        }

        .img_right {
            width        : 50%;
            // max-width : 300px;
            max-height   : 300px;
            float        : left;
            padding      : 5px;
            position     : relative;
        }

        .img-div {
            background-color: #515151;
        }

        .img {
            // background-color   : #515151;
            // border                : 1px solid rgba(255, 255, 255, 0.7);
            height             : 300px;
            // margin          : 5px;
            position           : absolute;

            @media (max-width: 767px) {
                width: 100%;
            }

            // top  : 0;
            // left : 0;
        }

        .img-border {
            // background-color: #515151;
            // margin          : 0px 5px 0px 0px;
            // border          : 1px solid rgba(255, 255, 255, 0.7);
            height       : 300px;
            border-radius: 10px;

            span {
                width: 100% !important;
            }


            // background-image   : url("../../../assets/images/bg-camera.svg");
            // background-position: center;
            // background-size    : 230px 230px;
            // background-repeat  : no-repeat;
        }

        .bg-camera {
            text-align   : center;
            position     : absolute;
            width        : 100%;
            // margin-top: 250px;
            // margin    : 0px 0px 0px 0px;
            height       : 300px;

            background-image   : url("../../assets/images/bg-camera.svg");
            background-position: center;
            background-size    : 230px 230px;
            background-repeat  : no-repeat;
        }

        .img-btn {
            text-align: center;
            position  : absolute;
            width     : 100%;
            margin-top: 250px;
        }

        .img-btn-clear {
            text-align: right;
            position  : absolute;
            width     : 100%;
            margin-top: 0px;
        }

        video {
            min-width   : 100% !important;
            // max-width: 200% !important;
            // width    : 100% !important;
            height      : 100% !important;
        }

        .floatR {
            float: right;
        }

        .carousel-root {

            .control-dots,
            .carousel-status {
                display: none !important;
            }

        }

        .carousel .slide img {
            width: auto !important;
        }

    }

    .txt-content {
        // color: #FFFFFF;
        width      : 80%;
        padding-top: 10px;
        text-align : center;
        display    : inherit !important;
        margin     : 0 auto;
    }

    .lable_content {
        width  : 100%;
        padding: 5px;

        .lable_left {
            width     : 50%;
            height    : 25px;
            float     : left;
            padding   : 5px;
            text-align: center;
        }

        .lable_right {
            width     : 50%;
            height    : 25px;
            float     : left;
            padding   : 5px;
            text-align: center;
        }
    }

    .lbl_error {
        width     : 100%;
        color     : red;
        padding   : 5px;
        text-align: center;
    }

    .fa-camera {
        font-size  : 15px;
        color      : #FFFFFF;
        font-weight: bold;
    }

    .re-take {
        color      : black;
        font-weight: bold;
    }

    .label-btn-clear-photo {
        // background-color: themed('section-body-text-acitve');
        color           : #d25a5a;
        height          : 30px;
        line-height     : 30px;
        border-radius   : 5px;
        cursor          : pointer;
        padding         : 0px 20px 5px 20px;
        // margin-bottom: 0.5rem;


        &:hover {
            // background-color: themed('form-control-focus-color');
            color: red;
        }
    }



}


.modal-error-ekyc,
.modal-edit-ekyc {

    border-radius: 10px;

    @media (min-width: 576px) {
        max-width: 550px !important;
    }

    .modal-content {
        border-radius: 10px !important;
        box-shadow   : none !important;
        border       : none !important;
    }

    button:disabled,
    button[disabled] {
        cursor: no-drop !important;
    }



    .thong-bao-popup {
        position        : relative;
        padding-top     : 35px;
        padding-left    : 45px;
        padding-right   : 45px;
        padding-bottom  : 35px;
        min-height      : 240px;
        background-color: white;
        max-width       : 530px;
        text-align      : center;
        border-radius   : 10px;

        .input-info {
            font-size   : 12px;
            color       : #FF4913;
            text-align  : left;
            padding-left: 15px;
            margin-top  : 5px;
            text-align  : center;
        }

        .img-icon-close-modal {
            position                 : absolute;
            right                    : 0;
            top                      : 0;
            width                    : 20px;
            height                   : 20px;
            display                  : block;
            // // background-image   : url(../images/icon-x-circle-orange.png);
            background-size          : 100% auto;
            background-repeat        : no-repeat;
            background-position      : center center;
            transform                : translate(50%, -50%);
            cursor                   : pointer;
        }

        .img-icon {
            margin-bottom: 25px;
        }

        .text-gradient {
            background             : linear-gradient(179.98deg, #283991 0.02%, #3298D1 99.98%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .o-h3 {
            font-weight   : bold;
            font-size     : 14px;
            line-height   : 143%;
            margin-bottom : 20px;
            font-family   : $body-font2;
            text-transform: uppercase;
        }

        .o-h4 {
            font-weight   : bold;
            font-size     : 18px;
            line-height   : 111%;
            margin-bottom : 20px;
            text-transform: uppercase;
            font-family   : $body-font2;
        }

        .o-wysiwyg {
            text-align : justify;
            font-weight: bold;
            font-size  : 14px;
            line-height: 143%;

            ul {
                list-style  : none;
                padding-left: 10px;

                li {
                    // position: relative;

                    &::before {
                        position              : relative;
                        width                 : 6px;
                        height                : 6px;
                        // // background-image: url(../images/icon-li-mark-blue.png);
                        content               : '';
                        display               : inline-block;
                        margin-left           : -14px;
                        margin-right          : 7px;
                    }
                }
            }


        }

        &.popup-text-black {
            padding-left : 15px;
            padding-right: 15px;
            max-width    : 550px;

            &::before {
                display: none;
            }

            .o-wysiwyg {
                color        : #212121;
                text-align   : center;
                margin-bottom: 30px;

                &.row-lower {
                    color: red;
                }
            }

            .btn-manhtq {
                margin-left : auto;
                margin-right: auto;
                font-size   : 14px;
                min-height  : 43px;
            }

            &.row-narrow {
                .o-wysiwyg {
                    &.row-upper {
                        margin-bottom: 15px;
                    }
                }
            }


            &.text-light {
                .o-wysiwyg {
                    font-weight: normal;

                    &.row-lower {
                        color: #7D7D7D;
                    }
                }
            }

        }

        .btn-wrapper {
            display        : flex;
            justify-content: center;

            .btn-manhtq {
                min-width   : 135px;
                margin-left : 10px;
                margin-right: 10px;
                font-weight : bold;
            }
        }




        .btn-manhtq {
            border-radius: 50px;
            max-width    : 297px;
            display      : block;

            font-family    : $heading-font;
            font-style     : normal;
            font-weight    : normal;
            font-size      : 20px;
            line-height    : 111%;
            text-align     : center;
            color          : #212121;
            min-height     : 2.15em;
            display        : flex;
            justify-content: center;
            align-items    : center;


            &.smaller {
                padding-left : 2em;
                padding-right: 2em;
            }

            @include mobile {
                padding-left : 5em;
                padding-right: 5em;

                &.smaller {
                    padding-left : 2em;
                    padding-right: 2em;
                }
            }

            &:hover {
                cursor: pointer;
            }

            span {
                color: inherit;
            }

            &.btn-grey {
                background: #DFDFDF;
            }

            &.btn-grey-bang-gia-1 {
                background: #C4C4C4;
                color     : #727272;
            }

            &.btn-orange {
                background: linear-gradient(89.34deg, #FF4913 1.12%, #FF5C00 95.42%);
                color     : white;
            }

            &.btn-transparent {
                border: 1px solid #121212;
            }

            &.btn-text2 {
                font-family   : $body-font2;
                font-style    : normal;
                font-weight   : bold;
                font-size     : 13px;
                line-height   : 106.19%;
                text-transform: uppercase;
                min-height    : 3.08em;
                white-space   : nowrap;
                margin-bottom : 15px;
            }

            &.btn-square {
                border       : 1px solid #B6B6B6;
                border-radius: 2px;
                font-weight  : bold;
                font-size    : 18px;
                color        : #535353;
                margin-bottom: 15px;
                min-height   : 2.7em;
                max-width    : unset;
            }

            &.btn-blue {
                background: #283991;
                color     : white;
            }

            &.btn-bo-loc {
                min-width: 140px;

                &::before {
                    content               : '';
                    display               : inline-block;
                    width                 : 15px;
                    height                : 10px;
                    margin-right          : 10px;
                    // background-image   : url(../images/icon-filter-white.png);
                    background-repeat     : no-repeat;
                    background-size       : 100% auto;
                    background-position   : center center;
                }
            }

            &.btn-dang-ky {
                display: inline-flex;

                &::before {
                    content               : '';
                    width                 : 16px;
                    height                : 16px;
                    display               : inline-block;
                    // background-image   : url(../images/icon-add-friend-white.png);
                    background-repeat     : no-repeat;
                    background-size       : 100% auto;
                    background-position   : center center;
                    margin-right          : 10px;
                    flex-shrink           : 0;
                }
            }

            &.btn-dat-mua {
                display      : inline-flex;
                min-height   : 2.39em;
                padding-right: 3em;

                &::before {
                    content               : '';
                    width                 : 22px;
                    height                : 17px;
                    display               : inline-block;
                    // background-image   : url(../images/icon-gio-hang.png);
                    background-repeat     : no-repeat;
                    background-size       : 100% auto;
                    background-position   : center center;
                    margin-right          : 10px;
                    flex-shrink           : 0;
                }
            }

            &.btn-green {
                background   : #009400;
                border-radius: 5px;
                color        : white;
                font-size    : inherit;
                min-height   : 1.92em;
                margin-top   : 2px;
                margin-bottom: 2px;
                min-width    : 90px;
            }
        }

        textarea {
            resize: none;
        }
    }
}