@import '../../styles/variables';
@import '../../styles/mixin';

.page-dangkithongtin3-xac-thuc-33 {
    position: relative;
    overflow: hidden;

    .row_container {
        padding   : 38px 5% 100px;
        margin    : 0 auto;
        max-width : 1192px;
        background: linear-gradient(90.12deg, #EFEFEF 22.13%, #F8F8F8 96.25%);

        @media screen and (max-width: 1919px) {
            margin: 0 14%;
        }

        @media (max-width: 1439px) {
            margin: 0 10%;
        }

        @media screen and (max-width: 1279px) {
            margin: 0 6%;
        }

        @media (max-width: 991px) {
            margin: 0;
        }

        .text-err {
            color     : red;
            font-style: italic;
        }

        .box-tieude {
            text-align    : center;
            padding-bottom: 50px;

            .title-row {
                font-family   : $heading-font;
                font-style    : normal;
                font-weight   : bold;
                font-size     : 30px;
                line-height   : 1.11;
                text-align    : center;
                text-transform: uppercase;
                color         : #283991;
                padding-bottom: 18px;
                position      : relative;

                &:after {
                    content   : '';
                    position  : absolute;
                    bottom    : 0;
                    left      : 10%;
                    width     : 80%;
                    height    : 1px;
                    background: linear-gradient(90deg, rgba(193, 193, 193, 0) 0.06%, #C1C1C1 53.4%, rgba(193, 193, 193, 0) 98.54%);
                    z-index   : 5;
                }
            }
        }

        .tien-trinh-dang-ky {
            margin-bottom: 80px;

            @media (max-width: 767px) {
                margin-bottom: 40px;
            }
        }

        .box-dang-nhap {
            padding: 0 25%;


            @media (max-width: 991px) {
                padding: 0 20%;
            }

            @media (max-width: 767px) {
                padding: 0;
            }

            .row-info {
                margin-bottom  : 25px;
                padding-bottom : 5px;
                font-family    : $body-font;
                font-style     : normal;
                font-weight    : bold;
                font-size      : 14px;
                line-height    : 20px;
                color          : #212121;
                display        : flex;
                justify-content: space-between;
                width          : 100%;

                &.err-message {
                    color: red;
                }

                .title-info {
                    display: inline-block;
                    width  : 35%;
                }

                .value-info {
                    width: 65%;

                    i {
                        position   : relative;
                        right      : 0px;
                        float      : right;
                        display    : block;
                        top        : -30px;
                        cursor     : pointer;
                        line-height: 20px;
                        padding    : 5px;
                    }
                }
            }



            input {
                width        : 100%;
                border       : 1px solid #C4C4C4;
                box-sizing   : border-box;
                border-radius: 5px;
                background   : none;
                height       : 30px;
                outline      : none;
                padding-left : 10px;
                font-family  : $body-font;
                font-style   : italic;
                font-weight  : 300;
                font-size    : 14px;
                line-height  : 20px;
                color        : #212121;

                &.error {
                    border: 1px solid red;
                }

                // style placeholder color and hide when click
                /*WebKit browsers*/
                &::-webkit-input-placeholder {
                    color  : #212121;
                    opacity: 1;
                }

                &:focus::-webkit-input-placeholder {
                    color: transparent;
                }

                /* Mozilla Firefox 4 to 18 */
                &:-moz-placeholder {
                    color  : #212121;
                    opacity: 1;
                }

                &:focus:-moz-placeholder {
                    color: transparent;
                }

                /* Mozilla Firefox 19+ */
                &::-moz-placeholder {
                    color  : #212121;
                    opacity: 1;
                }

                &:focus::-moz-placeholder {
                    color: transparent;
                }

                /* Internet Explorer 10+ */
                &:-ms-input-placeholder {
                    color  : #212121;
                    opacity: 1;
                }

                &:focus:-ms-input-placeholder {
                    color: transparent;
                }

                &.input-id {
                    &::-webkit-input-placeholder {
                        color  : #646464;
                        opacity: 1;
                    }

                    &:focus::-webkit-input-placeholder {
                        color: transparent;
                    }

                    /* Mozilla Firefox 4 to 18 */
                    &:-moz-placeholder {
                        color  : #646464;
                        opacity: 1;
                    }

                    &:focus:-moz-placeholder {
                        color: transparent;
                    }

                    /* Mozilla Firefox 19+ */
                    &::-moz-placeholder {
                        color  : #646464;
                        opacity: 1;
                    }

                    &:focus::-moz-placeholder {
                        color: transparent;
                    }

                    /* Internet Explorer 10+ */
                    &:-ms-input-placeholder {
                        color  : #646464;
                        opacity: 1;
                    }

                    &:focus:-ms-input-placeholder {
                        color: transparent;
                    }
                }
            }
        }

        .box-click {
            position    : relative;
            padding-top : 20px;
            margin-right: -5px;
            text-align  : center;



            @media (max-width: 767px) {
                padding-top: 0;
            }

            .button-submit {
                text-align   : center;
                border-radius: 50px;
                font-family  : $heading-font;
                font-style   : normal;
                font-weight  : bold;
                line-height  : 1.11;
                padding      : 12px 80px 11px;
                font-size    : 14px;
                background   : #C4C4C4;
                color        : #212121;
                margin       : 0 5px;

                border : none;
                outline: none;

                @media (max-width: 767px) {
                    width        : 100%;
                    margin-bottom: 20px;
                    padding      : 12px 50px 11px;
                }

                &:hover {
                    color     : #FFFFFF;
                    background: linear-gradient(89.34deg, #FF4913 1.12%, #FF5C00 95.42%);
                }
            }

            .button-submit.active {
                color     : #FFFFFF;
                background: linear-gradient(89.34deg, #FF4913 1.12%, #FF5C00 95.42%);
                border    : none;
                outline   : none;
            }


        }

        .txt-username {
            font-size: 15px;
            color    : gray !important;
        }

        .noti-username {
            font-style: italic;
            color     : #283991;
            font-size : 12px;
        }
    }
}