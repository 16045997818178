// new html scss
/* Variable */
$white                   : #fff;
$black                   : #000;
$dark-color              : #2b2b2b;
$border-color            : #ebebeb; // màu border
$main-color              : #0F1A51; // màu chính
$secondary-color         : #FF4913; // màu phụ
$link-color              : #365899;
$link-hover-color        : #F04D22;
$footer-color            : #93989f;
$footer-link-color       : #93989f;
$footer-link-hover-color : #e9bf4b;
$body-font               : 'SVN-Gotham Book',
Arial,
Helvetica,
sans-serif;
$body-font2: 'SVN-Gotham',
Arial,
Helvetica,
sans-serif;
$body-fontweight-normal: 400;
$body-fontweight-bold  : 700;
$body-font-size        : 18px;
$body-color            : #666;
$heading-font          : 'SVN-Avo',
Arial,
Helvetica,
sans-serif;
$heading-font2: 'UTM Avo',
Arial,
Helvetica,
sans-serif;
$heading-font3: 'UTM Bebas',
Arial,
Helvetica,
sans-serif;
$heading-fontweight-normal: 400;
$heading-fontweight-bold  : 700;
$padding-mobile           : 25px;











// old html scss
$base-size: 16px;
$lh       : 1.313;
$fontmain :'Roboto Condensed',
"Helvetica",
-apple-system,
BlinkMacSystemFont,
"Segoe UI",
'Roboto Condensed',
"Helvetica Neue",
Arial,
"Noto Sans",
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji";

$header_rgb: #121212;
$body_rgb  : #383838;
$footer_rgb: #3b3b3b;

$colormain         :#0071ba;
$colorsub          :#bb8d09;
$common-text-color :#ffffffb3;
$common-btn-confirm: #185ba8;
$common-btn-deny   : #bb8d09;
$common-error      : #ff9600;
$common-notify     : greenyellow;
$common-green      : #00C087;
$common-red        : #d01a1d;
$common-white      : #fff;
$common-orange     : #FFAC42;
$border            : #444;
$text-in-light     : #000;

$input-focus: #FFAC42;

$footer-container   : #282828;
$footer-text        : #262626;
$footer-next-disable: #ccc;

$bg-menu-color   : #f5f5f5;
$bg-scrollbar    : #eee;
$bg-table-color  : #222;
$box-shadow-color: #0000004d;

$dropdown-image: url("../assets/images/dropdown-black.svg");

$date-picker-bg             : lighten($body_rgb, 5%);
$date-picker-border         : darken(#3f4458, 50%);
$date-picker-arrow-hover    : $common-green;
$date-picker-month-fg       : #fff;
$date-picker-month-bg       : lighten($body_rgb, 5%);
$date-picker-weekdays-fg    : #fff;
$date-picker-weekdays-bg    : transparent;
$date-picker-day-fg         : #fff;
$date-picker-day-hover-bg   : lighten($body_rgb, 25%);
$date-picker-today-border   : $common-green;
$date-picker-today-bg       : lighten($body_rgb, 25%);
$date-picker-today-fg       : #fff;
$date-picker-day-selected-fg: lighten($body_rgb, 5%);


$font-family : helvetica,
arial,
sans-serif;
$font-family-1: tahoma,
helvetica,
arial,
sans-serif;
$font-family-2: helvetica,
arial,
sans-serif;

$font-size-8  : 8px;
$font-size-9  : 9px;
$font-size-10 : 10px;
$font-size-11 : 11px;
$font-size-12 : 12px;
$font-size-13 : 13px;
$font-size-14 : 14px;
$font-size-15 : 15px;
$font-size-16 : 16px;
$font-size-17 : 17px;
$font-size-18 : 18px;
$font-size-19 : 19px;
$font-size-20 : 20px;
$font-size-21 : 21px;
$font-size-22 : 22px;
$font-size-23 : 23px;
$font-size-24 : 24px;
$font-size-25 : 25px;
$font-size-28 : 28px;

$font-size-small   : 12px;
$font-size-regular : 13px;
$font-size-medium  : 14px;
$font-size-big     : 16px;
$font-size-very-big: 20px;
// font-size variables [End]
//=====================================================

//=====================================================
// height variables [Begin]
$height-8  : 8px;
$height-9  : 9px;
$height-10 : 10px;
$height-11 : 11px;
$height-12 : 12px;
$height-13 : 13px;
$height-14 : 14px;
$height-15 : 15px;
$height-16 : 16px;
$height-17 : 17px;
$height-18 : 18px;
$height-19 : 19px;
$height-20 : 20px;
$height-21 : 21px;
$height-22 : 22px;
$height-23 : 23px;
$height-24 : 24px;
$height-25 : 25px;
$height-30 : 30px;

// height-size variables [End]
//=====================================================

//=====================================================
// z-index  variables [Begin]
$z-index-layer-0: 9000;
$z-index-layer-1: 999;
$z-index-layer-2: 888;
$z-index-layer-3: 777;
$z-index-layer-4: 666;
$z-index-layer-5: 555;
$z-index-layer-6: 444;
// z-index  variables [End]
//=====================================================

//=====================================================
// color && background variables [Begin]

$dark-1 : lighten(#1F2734, 2); // Haki edit
$dark-2 : lighten(#2e2e2e, 2); // Haki edit
$dark-3 : lighten(#252525, 2);
; // Haki edit
$dark-light-price-board: #3d3d3db7;
$border                : #383C45;
$white                 : #FFFFFF;
$gray-light            : #EAEAEA;
$gray                  : #959CAC;
$gray-darker           : darken($gray, 35);
$primary               : rgb(0, 192, 135);
$red                   : #DD0574;
$green                 : #00C087;
$purple                : #f0f;
$blue                  : #6cf;
$primary-blue          : #0079c1;
$error-red             : rgb(255, 10, 15);
$red-rgb-255-0-0       : rgb(255, 0, 0);
$red-rgb-232-31-31     : rgb(232, 31, 31);



$black-rgb-0-0-0         : rgb(0, 0, 0);
$black-rgb-18-18-18      : rgb(18, 18, 18);
$black-rgb-20-20-20      : rgb(20, 20, 20);
$black-rgb-24-24-24      : rgb(24, 24, 24);
$black-rgb-30-30-30      : rgb(30, 30, 30);
$black-rgb-35-35-35      : rgb(35, 35, 35);
$black-rgb-37-37-37      : rgb(37, 37, 37);
$black-rgb-38-38-38      : rgb(38, 38, 38);
$black-rgb-40-40-40      : rgb(40, 40, 40);
$black-rgb-43-43-43      : rgb(43, 43, 43);
$black-rgb-46-46-46      : rgb(46, 46, 46);
$black-rgb-49-49-49      : rgb(49, 49, 49);
$black-rgb-52-52-52      : rgb(52, 52, 52);
$black-rgb-54-54-54      : rgb(54, 54, 54);
$black-rgb-55-55-55      : rgb(55, 55, 55);
$black-rgb-57-57-57      : rgb(57, 57, 57);
$black-rgb-58-58-58      : rgb(58, 58, 58);
$black-rgb-63-63-63      : rgb(63, 63, 63);
$black-rgb-68-68-68      : rgb(68, 68, 68);
$black-rgb-72-72-72      : rgb(72, 72, 72);
$black-rgb-73-73-73      : rgb(73, 73, 73);
$black-rgb-100-100-100   : rgb(100, 100, 100);
$black-rgba-60-60-60-095 : rgba(60, 60, 60, 0.95);
$black-rgba-83-83-83-1   : rgba(83, 83, 83, 1);
$black-rgba-83-83-83-015 : rgba(83, 83, 83, 0.15);
$black-rgb-66-54-54      : rgb(66, 54, 54);
$black-rgba-17-17-17-030 : rgba(17, 17, 17, 0.30);
$black-rgba-40-40-40-030 : rgba(40, 40, 40, 0.30);
$black-rgba-0-0-0-050    : rgba(0, 0, 0, 0.50);

$white-rgb-100-100-100   : rgb(100, 100, 100);
$white-rgb-120-120-120   : rgb(120, 120, 120);
$white-rgb-128-128-128   : rgb(126, 128, 128);
$white-rgb-147-144-144   : rgb(147, 144, 144);
$white-rgb-149-156-172   : rgb(149, 156, 172);
$white-rgb-153-153-153   : rgb(153, 153, 153);
$white-rgb-166-166-166   : rgb(166, 166, 166);
$white-rgb-175-175-175   : rgb(175, 175, 175);
$white-rgb-190-190-190   : rgb(190, 190, 190);
$white-rgb-193-193-193   : rgb(193, 193, 193);
$white-rgb-204-204-204   : rgb(204, 204, 204);
$white-rgb-207-207-207   : rgb(207, 207, 207);
$white-rgb-214-214-214   : rgb(214, 214, 214);
$white-rgb-218-218-218   : rgb(218, 218, 218);
$white-rgb-229-229-229   : rgb(229, 229, 229);
$white-rgb-230-230-230   : rgb(230, 230, 230);
$white-rgb-231-231-231   : rgb(231, 231, 231);
$white-rgb-238-238-238   : rgb(238, 238, 238);
$white-rgb-240-240-240   : rgb(240, 240, 240);
$white-rgb-242-242-242   : rgb(242, 242, 242);
$white-rgb-251-251-251   : rgb(251, 251, 251);
$white-rgb-255-255-255-08: rgba(255, 255, 255, 0.8);
$white-rgb-255-255-255   : rgb(255, 255, 255);

$rgb-17-40-33: rgb(17, 40, 33);

$rgb-52-20-29 : rgb(52, 20, 29);

$rgb-40-52-60 : rgb(40, 52, 60);

$rgb-40-52-60: rgb(40, 52, 60);

$orange-rbg-255-172-66 : rgb(255, 172, 66);

$orange-rbg-217-122-8 : rgb(217, 122, 8);

$orange-rbg-247-148-29 : rgb(247, 148, 29);

$theme-dark-orange : rgb(255, 172, 66);
$rgb-30-222-165    : rgb(30, 222, 165);
$rgb-0-192-135-01  : rgba(0, 192, 135, 0.1);
$rgb-0-192-135     : rgb(0, 192, 135);
$rgb-66-133-244    :rgb(66, 133, 244);

$rgb-255-55-55   : rgb(255, 55, 55);
$rgb-255-105-105 : rgb(255, 105, 105);
$rgb-255-85-85   : rgb(255, 85, 85);

$theme-dark-red         : #DD0574;
$theme-dark-black       : #000;
$theme-dark-white       : #FFFFFF;
$theme-dark-gray        : #959CAC;
$theme-dark-green       : #00C087;
$theme-dark-blue        : #6cf;
$theme-dark-purple      : #f0f;
//hieu ung
// $darken-btn          : 10%;
// BAO VIET
$fss-blue               : rgb(0, 192, 135);
$fss-blue-darker        : #1C5CA7;
$fss-blue-darkest       : darken($fss-blue, 15);
$fss-orange             : #FFAC42;
$fss-orange-dark        : #b69418;

$theme-light-orange: #FF851B; // #F95700; #FF851B;
$theme-light-red   : #DC2937;
$theme-light-black : #000;
$theme-light-white : #FFFFFF;
$theme-light-gray  : #6e7d8b;
$theme-light-green : #588623;
$theme-light-blue  : #0074D9; //#2786fb;
$theme-light-purple: $theme-dark-purple;


// // Bộ mã màu giá.Begin HSC
// $theme-dark-text-floor-price       : #00BCD4;
// $theme-dark-text-ceil-price        : #9C27B0;
// $theme-dark-text-ref-price         : #FFEB3B;
// $theme-dark-text-priceboard-green  : #4CAF50;
// $theme-dark-text-priceboard-red    : #F44336;
// $theme-dark-cell-group-item        : #F44336;
// $theme-dark-cell-group-item-lighter: #F44336;
// $theme-dark-table-header-color     : lighten(#2c2c2c, 2);
// $theme-dark-table-header-text-color: #fff;
// $theme-dark-table-border           : #fff;

// $theme-light-text-floor-price       : #00BCD4;
// $theme-light-text-ceil-price        : #9C27B0;
// $theme-light-text-ref-price         : #FFEB3B;
// $theme-light-text-priceboard-green  : #4CAF50;
// $theme-light-text-priceboard-red    : #F44336;
// $theme-light-cell-group-item        : #F44336;
// $theme-light-cell-group-item-lighter: #F44336;
// $theme-light-table-header-color     : lighten(#2c2c2c, 2);
// $theme-light-table-header-text-color: #fff;
// $theme-light-table-border           : #fff;
// //Bộ mã màu giá.End

// Bộ mã màu giá.Begin Demo VND
$theme-dark-text-floor-price       : #1eeeee;
$theme-dark-text-ceil-price        : #ff25ff;
$theme-dark-text-ref-price         : #ffd900;
$theme-dark-text-priceboard-green  : #0f0;
$theme-dark-text-priceboard-red    : #ff2626; // #ff3737
$theme-dark-cell-group-item        : #ff2626; // #ff3737
$theme-dark-cell-group-item-lighter: #ff2626; // #ff3737
$theme-dark-table-header-color     : lighten(#2c2c2c, 2);
$theme-dark-table-header-text-color: #fff;
$theme-dark-table-border           : #fff;
$theme-dark-text-normal            : #fff;

$theme-light-text-floor-price       : #00c5c5;
$theme-light-text-ceil-price        : #ce0ece;
$theme-light-text-ref-price         : #c7b300;
$theme-light-text-priceboard-green  : #1ac316;
$theme-light-text-priceboard-red    : #f53e3e;
$theme-light-cell-group-item        : #f53e3e;
$theme-light-cell-group-item-lighter: #f53e3e;
$theme-light-table-header-color     : lighten(#2c2c2c, 2);
$theme-light-table-header-text-color: #fff;
$theme-light-table-border           : #fff;
$theme-light-text-normal            : #000;
//Bộ mã màu giá.End


// color && background variables [End]
//=====================================================

// border variables
// width variables
// height variables
//=====================================================


//=====================================================
// using Old CSS variables [Begin]
$side-menu-width            : 250px;
$side-menu-toggle-icon-width: 25px;
$side-menu-position-top-left: 10px;
// Bootstrap overrides
$grid-breakpoints           : (xs: 0,
      sm: 576px,
      md: 769px,
      lg: 1200px,
      xl: 1366px) !default;
// using Old CSS  variables [End]
//=====================================================
// Haki.: [End] Manage CSS variables =>>>>>>>>>>>>>>>z

$dark-1 : lighten(#1F2734, 2); // Haki edit
$dark-2 : lighten(#2e2e2e, 2); // Haki edit
$dark-3 : lighten(#252525, 2);
; // Haki edit
$dark-light-price-board: #3d3d3db7;
$border                : #383C45;
$white                 : #FFFFFF;
$gray-light            : #EAEAEA;
$gray                  : #959CAC;
$gray-darker           : darken($gray, 35);
$primary               : rgb(0, 192, 135);
$red                   : #DD0574;
$green                 : #00C087;
$purple                : #f0f;
$blue                  : #6cf;
$primary-blue          : #0079c1;
$blue-chart-volume     : #60a0ffbf;
$error-red             : rgb(255, 10, 15);

// DARK
$theme-dark-dark-0    : rgb(0, 0, 0); // Haki editC
$theme-dark-dark-1    : rgb(18, 18, 18); // Haki editC
$theme-dark-dark-1_   : rgb(20, 20, 20); // Haki editC
$theme-dark-dark-1__  : rgb(24, 24, 24); // Haki editC
$theme-dark-dark-2    : rgb(30, 30, 30); // Haki edit
$theme-dark-dark-2_   : rgb(35, 35, 35);
$theme-dark-dark-3    : rgb(46, 46, 46); // Haki edit
$theme-dark-dark-3_   : rgb(54, 54, 54); // Haki edit
$theme-dark-dark-4    : rgb(63, 63, 63); // Haki edit
$theme-dark-dark-5    : rgb(85, 85, 85); // Haki edit
// $theme-dark-dark-3: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #121212;
$theme-dark-border    : rgba(83, 83, 83, 0.15); // Haki edit
$theme-dark-border-2  : rgb(66, 54, 54); // Haki edit
$theme-dark-border-3  : rgba(52, 52, 52, 0.8); // Haki edit
$theme-light-border-2 : rgba(83, 83, 83, 1); // Haki edit



//Start-Bộ mã màu marketwatch MBB
$green-rgb-90-181-92  : rgb(90, 181, 92);
$red-rgb-188-58-54    : rgb(188, 58, 54);
$yellow-rgb-252-218-80: rgb(252, 218, 80);
$purple-rgb-162-37-138: rgb(162, 37, 138);
$blue-rgb-30-151-190  : rgb(30, 151, 190);

//End-Bộ mã màu marketwatch MBB


// the :export directive is the magic sauce for webpack
// export để sử dụng scss variables trong js file
:export {
      default-font-family-chart       : $font-family;
      //dark
      theme-dark-border               : $theme-dark-border;
      theme-dark-text-floor-price     : $theme-dark-text-floor-price;
      theme-dark-text-priceboard-green: $theme-dark-text-priceboard-green;
      theme-dark-text-ref-price       : $theme-dark-text-ref-price;
      theme-dark-text-priceboard-red  : $theme-dark-text-priceboard-red;
      theme-dark-white                : $theme-light-white;
      theme-dark-gray                 : $theme-light-gray;
      theme-dark-dark-2_              : $theme-dark-dark-2_;
      blue-chart-volume               : $blue-chart-volume;

      theme-dark-text-ceil-price          : $theme-dark-text-ceil-price;
      theme-dark-light-green              : $theme-light-green;
      theme-dark-background-charts        : $black-rgb-35-35-35;
      theme-dark-tooltip-charts           : $theme-dark-white;
      theme-dark-text-charts              : $theme-dark-text-priceboard-green;
      theme-dark-negative-text-charts     : $theme-dark-text-priceboard-red;
      theme-dark-volume-charts            : $blue-chart-volume;
      theme-dark-label-charts             : $white-rgb-100-100-100;
      theme-dark-color-chart-column       : $blue-chart-volume;
      theme-dark-vertGrid-Color-charts    : $rgb-40-52-60;
      theme-dark-horzGrid-Color-charts    : $rgb-40-52-60;
      theme-dark-toolbar-background-charts: $black-rgb-35-35-35;

      theme-dark-symbol-detail-overview-background-chart: $black-rgb-35-35-35;
      theme-dark-symbol-detail-finance-legend-color     : $white-rgb-214-214-214;
      theme-dark-symbol-detail-finance-legend-hover     : $white-rgb-230-230-230;
      theme-dark-symbol-detail-finance-label-color      : $white-rgb-100-100-100;
      theme-dark-symbol-detail-color-grid-line-yaxis    : $black-rgb-40-40-40;

      theme-dark-market-watch-color-text-normal: $white-rgb-175-175-175;

      theme-dark-order-buy-LineColor                  : #35A6FF;
      theme-dark-order-buy-BodyTextColor              : $theme-dark-text-priceboard-green;
      theme-dark-order-buy-BodyBackgroundColor        : $black-rgb-18-18-18;
      theme-dark-order-buy-BodyBorderColor            : #35A6FF;
      theme-dark-order-buy-QuantityTextColor          : #ffffff;
      theme-dark-order-buy-QuantityBackgroundColor    : #35A6FF;
      theme-dark-order-buy-QuantityBorderColor        : #35A6FF;
      theme-dark-order-buy-CancelButtonIconColor      : #35A6FF;
      theme-dark-order-buy-CancelButtonBackgroundColor: $black-rgb-18-18-18;
      theme-dark-order-buy-CancelButtonBorderColor    : #35A6FF;

      theme-dark-order-sell-LineColor                  : $theme-dark-text-priceboard-red;
      theme-dark-order-sell-BodyTextColor              : $theme-dark-text-priceboard-red;
      theme-dark-order-sell-BodyBackgroundColor        : $black-rgb-18-18-18;
      theme-dark-order-sell-BodyBorderColor            : $theme-dark-text-priceboard-red;
      theme-dark-order-sell-QuantityTextColor          : #ffffff;
      theme-dark-order-sell-QuantityBackgroundColor    : $theme-dark-text-priceboard-red;
      theme-dark-order-sell-QuantityBorderColor        : $theme-dark-text-priceboard-red;
      theme-dark-order-sell-CancelButtonIconColor      : $theme-dark-text-priceboard-red;
      theme-dark-order-sell-CancelButtonBackgroundColor: $black-rgb-18-18-18;
      theme-dark-order-sell-CancelButtonBorderColor    : $theme-dark-text-priceboard-red;


      // theme-dark-positon-buy-LineColor                  : #35A6FF;
      theme-dark-positon-buy-LineColor                   : #35A6FF;
      theme-dark-positon-buy-BodyTextColor               : $theme-dark-text-priceboard-green;
      theme-dark-positon-buy-BodyBackgroundColor         : $black-rgb-18-18-18;
      theme-dark-positon-buy-BodyBorderColor             : #35A6FF;
      theme-dark-positon-buy-QuantityTextColor           : #ffffff;
      theme-dark-positon-buy-QuantityBackgroundColor     : #35A6FF;
      theme-dark-positon-buy-QuantityBorderColor         : #35A6FF;
      theme-dark-positon-buy-ReverseButtonIconColor      : #35A6FF;
      theme-dark-positon-buy-ReverseButtonBackgroundColor: $black-rgb-100-100-100;
      theme-dark-positon-buy-ReverseButtonBorderColor    : #35A6FF;
      theme-dark-positon-buy-CloseButtonIconColor        : #35A6FF;
      theme-dark-positon-buy-CloseButtonBackgroundColor  : $black-rgb-18-18-18;
      theme-dark-positon-buy-CloseButtonBorderColor      : #35A6FF;

      theme-dark-positon-sell-LineColor                   : $theme-dark-text-priceboard-red;
      theme-dark-positon-sell-BodyTextColor               : $theme-dark-text-priceboard-red;
      theme-dark-positon-sell-BodyBackgroundColor         : $black-rgb-18-18-18;
      theme-dark-positon-sell-BodyBorderColor             : $theme-dark-text-priceboard-red;
      theme-dark-positon-sell-QuantityTextColor           : #ffffff;
      theme-dark-positon-sell-QuantityBackgroundColor     : $theme-dark-text-priceboard-red;
      theme-dark-positon-sell-QuantityBorderColor         : $theme-dark-text-priceboard-red;
      theme-dark-positon-sell-ReverseButtonIconColor      : $theme-dark-text-priceboard-red;
      theme-dark-positon-sell-ReverseButtonBackgroundColor: $black-rgb-100-100-100;
      theme-dark-positon-sell-ReverseButtonBorderColor    : $theme-dark-text-priceboard-red;
      theme-dark-positon-sell-CloseButtonIconColor        : $theme-dark-text-priceboard-red;
      theme-dark-positon-sell-CloseButtonBackgroundColor  : $black-rgb-18-18-18;
      theme-dark-positon-sell-CloseButtonBorderColor      : $theme-dark-text-priceboard-red;

      theme-dark-positon-normal-BodyTextColor: $theme-dark-text-normal;
      theme-dark-range-input-left-track      : #cccccc;
      theme-dark-range-input-right-track     : #3a3a3a;

      //light
      theme-light-text-ceil-price     : $theme-light-text-ceil-price;
      theme-light-light-green         : $theme-light-green;
      theme-light-background-charts   : $white-rgb-230-230-230;
      theme-light-tooltip-charts      : $theme-light-white;
      theme-light-text-charts         : $theme-light-text-priceboard-green;
      theme-light-negative-text-charts: $theme-light-text-priceboard-red;
      theme-light-volume-charts       : $blue-chart-volume;
      theme-light-label-charts        : $white-rgb-147-144-144;
      theme-light-color-chart-column  : $blue-chart-volume;

      theme-light-text-priceboard-green: $theme-dark-text-priceboard-green;
      theme-light-text-priceboard-red  : $theme-dark-text-priceboard-red;

      theme-light-symbol-detail-overview-background-chart: $white-rgb-238-238-238;
      theme-light-symbol-detail-finance-legend-color     : $white-rgb-100-100-100;
      theme-light-symbol-detail-finance-legend-hover     : $white-rgb-100-100-100;
      theme-light-symbol-detail-finance-label-color      : $white-rgb-120-120-120;
      theme-light-symbol-detail-color-grid-line-yaxis    : $white-rgb-214-214-214;

      theme-light-market-watch-color-text-normal: $white-rgb-218-218-218;



      theme-light-vertGrid-Color-charts    : $white-rgb-204-204-204;
      theme-light-horzGrid-Color-charts    : $white-rgb-204-204-204;
      theme-light-toolbar-background-charts: $white-rgb-230-230-230;


      theme-light-order-buy-LineColor                  : #35A6FF;
      theme-light-order-buy-BodyTextColor              : $theme-light-text-priceboard-green;
      theme-light-order-buy-BodyBackgroundColor        : $white-rgb-230-230-230;
      theme-light-order-buy-BodyBorderColor            : #35A6FF;
      theme-light-order-buy-QuantityTextColor          : #ffffff;
      theme-light-order-buy-QuantityBackgroundColor    : #35A6FF;
      theme-light-order-buy-QuantityBorderColor        : #35A6FF;
      theme-light-order-buy-CancelButtonIconColor      : #35A6FF;
      theme-light-order-buy-CancelButtonBackgroundColor: $white-rgb-230-230-230;
      theme-light-order-buy-CancelButtonBorderColor    : #35A6FF;

      theme-light-order-sell-LineColor                  : $theme-light-text-priceboard-red;
      theme-light-order-sell-BodyTextColor              : $theme-light-text-priceboard-red;
      theme-light-order-sell-BodyBackgroundColor        : $white-rgb-230-230-230;
      theme-light-order-sell-BodyBorderColor            : $theme-light-text-priceboard-red;
      theme-light-order-sell-QuantityTextColor          : #ffffff;
      theme-light-order-sell-QuantityBackgroundColor    : $theme-light-text-priceboard-red;
      theme-light-order-sell-QuantityBorderColor        : $theme-light-text-priceboard-red;
      theme-light-order-sell-CancelButtonIconColor      : $theme-light-text-priceboard-red;
      theme-light-order-sell-CancelButtonBackgroundColor: $white-rgb-230-230-230;
      theme-light-order-sell-CancelButtonBorderColor    : $theme-light-text-priceboard-red;

      theme-light-positon-buy-LineColor                   : #35A6FF;
      theme-light-positon-buy-BodyTextColor               : $theme-light-text-priceboard-green;
      theme-light-positon-buy-BodyBackgroundColor         : $white-rgb-230-230-230;
      theme-light-positon-buy-BodyBorderColor             : #35A6FF;
      theme-light-positon-buy-QuantityTextColor           : #ffffff;
      theme-light-positon-buy-QuantityBackgroundColor     : #35A6FF;
      theme-light-positon-buy-QuantityBorderColor         : #35A6FF;
      theme-light-positon-buy-ReverseButtonIconColor      : #35A6FF;
      theme-light-positon-buy-ReverseButtonBackgroundColor: $white-rgb-240-240-240;
      theme-light-positon-buy-ReverseButtonBorderColor    : #35A6FF;
      theme-light-positon-buy-CloseButtonIconColor        : #35A6FF;
      theme-light-positon-buy-CloseButtonBackgroundColor  : $white-rgb-230-230-230;
      theme-light-positon-buy-CloseButtonBorderColor      : #35A6FF;


      theme-light-positon-sell-LineColor                   : $theme-light-text-priceboard-red;
      theme-light-positon-sell-BodyTextColor               : $theme-light-text-priceboard-red;
      theme-light-positon-sell-BodyBackgroundColor         : $white-rgb-230-230-230;
      theme-light-positon-sell-BodyBorderColor             : $theme-light-text-priceboard-red;
      theme-light-positon-sell-QuantityTextColor           : #ffffff;
      theme-light-positon-sell-QuantityBackgroundColor     : $theme-light-text-priceboard-red;
      theme-light-positon-sell-QuantityBorderColor         : $theme-light-text-priceboard-red;
      theme-light-positon-sell-ReverseButtonIconColor      : $theme-light-text-priceboard-red;
      theme-light-positon-sell-ReverseButtonBackgroundColor: $white-rgb-240-240-240;
      theme-light-positon-sell-ReverseButtonBorderColor    : $theme-light-text-priceboard-red;
      theme-light-positon-sell-CloseButtonIconColor        : $theme-light-text-priceboard-red;
      theme-light-positon-sell-CloseButtonBackgroundColor  : $white-rgb-230-230-230;
      theme-light-positon-sell-CloseButtonBorderColor      : $theme-light-text-priceboard-red;

      theme-light-positon-normal-BodyTextColor: $theme-light-text-normal;
      theme-light-range-input-left-track      : #848E9C;
      theme-light-range-input-right-track     : #cccccc;

}


$themes: (dark: ( //1.B.PageHeader === [Begin]
            //--1.1.PageHeader basic
            PageHeader-width: 100%,
            PageHeader-height: 40px,
            PageHeader-font-family: $font-family,
            PageHeader-font-size: $font-size-14,
            PageHeader-z-index: $z-index-layer-1,
            PageHeader-text-color: $white-rgb-231-231-231,
            PageHeader-background: $black-rgba-60-60-60-095,
            PageHeader-shadow: $black-rgba-60-60-60-095,
            //--1.2.PageHeader-logo
            PageHeader-logo-width: 100px,
            PageHeader-logo-height: 40px,
            //--1.3.PageHeader-menu cấp 1
            PageHeader-menu-width: 100%,
            PageHeader-menu-height: 40px,
            PageHeader-menu-font-family: $font-family,
            PageHeader-menu-font-size: $font-size-14,
            PageHeader-menu-text-color: $white-rgb-231-231-231,
            PageHeader-menu-text-color-hover: $rgb-0-192-135,
            PageHeader-menu-text-color-active: $rgb-0-192-135,
            PageHeader-menu-background: $black-rgb-68-68-68,
            PageHeader-menu-background-hover: $black-rgb-100-100-100,
            PageHeader-menu-border: $black-rgb-63-63-63,
            //--1.4.PageHeader-menu cấp 2
            PageHeader-menu-2-width: 100%,
            PageHeader-menu-2-height: 40px,
            PageHeader-menu-2-font-family: $font-family,
            PageHeader-menu-2-font-size: $font-size-14,
            PageHeader-menu-2-text-color: $white-rgb-231-231-231,
            PageHeader-menu-2-text-color-hover: $orange-rbg-217-122-8,
            PageHeader-menu-2-text-color-active: $rgb-0-192-135,
            PageHeader-menu-2-background: $black-rgb-68-68-68,
            PageHeader-menu-2-background-hover: $black-rgb-100-100-100,
            PageHeader-menu-2-border: $black-rgb-63-63-63,
            PageHeader-menu-not-right-text-color: $white-rgb-166-166-166,
            //--1.5.PageHeader-popup
            PageHeader-popup-width: 100%,
            PageHeader-popup-height: 100%,
            PageHeader-popup-text-color: 100%,
            PageHeader-popup-text-color-hover: 100%,
            PageHeader-popup-background: 100%,
            PageHeader-popup-background-hover: 100%,
            //--1.6.PageHeader-icon
            PageHeader-icon-width: 100%,
            PageHeader-icon-height: 100%,
            PageHeader-icon-font-size: $font-size-20,
            PageHeader-icon-text-color: $white-rgb-231-231-231,
            PageHeader-icon-text-color-hover: $orange-rbg-217-122-8,
            PageHeader-icon-color: $white-rgb-255-255-255,
            PageHeader-icon-color-hover: $rgb-0-192-135,
            PageHeader-icon-background: $black-rgb-68-68-68,
            PageHeader-icon-background-hover: $black-rgb-100-100-100,
            //--1.7.PageHeader-time
            PageHeader-time-width: 100%,
            PageHeader-time-height: 40px,
            PageHeader-time-font-family: $font-family,
            PageHeader-time-font-size: $font-size-14,
            PageHeader-time-color: $white-rgb-231-231-231,
            //--1.8.PageHeader-btn
            PageHeader-btn-width: 40px,
            PageHeader-btn-height: 40px,
            PageHeader-btn-text-color: $white-rgb-231-231-231,
            PageHeader-btn-text-color-hover: $rgb-0-192-135,
            //--1.9.PageHeader-p
            PageHeader-p-width: 100%,
            PageHeader-p-height: 100%,
            PageHeader-p-font-size: $font-size-15,
            //--1.10.PageHeader-dropdown
            PageHeader-dropdown-width: 100%,
            PageHeader-dropdown-height: 40px,
            PageHeader-dropdown-font-family: $font-family,
            PageHeader-dropdown-font-size: $font-size-14,
            PageHeader-dropdown-text-color: $white-rgb-231-231-231,
            PageHeader-dropdown-text-color-hover: $orange-rbg-255-172-66,
            PageHeader-dropdown-text-color-active: $orange-rbg-255-172-66,
            PageHeader-dropdown-background: $black-rgb-68-68-68,
            PageHeader-dropdown-background-hover: $black-rgb-100-100-100,
            PageHeader-dropdown-border: $black-rgb-68-68-68,
            //1.E.PageHeader === [End]

            // =====================================================================================================

            // 2.B.WidgetBase === [Begin]
            //--2.1.WidgetBase basic
            WidgetBase-width: 100%,
            WidgetBase-height: 100%,
            WidgetBase-font-family: $font-family,
            WidgetBase-font-size: $font-size-14,
            WidgetBase-z-index: $z-index-layer-2,
            WidgetBase-text-color: $white-rgb-231-231-231,
            WidgetBase-background: $black-rgb-68-68-68,
            WidgetBase-border: $black-rgba-83-83-83-015,
            //--2.2.WidgetBase header
            WidgetBase-header-width: 100%,
            WidgetBase-header-height: 30px,
            WidgetBase-header-font-family: $font-family,
            WidgetBase-header-font-size: $font-size-14,
            WidgetBase-header-background: $black-rgb-18-18-18,
            WidgetBase-header-border: $black-rgb-68-68-68,
            WidgetBase-header-shadow: $black-rgb-68-68-68,
            WidgetBase-header-icon-width: 100%,
            WidgetBase-header-icon-height: 30px,
            WidgetBase-header-icon-font-size: $font-size-14,
            WidgetBase-header-icon-text-color: $white-rgb-231-231-231,
            WidgetBase-header-icon-text-color-hover: $orange-rbg-217-122-8,
            WidgetBase-header-icon-color: $white-rgb-231-231-231,
            WidgetBase-header-icon-color-hover: $rgb-0-192-135,
            WidgetBase-header-icon-background: $black-rgb-68-68-68,
            WidgetBase-header-icon-background-hover: $black-rgb-100-100-100,
            WidgetBase-header-btn-font-size: $font-size-14,
            WidgetBase-header-btn-height: 30px,
            WidgetBase-header-btn-text-color: $white-rgb-231-231-231,
            WidgetBase-header-btn-text-color-hover: $rgb-0-192-135,
            WidgetBase-header-btn-text-color-active: $rgb-0-192-135,
            WidgetBase-header-btn-border-active: $black-rgb-0-0-0,
            WidgetBase-hide-header-z-index: $z-index-layer-1,
            WidgetBase-hide-header-width-div-hover: 200px,

            // WidgetBase-header-btn-background: none,
            // WidgetBase-header-btn-background-hover: none,
            //--2.2.WidgetBase body
            WidgetBase-body-width: 100%,
            WidgetBase-body-height: calc(100vh - 110px),
            WidgetBase-body-font-family: $font-family,
            WidgetBase-body-font-size: $font-size-14,
            WidgetBase-body-background: $black-rgb-37-37-37,
            WidgetBase-body-border: $black-rgb-37-37-37,
            WidgetBase-body-shadow: $black-rgb-37-37-37,
            //--2.3.WidgetBase scroll
            WidgetBase-scroll-border-x: $white-rgb-100-100-100,
            WidgetBase-scroll-border-y: $white-rgb-100-100-100,
            WidgetBase-scroll-border-xy: $white-rgb-231-231-231,
            //--2.3.WidgetBase scroll
            WidgetBase-menu-width: 100%,
            WidgetBase-menu-height: 30px,
            WidgetBase-menu-font-family: $font-family,
            WidgetBase-menu-font-size: $font-size-14,
            WidgetBase-menu-text-color: $white-rgb-128-128-128,
            WidgetBase-menu-text-color-active: $white-rgb-255-255-255,
            WidgetBase-menu-text-color-hover: $white-rgb-255-255-255,
            WidgetBase-menu-background: $black-rgb-68-68-68,
            WidgetBase-menu-background-hover: $black-rgb-68-68-68,
            //--2.E.WidgetBase === [End]

            // =====================================================================================================

            // 3.B.WidgetPriceBoard === [Begin]
            //--3.1.WidgetPriceBoard basic
            WidgetPriceBoard-width: 100%,
            WidgetPriceBoard-height: 100%,
            WidgetPriceBoard-font-family: $font-family,
            WidgetPriceBoard-font-size: $font-size-14,
            WidgetPriceBoard-z-index: $z-index-layer-2,
            WidgetPriceBoard-text-color: $white-rgb-231-231-231,
            WidgetPriceBoard-background: $black-rgb-68-68-68,
            //--3.2.WidgetPriceBoard menu
            WidgetPriceBoard-menu-width: 100%,
            WidgetPriceBoard-menu-height: 100%,
            WidgetPriceBoard-menu-font-family: $font-family,
            WidgetPriceBoard-menu-font-size: $font-size-14,
            WidgetPriceBoard-menu-z-index: $z-index-layer-2,
            WidgetPriceBoard-menu-text-color: $white-rgb-207-207-207,
            WidgetPriceBoard-menu-text-color-active: $rgb-0-192-135,
            WidgetPriceBoard-menu-text-color-hover: $white-rgb-255-255-255,
            WidgetPriceBoard-menu-background: $black-rgb-68-68-68,
            WidgetPriceBoard-menu-background-hover: $black-rgb-68-68-68,
            WidgetPriceBoard-menu-border: $black-rgb-72-72-72,
            WidgetPriceBoard-menu-border-active: $rgb-0-192-135,
            WidgetPriceBoard-menu-border-hover: $rgb-0-192-135,
            //
            WidgetPriceBoard-menu-2-width: 100%,
            WidgetPriceBoard-menu-2-height: 100%,
            WidgetPriceBoard-menu-2-font-family: $font-family,
            WidgetPriceBoard-menu-2-font-size: $font-size-14,
            WidgetPriceBoard-menu-2-z-index: $z-index-layer-2,
            WidgetPriceBoard-menu-2-text-color: $white-rgb-207-207-207,
            WidgetPriceBoard-menu-2-text-color-active: $rgb-0-192-135,
            WidgetPriceBoard-menu-2-text-color-hover: $rgb-0-192-135,
            WidgetPriceBoard-menu-2-background: $black-rgb-49-49-49,
            WidgetPriceBoard-menu-2-background-hover: $black-rgb-68-68-68,
            WidgetPriceBoard-menu-2-border: $black-rgb-63-63-63,
            WidgetPriceBoard-menu-2-border-active: $rgb-0-192-135,
            WidgetPriceBoard-menu-2-border-hover: $rgb-0-192-135,
            WidgetPriceBoard-menu-2-btn-background: $black-rgb-49-49-49,
            WidgetPriceBoard-menu-2-btn-background-hover: $black-rgb-100-100-100,
            WidgetPriceBoard-menu-2-btn-2-background: $black-rgb-52-52-52,
            WidgetPriceBoard-menu-2-btn-2-border-bottom: $black-rgb-68-68-68,
            WidgetPriceBoard-menu-2-btn-2-border: $white-rgb-100-100-100,
            WidgetPriceBoard-menu-2-icon-add-color-hover: $theme-dark-text-priceboard-green,
            WidgetPriceBoard-menu-2-icon-edit-color-hover: $theme-light-text-ref-price,
            WidgetPriceBoard-menu-2-icon-delete-color-hover: $rgb-255-55-55,

            //--3.3.WidgetPriceBoard table
            //--3.3.1. WidgetPriceBoard table header
            WidgetPriceBoard-table-header-width: 100%,
            WidgetPriceBoard-table-header-height: 100%,
            WidgetPriceBoard-table-header-font-family: $font-family,
            WidgetPriceBoard-table-header-font-size: $font-size-12,
            WidgetPriceBoard-table-header-z-index: $z-index-layer-2,
            WidgetPriceBoard-table-header-text-color: $white-rgb-255-255-255,
            WidgetPriceBoard-table-header-background: $black-rgb-35-35-35,
            WidgetPriceBoard-table-header-border: $black-rgb-52-52-52,
            WidgetPriceBoard-table-toggle-indicator-color: $white-rgb-175-175-175,
            //--3.3.2. WidgetPriceBoard table body
            WidgetPriceBoard-table-body-width: 100%,
            WidgetPriceBoard-table-body-height: 100%,
            WidgetPriceBoard-table-body-font-family: $font-family-1,
            WidgetPriceBoard-table-body-font-size: $font-size-12,
            WidgetPriceBoard-table-body-z-index: $z-index-layer-2,
            WidgetPriceBoard-table-body-text-color: $white-rgb-255-255-255,
            WidgetPriceBoard-table-body-background: $black-rgb-35-35-35,
            WidgetPriceBoard-table-body-border: $black-rgb-52-52-52,
            WidgetPriceBoard-table-row-height: 28px,
            WidgetPriceBoard-table-row-1-background: $black-rgb-24-24-24,
            WidgetPriceBoard-table-row-1-border: $black-rgb-52-52-52,
            WidgetPriceBoard-table-row-1-light-background: $black-rgb-55-55-55,
            WidgetPriceBoard-table-row-1-light-border: $black-rgb-52-52-52,
            WidgetPriceBoard-table-row-1-background-hover: $black-rgb-68-68-68,
            //
            WidgetPriceBoard-table-row-2-background: $black-rgb-0-0-0,
            WidgetPriceBoard-table-row-2-border: $black-rgb-52-52-52,
            WidgetPriceBoard-table-row-2-light-background: $black-rgb-73-73-73,
            WidgetPriceBoard-table-row-2-light-border: $black-rgb-52-52-52,
            WidgetPriceBoard-table-row-2-background-hover: $black-rgb-68-68-68,
            //--3.E.WidgetPriceBoard === [End]
            // =====================================================================================================

            // -- 4. Widget IndexesList === [Start]
            //4.1 Indexes List Header

            //4.2 Indexes List Body
            WidgetIndexesList-charts-span-font-size: $font-size-12,
            WidgetIndexesList-charts-select-background: $black-rgb-35-35-35,
            WidgetIndexesList-charts-list-p-background: $black-rgb-58-58-58,
            WidgetIndexesList-charts-select-color: $white-rgb-255-255-255,
            WidgetIndexesList-charts-select-border: $orange-rbg-247-148-29,
            WidgetIndexesList-text-normal: $white-rgb-218-218-218,
            WidgetIndexesList-text-ref-price: $theme-dark-text-ref-price,
            WidgetIndexesList-text-floor-price: $theme-dark-text-floor-price,
            WidgetIndexesList-text-ceil-price: $theme-dark-text-ceil-price,
            WidgetIndexesList-text-priceboard-green: $theme-dark-text-priceboard-green,
            WidgetIndexesList-text-priceboard-red: $theme-dark-text-priceboard-red,
            WidgetIndexesList-text-priceboard-orange: $orange-rbg-247-148-29,

            // -- 4. Widget IndexesList === [End]
            // =====================================================================================================

            // -- 5. Widget Symbol detail === [Start] -- Priceboard ---
            //5.1 Widget Symbol detail Header

            //5.2 Widget Symbol detail Body
            WidgetSymbolDetail-footer-background: $black-rgb-30-30-30,
            WidgetSymbolDetail-footer-icon: $white-rgb-147-144-144,
            WidgetSymbolDetail-footer-icon-active: $orange-rbg-247-148-29,
            WidgetSymbolDetail-charts-footer-background: $black-rgb-30-30-30,
            WidgetSymbolDetail-charts-footer-icon-active: $orange-rbg-247-148-29,
            WidgetSymbolDetail-text-font-size-13: $font-size-13,
            WidgetSymbolDetail-text-font-size-16: $font-size-16,
            WidgetSymbolDetail-text-font-size-17: $font-size-17,
            WidgetSymbolDetail-text-priceboard-green: $theme-dark-text-priceboard-green,
            WidgetSymbolDetail-text-priceboard-red: $theme-dark-text-priceboard-red,
            WidgetSymbolDetail-text-normal: $white-rgb-218-218-218,
            WidgetSymbolDetail-border-right: $black-rgba-83-83-83-015,

            // -- 5. Widget Widget Symbol detail === [End] -- Priceboard ---
            // =====================================================================================================


            // =====================================================================================================

            // -- 6. Widget Symbol Info === [Start]
            //6.1 Widget Symbol Info detail Header

            //6.2 Widget Symbol Info detail Body
            WidgetSymbolInfo-star-color: $orange-rbg-255-172-66,
            WidgetSymbolInfo-symbol-active: $orange-rbg-255-172-66,
            WidgetSymbolInfo-border-watchlist-symbol: $black-rgba-83-83-83-015,
            WidgetSymbolInfo-background-watchlist-symbol: $black-rgba-83-83-83-015,
            WidgetSymbolInfo-hide-header-width-div-hover: 20px,

            WidgetSymbolInfo-popover-body-background: $black-rgb-46-46-46,
            WidgetSymbolInfo-popover-text-color: $white-rgb-218-218-218,
            WidgetSymbolInfo-popover-star-color: $orange-rbg-255-172-66,
            WidgetSymbolInfo-popover-text-active: $orange-rbg-217-122-8,
            WidgetSymbolInfo-popover-text-active-background: $black-rgba-83-83-83-1,
            WidgetSymbolInfo-popover-div-hover: $black-rgba-83-83-83-1,
            WidgetSymbolInfo-tabbar-border: $orange-rbg-255-172-66,
            WidgetSymbolInfo-text-priceboard-red: $theme-dark-text-priceboard-red,

            // -- 6. Widget Symbol Info  === [End]
            // =====================================================================================================

            // =====================================================================================================

            // -- 7. Widget Symbol Detail Popup === [Start]
            //7.1 Widget Symbol Detail Popupdetail Header
            WidgetSymbolDetailPopup-header-font-size: $font-size-14,
            WidgetSymbolDetailPopup-header-background: $black-rgb-68-68-68,
            WidgetSymbolDetailPopup-header-border: $black-rgb-68-68-68,
            WidgetSymbolDetailPopup-header-active-tab:$rgb-0-192-135,
            WidgetSymbolDetailPopup-header-icon-close: $font-size-18,
            WidgetSymbolDetailPopup-header-icon-hover-background: $black-rgb-100-100-100,
            //7.2 Widget Symbol Detail Popup detail Body
            WidgetSymbolDetailPopup-background: $black-rgb-35-35-35,
            WidgetSymbolDetailPopup-z-index: $z-index-layer-0,
            WidgetSymbolDetailPopup-border-container: $black-rgb-73-73-73,
            // -- 7. Widget Symbol Detail Popup  === [End]
            // =====================================================================================================

            // =====================================================================================================
            // -- 8. Widget Top Indexes === [Start]
            //8.1 Widget Top Indexes Body
            WidgetTopIndexes-color-text-normal: $white-rgb-218-218-218,
            WidgetTopIndexes-header-border: $black-rgb-52-52-52,
            WidgetTopIndexes-header-background: $black-rgb-35-35-35,
            WidgetTopIndexes-header-color: $white-rgb-218-218-218,
            WidgetTopIndexes-row-odd-background: $black-rgb-46-46-46,
            WidgetTopIndexes-row-normal-background: $black-rgb-24-24-24,
            WidgetTopIndexes-hover-row: $black-rgb-68-68-68,
            // -- 8. Widget Top Indexes === [End]
            // =====================================================================================================

            // =====================================================================================================
            // -- 9. Widget Symbol Detail (Overview, Trading, Finace,...)  === [Start]
            WidgetOverview-color-text-normal: $white-rgb-218-218-218,
            WidgetOverview-background: $black-rgb-52-52-52,
            WidgetOverview-info-symbol-background: $black-rgb-35-35-35,
            WidgetOverview-info-symbol-border: $black-rgba-60-60-60-095,

            WidgetTrading-color-text-normal: $white-rgb-218-218-218,
            WidgetTrading-header-background: $black-rgb-30-30-30,
            WidgetTrading-header-active-background: $black-rgb-52-52-52,
            WidgetTrading-events-border: $black-rgb-63-63-63,
            WidgetTrading-border-priceboard: $black-rgb-52-52-52,

            WidgetFinance-color-text-normal: $white-rgb-218-218-218,
            WidgetFinance-popover-parent-background: $black-rgb-52-52-52,
            WidgetFinance-popover-child-background: $black-rgb-43-43-43,
            WidgetFinance-button-active: $orange-rbg-217-122-8,
            WidgetFinance-container-background: $black-rgb-52-52-52,
            WidgetFinance-section-background: $black-rgb-35-35-35,
            WidgetFinance-header-section-background: $black-rgb-30-30-30,
            WidgetFinance-border-table: $black-rgb-52-52-52,
            WidgetFinance-color-header-table: $black-rgb-30-30-30,

            WidgetValuation-color-text-normal: $white-rgb-218-218-218,
            WidgetValuation-background-container: $black-rgb-52-52-52,
            WidgetValuation-background-child: $black-rgb-43-43-43,
            WidgetValuation-border-table: $black-rgb-72-72-72,
            WidgetValuation-color-text-green-table: $theme-dark-text-priceboard-green,
            WidgetValuation-color-text-red-table: $theme-dark-text-priceboard-red,
            WidgetValuation-color-header: $black-rgb-30-30-30,
            WidgetValuation-color-button-calc-pe: $green,
            WidgetValuation-color-text-input: $black-rgb-43-43-43,

            WidgetComparison-color-text-normal: $white-rgb-218-218-218,
            WidgetComparison-background-container: $black-rgb-52-52-52,
            WidgetComparison-color-text-green-table: $theme-dark-text-priceboard-green,
            WidgetComparison-color-text-red-table: $theme-dark-text-priceboard-red,
            WidgetComparison-border-table: $black-rgb-72-72-72,
            WidgetComparison-background-button-add-new: $green,
            WidgetComparison-color-header-table: $black-rgb-46-46-46,

            WidgetSignal-color-text-normal: $white-rgb-218-218-218,
            WidgetSignal-background-container: $black-rgb-52-52-52,
            WidgetSignal-background-recommend-content: $black-rgba-17-17-17-030,
            WidgetSignal-border-table: $black-rgb-72-72-72,
            WidgetSignal-background-button-view: $green,
            WidgetSignal-background-button-back-test: $black-rgb-46-46-46,
            WidgetSignal-background-header-table: $black-rgb-46-46-46,
            WidgetSignal-color-title-modal: $white-rgb-218-218-218,
            WidgetSignal-background-header-modal: $black-rgb-52-52-52,

            WidgetReport-color-text-normal: $white-rgb-218-218-218,
            WidgetReport-background-container: $black-rgb-52-52-52,
            WidgetReport-background-recommend-content: $black-rgba-17-17-17-030,
            WidgetReport-border-table: $black-rgb-72-72-72,
            WidgetReport-background-button-view: $green,
            WidgetReport-background-header-table: $black-rgb-46-46-46,

            // -- 9. Widget Symbol Detail (Overview, Trading, Finace,...) === [End]
            // =====================================================================================================

            // -- 10. Account Register === [Start]
            AccountRegister-required-asterisk: $red-rgb-232-31-31,
            //10.1 Account Register Header
            AccountRegister-header-title-color: $white-rgb-255-255-255,
            AccountRegister-header-title-size: $font-size-16,
            AccountRegister-header-title-font: $font-family,
            AccountRegister-header-stepname-color: $white-rgb-255-255-255,
            AccountRegister-header-stepname-size: $font-size-14,
            AccountRegister-header-stepname-font: $font-family,
            //10.2 Account Register Section
            AccountRegister-section-background: $black-rgb-35-35-35,
            AccountRegister-section-header-background: $black-rgb-18-18-18,
            AccountRegister-section-bottom-background: $black-rgb-18-18-18,
            AccountRegister-section-header-title-color: $white-rgb-255-255-255,
            AccountRegister-section-header-title-size: $font-size-16,
            AccountRegister-section-header-title-font: $font-family,
            //10.2.1 Account Register Section Body
            AccountRegister-sectionBody-background: $black-rgb-35-35-35,
            AccountRegister-sectionBody-input-background: $white-rgb-204-204-204,
            AccountRegister-sectionBody-input-height: $height-30,
            AccountRegister-sectionBody-input-hover: $orange-rbg-255-172-66,
            AccountRegister-sectionBody-infotitle-color: $white-rgb-255-255-255,
            AccountRegister-sectionBody-infotitle-size: $font-size-16,
            AccountRegister-sectionBody-infotitle-font: $font-family,
            AccountRegister-sectionBody-text-color: $white-rgb-255-255-255-08,
            AccountRegister-sectionBody-text-size: $font-size-13,
            AccountRegister-sectionBody-text-font: $font-family,
            //10.2.1.1 Account Register Section Body Step 1
            //10.2.1.2 Account Register Section Body Step 2
            //10.2.1.3 Account Register Section Body Step 3
            //10.2.1.4 Account Register Section Body Step 4
            //10.2.1.5 Account Register Section Body Step 5
            AccountRegister-sectionBody-s5-btn-bg: $black-rgb-18-18-18,
            AccountRegister-sectionBody-s5-btn-color: $white-rgb-251-251-251,
            AccountRegister-sectionBody-s5-btn-height: $height-30,
            //10.3 Account Register Bottom Note
            AccountRegister-bottomNote-background: $black-rgb-18-18-18,
            //10.4 Account Register Bottom
            AccountRegister-bottom-button-background: $black-rgb-35-35-35,
            AccountRegister-bottom-button-hover: $orange-rbg-255-172-66,

            // -- 10. Account Register === [End]

            // -- 11. Create Account === [Start]
            // Common
            CreateAccount-section-title-bg: $theme-dark-dark-1,
            CreateAccount-section-title-color: $white-rgb-255-255-255,
            CreateAccount-section-asterisk-color: $rgb-255-55-55,
            CreateAccount-section-btn-color: $white-rgb-255-255-255,
            CreateAccount-step-bg-color: $black-rgb-37-37-37,

            // 11.0 Introduction Create Account
            AccountRegister-sectionBody-title-size: $font-size-28,
            AccountRegister-section-header-btnStart-color: $white-rgb-255-255-255,
            AccountRegister-sectionBody-btnStart-bg: $rgb-0-192-135,
            AccountRegister-sectionBody-btnStart-effect: #90EE90,
            AccountRegister-sectionBody-btnBack-bg: $black-rgb-49-49-49,
            // 11.2 Create Account Step 1
            CreateAccount-sectionBody-step1-color: $black-rgb-18-18-18,
            CreateAccount-sectionBody-step1-input-bg-color: $white-rgb-204-204-204,
            CreateAccount-section-step1-bg-tr-even-color: $white-rgb-231-231-231,
            CreateAccount-section-step1-bg-tr-odd-color: $white-rgb-242-242-242,
            CreateAccount-section-step1-table-border: $white-rgb-255-255-255,
            CreateAccount-section-step1-mapTitle-color: $white-rgb-255-255-255,
            CreateAccount-section-step1-map-content-color: $white-rgb-255-255-255,
            CreateAccount-section-step1-map-icon-color: $primary,
            CreateAccount-section-step1-map-icon-bg-color: $rgb-0-192-135-01,
            // 11.2 Create Account Step 2
            // 11.3 Create Account Step 3
            CreateAccount-section-step3-input-bg-color: $white-rgb-204-204-204,
            CreateAccount-section-step3-input-text-color: $black-rgb-18-18-18,
            CreateAccount-section-step3-input-effect-color: $primary,
            // 11.4 Create Account Step 4
            CreateAccount-section-step4-input-bg-color: $white-rgb-204-204-204,
            CreateAccount-sectionBody-step4-color: $black-rgb-18-18-18,
            // 11.5 Create Account Step 5
            CreateAccount-section-step5-para-color: $white-rgb-204-204-204,
            // 11.5 Create Account Step 5OTP
            AccountRegister-step5otp-bg-color: $black-rgb-37-37-37,
            AccountRegister-step5otp-btnCancel-color: $black-rgb-58-58-58,
            // 11.5 Create Account Step 5OTP1
            AccountRegister-step5otp1-label-color: $white-rgb-204-204-204,
            AccountRegister-step5otp1-input-bg-color: $white-rgb-204-204-204,
            AccountRegister-step5otp1-input-color: $black-rgb-18-18-18,
            AccountRegister-step5otp1-bottom-bg-color: $black-rgb-18-18-18,
            // 11.5 Create Account Step 5OTP2
            AccountRegister-step5otp2-effect-color: $primary,
            AccountRegister-step5otp2-bottom-bg-color: $black-rgb-18-18-18,
            AccountRegister-step5otp2-otp-content: $white-rgb-204-204-204,
            AccountRegister-step5otp2-otp-color: $white-rgb-204-204-204,
            // -- 11. Create Account === [End]


            // =====================================================================================================
            //50.MarketWatch - Widget Market === [Begin]
            MarketWatchTextCeilPrice: $purple-rgb-162-37-138,
            MarketWatchTextFloorPrice: $blue-rgb-30-151-190,
            MarketWatchTextRedPrice: $red-rgb-188-58-54,
            MarketWatchTextGreenPrice: $green-rgb-90-181-92,
            MarketWatchTextRefPrice: $yellow-rgb-252-218-80,

            MW-WidgetMarket-popover-parent-background: $black-rgb-52-52-52,
            MW-WidgetMarket-popover-child-background: $black-rgb-43-43-43,
            MW-WidgetMarket-popover-active-button-color: $orange-rbg-217-122-8,
            MW-WidgetMarket-color-text-normal: $white-rgb-218-218-218,

            //50.MarketWatch - Widget Market === [End]
            // =====================================================================================================

            //51.Trade - SymbolSelector === [Begin]
            Trade-SymboSelector-input-background: $white-rgb-204-204-204,
            Trade-SymboSelector-input-text-color: $black-rgb-0-0-0,
            Trade-SymboSelector-icon-color: $black-rgb-0-0-0,
            //51.Trade - SymbolSelector === [End]
            // =====================================================================================================

            //52.Trade - OrderInputContainer === [Begin]
            Trade-OrderInputContainer-checkbox-label-background: $white-rgb-255-255-255,
            Trade-OrderInputContainer-checkbox-disable-label-text-color:$white-rgb-153-153-153,
            Trade-OrderInputContainer-checkbox-label-checked-background: $rgb-0-192-135,
            Trade-OrderInputContainer-checkbox-span-color: $black-rgb-0-0-0,
            Trade-OrderInputContainer-capacity-container-hr: $black-rgb-68-68-68,
            Trade-OrderInputContainer-btn-sell: $rgb-255-55-55,
            Trade-OrderInputContainer-btn-sell-hover: $rgb-255-105-105,
            Trade-OrderInputContainer-btn-buy: $rgb-0-192-135,
            Trade-OrderInputContainer-btn-buy-hover: $rgb-30-222-165,
            //52.Trade - OrderInputContainer === [End]
            // =====================================================================================================

            //53.Trade - OrderActionSelector === [Begin]
            Trade-OrderActionSelector-sell-tab-item-background: $rgb-255-55-55,
            Trade-OrderActionSelector-buy-tab-item-background: $rgb-0-192-135,
            //53.Trade - OrderActionSelector === [End]
            // =====================================================================================================

            //54.SideMenu === [Begin]
            SideMenu-menu-list-menu-link-color-hover: $rgb-0-192-135,
            //54.SideMenu === [End]
            // =====================================================================================================

            //55.Account Balance=== [Begin]
            AccountBalance-custom-div-redirect-color-hover: $rgb-0-192-135,
            AccountBalance-custom-div-redirect-label-color-hover: $rgb-0-192-135,
            //55.SideMenu === [End]
            // =====================================================================================================

            //70.NotRight - Widget NotRight === [Begin]
            NotRight-width: 100%,
            NotRight-height: 30px,
            NotRight-font-family: $font-family,
            NotRight-font-size: $font-size-13,
            NotRight-a-font-size: $font-size-14,
            NotRight-a-hover-font-size: $font-size-15,
            NotRight-text-color: $white-rgb-207-207-207,
            NotRight-a-font-weight: 700,
            NotRight-a-color: $orange-rbg-217-122-8,
            NotRight-a-color-active: $orange-rbg-255-172-66,
            NotRight-a-color-hover: $orange-rbg-255-172-66,
            //70.NotRight - Widget NotRight === [End]
            // =====================================================================================================


            //100.B.PageFooter === [Begin]
            //--100.1.PageFooter basic
            PageFooter-width: 100%,
            PageFooter-height: 40px,
            PageFooter-font-family: $font-family,
            PageFooter-font-size: $font-size-14,
            PageFooter-z-index: $z-index-layer-1,
            PageFooter-text-color: $white-rgb-231-231-231,
            PageFooter-background: $black-rgba-60-60-60-095,
            PageFooter-shadow: $black-rgba-60-60-60-095,
            PageFooter-menu-not-right-text-color: $white-rgb-166-166-166,
            //--100.E.WidgetBase === [End]

            //--102 Filter Algo ===  [Begin]
            Filter-color-text-input-placeholder: $white-rgb-231-231-231,
            Filter-header-name-input-color: $white-rgb-231-231-231,
            Filter-input-search-color: $white-rgb-231-231-231,
            Filter-dropdown-color: $white-rgb-231-231-231,
            Filter-input-select-color: $white-rgb-231-231-231,
            Filter-header-button-color: $white-rgb-231-231-231,
            Filter-filter-color: $white-rgb-231-231-231,

            Filter-input-search-background: $black-rgb-68-68-68,
            Filter-dropdown-background:$black-rgb-68-68-68,
            Filter-input-select-background: $black-rgb-68-68-68,
            Filter-filters-background-color: $black-rgb-68-68-68,
            Filter-filter-content-background: $black-rgb-68-68-68,
            Filter-filters-border-color: $black-rgb-68-68-68,
            Filter-filter-content-border-color:$black-rgb-68-68-68,

            Filter-filter-content-icon-color: $rgb-0-192-135,
            Filter-header-button-background: $rgb-0-192-135,
            Filter-header-name-input-border-bottom: $rgb-0-192-135,

            Filter-filters-active-background-color: $black-rgb-35-35-35,
            Filter-filter-hover-backgroud-color: $black-rgb-35-35-35,

            Filter-filter-item-remove-color: $rgb-255-55-55,

            Filter-filter-range-input-color: $black-rgb-35-35-35,

            //--102 Filter Algo ===  [End]


            //--200.CustomTableNew
            CustomTableNew-th-background: $black-rgb-30-30-30,
            CustomTableNew-th-input-background: $black-rgb-46-46-46,
            CustomTableNew-td-background: $black-rgb-18-18-18,
            CustomTableNew-tfoot-color: $rgb-0-192-135,
            CustomTableNew-tfoot-input-color: $black-rgb-0-0-0,
            CustomTableNew-paging-hover-backgrond: $rgb-30-222-165,
            CustomTableNew-paging-color: $white-rgb-255-255-255,
            CustomTableNew-enter-item-border-color: $rgb-0-192-135,
            CustomTableNew-tr-background-hover: $black-rgb-68-68-68,
            //--200.CustomTableNew === [End]

            //--201.LayoutSettingTable
            LayoutSettingTable-drag-row-item-color: $white-rgb-255-255-255,
            LayoutSettingTable-drag-row-item-selector: $rgb-0-192-135,
            LayoutSettingTable-drag-row-item-background: $rgb-66-133-244,
            LayoutSettingTable-drag-row-item-border: $white-rgb-240-240-240,
            LayoutSettingTable-ant-card-h2-color: $rgb-0-192-135,
            LayoutSettingTable-ant-card-background: $black-rgb-30-30-30,
            LayoutSettingTable-ant-table-color: $white-rgb-255-255-255,
            LayoutSettingTable-ant-table-th-background: $black-rgb-18-18-18,
            LayoutSettingTable-ant-table-td-background: $black-rgb-46-46-46,
            LayoutSettingTable-ant-table-border-color: $black-rgb-68-68-68,
            LayoutSettingTable-modal-footer-button-background: $rgb-0-192-135,
            //--201.LayoutSettingTable === [End]

            // =====================================================================================================

            //--500.Customize dropdown component === [Begin]
            Dropdown-button-background: $black-rgb-43-43-43,
            Dropdown-body-parent-background: $black-rgb-52-52-52,
            Dropdown-body-child-background: $black-rgb-43-43-43,
            Dropdown-child-active: $orange-rbg-217-122-8,
            Dropdown-text-normal: $white-rgb-218-218-218,
            //--500.Customize dropdown component === [End]

            //--600.Quick Order component === [Begin]
            Quickorder-background: $black-rgb-68-68-68,
            Quickorder-buy-button-background: $rgb-0-192-135,
            Quickorder-buy-button-border-color:$rgb-17-40-33,
            Quickorder-sell-button-background: $rgb-255-55-55,
            Quickorder-sell-button-border-color:$rgb-52-20-29,
            Quickorder-notifi-error-background: $black-rgb-18-18-18,
            Quickorder-notifi-error-color:$rgb-255-55-55,
            //--600.Quick Order component === [End]

            // COMMON
            common-red: $rgb-255-55-55,
            common-white: $theme-dark-white,
            common-gray: $theme-dark-gray,
            common-green: $theme-dark-green,
            common-orange: $theme-dark-orange,
            common-blue: $theme-dark-blue,
            common-purple: $theme-dark-purple,
            common-black: $theme-dark-black,
            common-dark-light: $dark-light-price-board,
            // PRICEBOARD
            // PRICEBOARD
            table-header-color: $theme-dark-table-header-color,
            table-header-text-color: $theme-dark-table-header-text-color,
            table-border: $theme-dark-table-border,
            text-floor-price: $theme-dark-text-floor-price,
            text-ceil-price: $theme-dark-text-ceil-price,
            text-ref-price: $theme-dark-text-ref-price,
            text-priceboard-green: $theme-dark-text-priceboard-green,
            text-priceboard-red: $theme-dark-text-priceboard-red,
            cell-group-item:$theme-dark-cell-group-item,
            cell-group-item-lighter:$theme-dark-cell-group-item-lighter,
            // BODY
            label-text: $gray,
            body-bg: $theme-dark-dark-3,
            carbon-bg: url("../assets/images/bg-pattern4.svg") repeat center / (6px 6px),
            scrollbar-track-bg: rgba(255, 255, 255, 0.10),
            scrollbar-thumb-bg: rgba(255, 255, 255, 0.4),
            scrollbar-thumb-hover-bg: rgba(255, 255, 255, 0.8),
            // HEADER
            header-bg: $theme-dark-dark-1,
            header-bg-hover: $theme-dark-dark-2,
            header-shadow: '0 4px 4px 0 #{$theme-dark-dark-3}',
            header-border: $theme-dark-dark-3,
            header-text-color: $theme-dark-white,
            header-active-text-color: $rgb-0-192-135,
            header-dropdown-menu-bg: rgba($dark-2, 0.95),
            header-dropdown-menu-bg-hover: rgba($dark-3, 0.95),
            header-dropdown-text-color: $theme-dark-white,
            header-dropdown-active-text-color: $theme-dark-orange,
            header-dropdown-menu-border: $theme-dark-dark-3,
            header-combobox-border: $theme-dark-dark-3,
            header-combobox-bg: $theme-dark-dark-2,
            header-notification: $theme-dark-orange,
            // SECTION
            section-bg: rgba($theme-dark-dark-2_, 1),
            section-bg-strong: black,
            section-header-bg: $theme-dark-dark-1,
            section-header-hover-bg: $theme-dark-dark-2,
            section-header-text-color: $theme-dark-white,
            section-header-active-text-color: $rgb-0-192-135,
            section-header-text-gray: $theme-dark-gray,
            section-header-border-top: $fss-orange,
            section-body-dark-1: $theme-dark-dark-1,
            section-body-dark-2: $theme-dark-dark-2,
            section-body-dark-3: $theme-dark-dark-3,
            section-body-border: $theme-dark-border,
            section-body-border-2: $theme-light-border-2,
            section-body-border-highlight: $theme-dark-gray,
            section-body-text-normal: $theme-dark-white,
            section-body-text-gray: $white-rgb-255-255-255,
            section-body-text-acitve: $rgb-0-192-135, //light: blue, dark orange
            section-body-gray-bg    : darken($theme-dark-gray, 35),
            // FOOTER BACKGROUND
            footer-bg: $theme-dark-dark-1,
            // WIDGET SIDEBAR
            widget-sidebar-bg: $theme-dark-dark-1,
            widget-sidebar-bg-hover: $theme-dark-dark-2,
            widget-sidebar-border: $theme-dark-dark-3,
            widget-sidebar-text-color: $theme-dark-white,
            widget-sidebar-active-text-color: $theme-dark-orange,
            widget-sidebar-hider-border: $theme-dark-dark-3,
            widget-sidebar-hider-bg: $theme-dark-dark-1,
            widget-floating-item-border: $theme-dark-orange,
            widget-floating-item-bg:$dark-2,
            widget-box-shadow: #0000004d,
            react-table-input-filter: $theme-dark-dark-3,
            // ACCOUNT + SETTINGS
            left-sidebar-bg: $theme-dark-dark-2,
            left-sidebar-text-level-1: $theme-dark-gray,
            left-sidebar-text-level-2: $theme-dark-white,
            left-sidebar-text-level-2-active: $theme-dark-orange,
            basic-table-header-bg: darken($theme-dark-dark-2, 2),
            basic-table-border: $black-rgb-68-68-68,
            basic-table-text: $white-rgb-255-255-255,
            basic-table-header-text: $theme-dark-white,
            basic-table-header-border: $theme-dark-border,
            basic-table-body-bg: $theme-dark-dark-2,
            basic-table-row-odd-bg: $theme-dark-dark-1__,
            basic-table-footer-bg: darken($theme-dark-dark-2, 2),
            basic-table-body-text: $theme-dark-white,
            ultility-finish-icon: $green,
            // FORM
            form-control-label: $theme-dark-gray,
            form-control-text-color: $theme-dark-white,
            form-control-bg: $theme-dark-dark-3,
            form-control-readonly-text-color: $theme-dark-white,
            form-control-readonly-bg: transparent,
            form-control-select-bg-img: url("../assets/images/dropdown-dark.svg"),
            form-control-select-suggest-bg-img: url("../assets/images/down-dark-arrow.svg"),
            form-control-focus-color:$rgb-30-222-165,
            wizard-icon-active:$rgb-0-192-135,
            wizard-icon-bg: $theme-dark-dark-1,
            wizard-icon-active-color: $theme-light-black,
            form-control-border-focus: $rgb-0-192-135,
            // TOAST
            toast-success-bg: $theme-dark-green,
            toast-error-bg: $theme-dark-red,
            toast-warn-bg: $theme-dark-orange,
            toast-info-bg: $theme-dark-blue,
            toast-shadow-color: $theme-dark-dark-3,
            toast-close-color: $theme-dark-white,
            toast-text-color: $theme-dark-white,
            // MODAL
            modal-header-bg: $theme-dark-dark-1,
            modal-header-hover-bg: $theme-dark-dark-2,
            modal-header-icon-color: $theme-dark-white,
            modal-header-text-color: $theme-dark-white,
            modal-body-bg: $theme-dark-dark-2,
            modal-body-text-normal: $theme-dark-white,
            modal-body-text-total: $theme-dark-dark-1,
            modal-footer-bg: $theme-dark-dark-1,
            modal-warning-bg: $theme-light-red,
            modal-tpsl-item-bg: $black-rgb-24-24-24,
            // DATE PICKER
            date-picker-bg: $theme-dark-dark-1,
            date-picker-border: darken(#3f4458, 50%),
            date-picker-arrow-hover: $theme-dark-orange,
            date-picker-month-fg: #fff,
            date-picker-month-bg: $theme-dark-dark-1,
            date-picker-weekdays-fg: #fff,
            date-picker-weekdays-bg: transparent,
            date-picker-day-fg: rgba(white, 0.95),
            date-picker-day-hover-bg: lighten($theme-dark-dark-1, 25%),
            date-picker-today-border: #eee,
            date-picker-today-bg: #eee,
            date-picker-today-fg: #3f4458,
            date-picker-day-selected-fg: #fff,
            date-picker-day-selected-bg: $theme-dark-green,
            //NOTIFICATION 
            notification-bg: $theme-dark-dark-1,
            notification-inner: $theme-dark-dark-3,
            notification-date: $gray,
            notification-title:$theme-dark-orange,
            notification-inactive:$gray,
            notification-active:$theme-dark-orange,
            // BUTTON
            button-bg:$theme-dark-orange,
            btn-buy-bg: $theme-dark-green,
            btn-cancel-color: #ffffff80,
            btn-cancel-bg: #313131,
            // INPUT
            input-active-bg: $rgb-0-192-135,
            input-disable-bg: lighten($rgb-0-192-135, 20%),
            background-order-input: #ccc,
            text-color-order-input: #000,
            text-note-color-order-input: #3D3D3D,
            range-input-background-mark: #3a3a3a,
            range-input-background-mark-light: #cccccc,
            range-input-border-mark: #232323,
            range-input-background-thumb: #232323,
            range-input-border-thumb: #cccccc,
            // separator
            separate-line: #dddddd),

      light: ( // COMMON
            //1.B.PageHeader === [Begin]
            //--1.1.PageHeader basic
            PageHeader-width: 100%,
            PageHeader-height: 40px,
            PageHeader-font-family: $font-family,
            PageHeader-font-size: $font-size-14,
            PageHeader-z-index: $z-index-layer-1,
            PageHeader-text-color: $black-rgb-68-68-68,
            PageHeader-background: $white-rgb-207-207-207,
            PageHeader-shadow: $white-rgb-207-207-207,
            //--1.2.PageHeader-logo
            PageHeader-logo-width: 100px,
            PageHeader-logo-height: 40px,
            //--1.3.PageHeader-menu cấp 1
            PageHeader-menu-width: 100%,
            PageHeader-menu-height: 40px,
            PageHeader-menu-font-family: $font-family,
            PageHeader-menu-font-size: $font-size-14,
            PageHeader-menu-text-color: $black-rgb-68-68-68,
            PageHeader-menu-text-color-hover: $rgb-0-192-135,
            PageHeader-menu-text-color-active: $rgb-0-192-135,
            PageHeader-menu-background: $white-rgb-207-207-207,
            PageHeader-menu-background-hover: $white-rgb-218-218-218,
            PageHeader-menu-border: $black-rgb-63-63-63,
            //--1.4.PageHeader-menu cấp 2
            PageHeader-menu-2-width: 100%,
            PageHeader-menu-2-height: 40px,
            PageHeader-menu-2-font-family: $font-family,
            PageHeader-menu-2-font-size: $font-size-14,
            PageHeader-menu-2-text-color: $black-rgb-68-68-68,
            PageHeader-menu-2-text-color-hover: $orange-rbg-217-122-8,
            PageHeader-menu-2-text-color-active: $rgb-0-192-135,
            PageHeader-menu-2-background: $white-rgb-207-207-207,
            PageHeader-menu-2-background-hover: $white-rgb-218-218-218,
            PageHeader-menu-2-border: $black-rgb-63-63-63,
            PageHeader-menu-not-right-text-color: $white-rgb-166-166-166,
            //--1.5.PageHeader-popup
            PageHeader-popup-width: 100%,
            PageHeader-popup-height: 100%,
            PageHeader-popup-text-color: 100%,
            PageHeader-popup-text-color-hover: 100%,
            PageHeader-popup-background: 100%,
            PageHeader-popup-background-hover: 100%,
            //--1.6.PageHeader-icon
            PageHeader-icon-width: 100%,
            PageHeader-icon-height: 100%,
            PageHeader-icon-font-size: $font-size-20,
            PageHeader-icon-text-color: $black-rgb-68-68-68,
            PageHeader-icon-text-color-hover: $orange-rbg-217-122-8,
            PageHeader-icon-color: $black-rgb-68-68-68,
            PageHeader-icon-color-hover: $rgb-0-192-135,
            PageHeader-icon-background: $white-rgb-207-207-207,
            PageHeader-icon-background-hover: $white-rgb-218-218-218,
            //--1.7.PageHeader-time
            PageHeader-time-width: 100%,
            PageHeader-time-height: 40px,
            PageHeader-time-font-family: $font-family,
            PageHeader-time-font-size: $font-size-14,
            PageHeader-time-color: $black-rgb-68-68-68,
            //--1.8.PageHeader-btn
            PageHeader-btn-width: 40px,
            PageHeader-btn-height: 40px,
            PageHeader-btn-font-family: $font-family,
            PageHeader-btn-font-size: $font-size-14,
            PageHeader-btn-text-color: $black-rgb-68-68-68,
            PageHeader-btn-text-color-hover: $rgb-0-192-135,
            //--1.9.PageHeader-p
            PageHeader-p-width: 100%,
            PageHeader-p-height: 100%,
            PageHeader-p-font-size: $font-size-15,
            //--1.10.PageHeader-dropdown
            PageHeader-dropdown-width: 100%,
            PageHeader-dropdown-height: 40px,
            PageHeader-dropdown-font-family: $font-family,
            PageHeader-dropdown-font-size: $font-size-14,
            PageHeader-dropdown-text-color: $black-rgb-68-68-68,
            PageHeader-dropdown-text-color-hover: $orange-rbg-255-172-66,
            PageHeader-dropdown-text-color-active: $orange-rbg-255-172-66,
            PageHeader-dropdown-background: $white-rgb-207-207-207,
            PageHeader-dropdown-background-hover: $white-rgb-218-218-218,
            PageHeader-dropdown-border: $white-rgb-255-255-255,
            //1.E.PageHeader === [End]

            // =====================================================================================================

            // 2.B.WidgetBase === [Begin]
            //--2.1.WidgetBase basic
            WidgetBase-width: 100%,
            WidgetBase-height: 100%,
            WidgetBase-font-family: $font-family,
            WidgetBase-font-size: $font-size-14,
            WidgetBase-z-index: $z-index-layer-2,
            WidgetBase-text-color: $black-rgb-20-20-20,
            WidgetBase-background: $white-rgb-207-207-207,
            WidgetBase-border: $black-rgba-83-83-83-015,
            //--2.2.WidgetBase header
            WidgetBase-header-width: 100%,
            WidgetBase-header-height: 30px,
            WidgetBase-header-font-family: $font-family,
            WidgetBase-header-font-size: $font-size-14,
            WidgetBase-header-background: $white-rgb-207-207-207,
            WidgetBase-header-border: $white-rgb-207-207-207,
            WidgetBase-header-shadow: $white-rgb-207-207-207,
            WidgetBase-header-icon-width: 100%,
            WidgetBase-header-icon-height: 30px,
            WidgetBase-header-icon-font-size: $font-size-14,
            WidgetBase-header-icon-text-color: $black-rgb-20-20-20,
            WidgetBase-header-icon-text-color-hover: $orange-rbg-217-122-8,
            WidgetBase-header-icon-color: $black-rgb-20-20-20,
            WidgetBase-header-icon-color-hover: $rgb-0-192-135,
            WidgetBase-header-icon-background: $white-rgb-238-238-238,
            WidgetBase-header-icon-background-hover: $white-rgb-240-240-240,
            WidgetBase-header-btn-font-size: $font-size-14,
            WidgetBase-header-btn-height: 30px,
            WidgetBase-header-btn-text-color: $black-rgb-20-20-20,
            WidgetBase-header-btn-text-color-hover: $rgb-0-192-135,
            WidgetBase-header-btn-text-color-active: $rgb-0-192-135,
            WidgetBase-header-btn-border-active: $white-rgb-251-251-251,
            WidgetBase-hide-header-z-index: $z-index-layer-1,
            WidgetBase-hide-header-width-div-hover: 200px,
            // WidgetBase-header-btn-background: none,
            // WidgetBase-header-btn-background-hover: none,
            //--2.2.WidgetBase body
            WidgetBase-body-width: 100%,
            WidgetBase-body-height: calc(100vh - 110px),
            WidgetBase-body-font-family: $font-family,
            WidgetBase-body-font-size: $font-size-14,
            WidgetBase-body-background: $white-rgb-230-230-230,
            WidgetBase-body-border: $white-rgb-230-230-230,
            WidgetBase-body-shadow: $white-rgb-230-230-230,
            //--2.3.WidgetBase scroll
            WidgetBase-scroll-border-x: $white-rgb-147-144-144,
            WidgetBase-scroll-border-y: $white-rgb-147-144-144,
            WidgetBase-scroll-border-xy: $white-rgb-100-100-100,
            //--2.3.WidgetBase scroll
            WidgetBase-menu-width: 100%,
            WidgetBase-menu-height: 30px,
            WidgetBase-menu-font-family: $font-family,
            WidgetBase-menu-font-size: $font-size-14,
            WidgetBase-menu-text-color: $black-rgba-0-0-0-050,
            WidgetBase-menu-text-color-active: $black-rgb-0-0-0,
            WidgetBase-menu-text-color-hover: $white-rgb-255-255-255,
            WidgetBase-menu-background: $black-rgb-68-68-68,
            WidgetBase-menu-background-hover: $black-rgb-68-68-68,
            //--2.E.WidgetBase === [End]

            // =====================================================================================================

            // 3.B.WidgetPriceBoard === [Begin]
            //--3.1.WidgetPriceBoard basic
            WidgetPriceBoard-width: 100%,
            WidgetPriceBoard-height: 100%,
            WidgetPriceBoard-font-family: $font-family,
            WidgetPriceBoard-font-size: $font-size-14,
            WidgetPriceBoard-z-index: $z-index-layer-2,
            WidgetPriceBoard-text-color: $black-rgb-20-20-20,
            WidgetPriceBoard-background: $black-rgb-68-68-68,
            //--3.2.WidgetPriceBoard menu
            WidgetPriceBoard-menu-width: 100%,
            WidgetPriceBoard-menu-height: 100%,
            WidgetPriceBoard-menu-font-family: $font-family,
            WidgetPriceBoard-menu-font-size: $font-size-14,
            WidgetPriceBoard-menu-z-index: $z-index-layer-2,
            WidgetPriceBoard-menu-text-color: $black-rgb-20-20-20,
            WidgetPriceBoard-menu-text-color-active: $rgb-0-192-135,
            WidgetPriceBoard-menu-text-color-hover: $white-rgb-255-255-255,
            WidgetPriceBoard-menu-background: $white-rgb-230-230-230,
            WidgetPriceBoard-menu-background-hover: $white-rgb-230-230-230,
            WidgetPriceBoard-menu-border: $white-rgb-204-204-204,
            WidgetPriceBoard-menu-border-active: $rgb-0-192-135,
            WidgetPriceBoard-menu-border-hover: $rgb-0-192-135,

            //
            WidgetPriceBoard-menu-2-width: 100%,
            WidgetPriceBoard-menu-2-height: 100%,
            WidgetPriceBoard-menu-2-font-family: $font-family,
            WidgetPriceBoard-menu-2-font-size: $font-size-14,
            WidgetPriceBoard-menu-2-z-index: $z-index-layer-2,
            WidgetPriceBoard-menu-2-text-color: $black-rgb-20-20-20,
            WidgetPriceBoard-menu-2-text-color-active: $rgb-0-192-135,
            WidgetPriceBoard-menu-2-text-color-hover: $rgb-0-192-135,
            WidgetPriceBoard-menu-2-background: $white-rgb-207-207-207,
            WidgetPriceBoard-menu-2-background-hover: $white-rgb-207-207-207,
            WidgetPriceBoard-menu-2-border: $white-rgb-204-204-204,
            WidgetPriceBoard-menu-2-border-active: $rgb-0-192-135,
            WidgetPriceBoard-menu-2-border-hover: $rgb-0-192-135,
            WidgetPriceBoard-menu-2-btn-background: $white-rgb-230-230-230,
            WidgetPriceBoard-menu-2-btn-background-hover: $white-rgb-218-218-218,
            WidgetPriceBoard-menu-2-btn-2-background: $white-rgb-218-218-218,
            WidgetPriceBoard-menu-2-btn-2-border-bottom: $white-rgb-193-193-193,
            WidgetPriceBoard-menu-2-btn-2-border: $white-rgb-207-207-207,
            WidgetPriceBoard-menu-2-icon-add-color-hover: $theme-light-text-priceboard-green,
            WidgetPriceBoard-menu-2-icon-edit-color-hover: $theme-light-text-ref-price,
            WidgetPriceBoard-menu-2-icon-delete-color-hover: $rgb-255-55-55,

            //--3.3.WidgetPriceBoard table
            //--3.3.1. WidgetPriceBoard table header
            WidgetPriceBoard-table-header-width: 100%,
            WidgetPriceBoard-table-header-height: 100%,
            WidgetPriceBoard-table-header-font-family: $font-family,
            WidgetPriceBoard-table-header-font-size: $font-size-12,
            WidgetPriceBoard-table-header-z-index: $z-index-layer-2,
            WidgetPriceBoard-table-header-text-color: $black-rgb-20-20-20,
            WidgetPriceBoard-table-header-background: $white-rgb-240-240-240,
            WidgetPriceBoard-table-header-border: $white-rgb-204-204-204,
            WidgetPriceBoard-table-toggle-indicator-color: $black-rgb-20-20-20,
            //--3.3.2. WidgetPriceBoard table body
            WidgetPriceBoard-table-body-width: 100%,
            WidgetPriceBoard-table-body-height: 100%,
            WidgetPriceBoard-table-body-font-family: $font-family-1,
            WidgetPriceBoard-table-body-font-size: $font-size-12,
            WidgetPriceBoard-table-body-z-index: $z-index-layer-2,
            WidgetPriceBoard-table-body-text-color: $black-rgb-20-20-20,
            WidgetPriceBoard-table-body-background: $white-rgb-251-251-251,
            WidgetPriceBoard-table-body-border: $white-rgb-204-204-204,
            WidgetPriceBoard-table-row-height: 28px,
            WidgetPriceBoard-table-row-1-background: $white-rgb-238-238-238,
            WidgetPriceBoard-table-row-1-border: $white-rgb-204-204-204,
            WidgetPriceBoard-table-row-1-light-background: $white-rgb-214-214-214,
            WidgetPriceBoard-table-row-1-light-border: $white-rgb-204-204-204,
            WidgetPriceBoard-table-row-1-background-hover: $white-rgb-218-218-218,
            //
            WidgetPriceBoard-table-row-2-background: $white-rgb-251-251-251,
            WidgetPriceBoard-table-row-2-border: $white-rgb-204-204-204,
            WidgetPriceBoard-table-row-2-light-background: $white-rgb-230-230-230,
            WidgetPriceBoard-table-row-2-light-border: $white-rgb-204-204-204,
            WidgetPriceBoard-table-row-2-background-hover: $white-rgb-218-218-218,
            //--3.E.WidgetPriceBoard === [End]
            // =====================================================================================================

            // -- 4. Widget IndexesList === [Start]
            //4.1 Indexes List Header

            //4.2 Indexes List Body
            WidgetIndexesList-charts-span-font-size: $font-size-12,
            WidgetIndexesList-charts-select-background:$white-rgb-230-230-230,
            WidgetIndexesList-charts-list-p-background: $white-rgb-229-229-229,
            WidgetIndexesList-charts-select-color: $black-rgb-0-0-0,
            WidgetIndexesList-charts-select-border: $white-rgb-230-230-230,
            WidgetIndexesList-text-normal: $white-rgb-100-100-100,
            WidgetIndexesList-text-ref-price: $theme-light-text-ref-price,
            WidgetIndexesList-text-floor-price: $theme-light-text-floor-price,
            WidgetIndexesList-text-ceil-price: $theme-light-text-ceil-price,
            WidgetIndexesList-text-priceboard-green: $theme-light-text-priceboard-green,
            WidgetIndexesList-text-priceboard-red: $theme-light-text-priceboard-red,
            WidgetIndexesList-text-priceboard-orange: $orange-rbg-247-148-29,

            // -- 4. Widget IndexesList === [End]
            // =====================================================================================================

            // =====================================================================================================

            // -- 5. Widget Symbol detail === [Start]
            //5.1 Widget Symbol detail Header

            //5.2 Widget Symbol detail Body
            WidgetSymbolDetail-footer-background: $white-rgb-218-218-218,
            WidgetSymbolDetail-footer-icon: $white-rgb-147-144-144,
            WidgetSymbolDetail-footer-icon-active: $orange-rbg-247-148-29,
            WidgetSymbolDetail-charts-footer-background: $white-rgb-218-218-218,
            WidgetSymbolDetail-charts-footer-icon-active: $orange-rbg-247-148-29,
            WidgetSymbolDetail-text-font-size-13: $font-size-13,
            WidgetSymbolDetail-text-font-size-16: $font-size-16,
            WidgetSymbolDetail-text-font-size-17: $font-size-17,
            WidgetSymbolDetail-text-priceboard-green: $theme-dark-text-priceboard-green,
            WidgetSymbolDetail-text-priceboard-red: $theme-dark-text-priceboard-red,
            WidgetSymbolDetail-border-right:$black-rgba-83-83-83-015,
            WidgetSymbolDetail-text-normal: $white-rgb-100-100-100,
            // -- 5. Widget Widget Symbol detail === [End]
            // =====================================================================================================

            // =====================================================================================================

            // -- 6. Widget Symbol Info === [Start]
            //6.1 Widget Symbol Info detail Header

            //6.2 Widget Symbol Info detail Body
            WidgetSymbolInfo-star-color: $orange-rbg-255-172-66,
            WidgetSymbolInfo-symbol-active: $orange-rbg-255-172-66,
            WidgetSymbolInfo-border-watchlist-symbol: $black-rgba-83-83-83-015,
            WidgetSymbolInfo-background-watchlist-symbol: $white-rgb-230-230-230,
            WidgetSymbolInfo-hide-header-width-div-hover: 20px,

            WidgetSymbolInfo-popover-body-background: $white-rgb-218-218-218,
            WidgetSymbolInfo-popover-text-color: $white-rgb-100-100-100,
            WidgetSymbolInfo-popover-star-color: $orange-rbg-255-172-66,
            WidgetSymbolInfo-popover-text-active: $orange-rbg-217-122-8,
            WidgetSymbolInfo-popover-text-active-background: $white-rgb-207-207-207,
            WidgetSymbolInfo-popover-div-hover: $white-rgb-207-207-207,
            WidgetSymbolInfo-tabbar-border: $orange-rbg-255-172-66,
            WidgetSymbolInfo-text-priceboard-red: $theme-dark-text-priceboard-red,
            // -- 6. Widget Symbol Info  === [End]
            // =====================================================================================================

            // =====================================================================================================
            // -- 7. Widget Symbol Detail Popup === [Start]
            //7.1 Widget Symbol Detail Popupdetail Header
            WidgetSymbolDetailPopup-header-font-size: $font-size-14,
            WidgetSymbolDetailPopup-header-background: $white-rgb-207-207-207,
            WidgetSymbolDetailPopup-header-border: $white-rgb-207-207-207,
            WidgetSymbolDetailPopup-header-active-tab: $rgb-0-192-135,
            WidgetSymbolDetailPopup-header-icon-close: $font-size-18,
            WidgetSymbolDetailPopup-header-icon-hover-background: $black-rgb-100-100-100,

            //7.2 Widget Symbol Detail Popup detail Body
            WidgetSymbolDetailPopup-background: $white-rgb-230-230-230,
            WidgetSymbolDetailPopup-z-index: $z-index-layer-0,
            WidgetSymbolDetailPopup-border-container: $white-rgb-230-230-230,

            // -- 7. Widget Symbol Detail Popup  === [End]
            // =====================================================================================================

            // =====================================================================================================
            // -- 8. Widget Top Indexes === [Start]
            //8.1 Widget Top Indexes Body
            WidgetTopIndexes-color-text-normal: $white-rgb-100-100-100,
            WidgetTopIndexes-header-border: $black-rgba-83-83-83-015,
            WidgetTopIndexes-header-background: $white-rgb-218-218-218,
            WidgetTopIndexes-header-color: $white-rgb-207-207-207,
            WidgetTopIndexes-row-odd-background:$white-rgb-238-238-238,
            WidgetTopIndexes-row-normal-background: $white-rgb-240-240-240,
            WidgetTopIndexes-hover-row: $white-rgb-230-230-230,

            // -- 8. Widget Top Indexes === [End]
            // =====================================================================================================

            // =====================================================================================================
            // -- 9. Widget Symbol Detail (Overview, Trading, Finace,...)  === [Start]
            WidgetOverview-color-text-normal: $white-rgb-100-100-100,
            WidgetOverview-background: $white-rgb-230-230-230,
            WidgetOverview-info-symbol-background: $white-rgb-238-238-238,
            WidgetOverview-info-symbol-border: $white-rgb-204-204-204,

            WidgetTrading-color-text-normal: $white-rgb-100-100-100,
            WidgetTrading-header-background: $white-rgb-214-214-214,
            WidgetTrading-header-active-background: $white-rgb-204-204-204,
            WidgetTrading-events-border: $white-rgb-214-214-214,
            WidgetTrading-border-priceboard: $white-rgb-214-214-214,

            WidgetFinance-color-text-normal: $white-rgb-100-100-100,
            WidgetFinance-popover-parent-background: $white-rgb-238-238-238,
            WidgetFinance-popover-child-background: $white-rgb-214-214-214,
            WidgetFinance-button-active: $orange-rbg-217-122-8,
            WidgetFinance-container-background: $white-rgb-230-230-230,
            WidgetFinance-section-background: $white-rgb-238-238-238,
            WidgetFinance-header-section-background: $white-rgb-214-214-214,
            WidgetFinance-border-table: $white-rgb-214-214-214,
            WidgetFinance-color-header-table: $white-rgb-230-230-230,

            WidgetValuation-color-text-normal: $white-rgb-100-100-100,
            WidgetValuation-background-container: $white-rgb-238-238-238,
            WidgetValuation-background-child: $white-rgb-214-214-214,
            WidgetValuation-border-table: $white-rgb-190-190-190,
            WidgetValuation-color-text-green-table: $theme-light-text-priceboard-green,
            WidgetValuation-color-text-red-table: $theme-light-text-priceboard-red,
            WidgetValuation-color-header: $white-rgb-204-204-204,
            WidgetValuation-color-button-calc-pe: $green,
            WidgetValuation-color-text-input: $white-rgb-100-100-100,

            WidgetComparison-color-text-normal: $white-rgb-100-100-100,
            WidgetComparison-background-container: $white-rgb-238-238-238,
            WidgetComparison-color-text-green-table: $theme-light-text-priceboard-green,
            WidgetComparison-color-text-red-table: $theme-light-text-priceboard-red,
            WidgetComparison-border-table: $white-rgb-190-190-190,
            WidgetComparison-background-button-add-new: $green,
            WidgetComparison-color-header-table: $white-rgb-204-204-204,

            WidgetSignal-color-text-normal: $white-rgb-100-100-100,
            WidgetSignal-background-container: $white-rgb-238-238-238,
            WidgetSignal-background-recommend-content: $black-rgba-40-40-40-030,
            WidgetSignal-border-table: $white-rgb-190-190-190,
            WidgetSignal-background-button-view: $green,
            WidgetSignal-background-button-back-test: $black-rgb-46-46-46,
            WidgetSignal-background-header-table: $white-rgb-204-204-204,
            WidgetSignal-color-title-modal: $white-rgb-100-100-100,
            WidgetSignal-background-header-modal: $white-rgb-238-238-238,

            WidgetReport-color-text-normal: $white-rgb-100-100-100,
            WidgetReport-background-container: $white-rgb-238-238-238,
            WidgetReport-background-recommend-content: $black-rgba-40-40-40-030,
            WidgetReport-border-table: $white-rgb-190-190-190,
            WidgetReport-background-button-view: $green,
            WidgetReport-background-header-table: $white-rgb-204-204-204,

            // -- 9. Widget Symbol Detail (Overview, Trading, Finace,...) === [End]
            // =====================================================================================================

            // -- 10. Account Register === [Start]
            AccountRegister-required-asterisk: $red-rgb-232-31-31,
            //10.1 Account Register Header
            AccountRegister-header-title-color: $red-rgb-255-0-0,
            AccountRegister-header-title-size: $font-size-16,
            AccountRegister-header-title-font: $font-family,
            AccountRegister-header-stepname-color: $red-rgb-255-0-0,
            AccountRegister-header-stepname-size: $font-size-14,
            AccountRegister-header-stepname-font: $font-family,
            //10.2 Account Register Section
            AccountRegister-section-background: $white-rgb-238-238-238,
            AccountRegister-section-header-background: $white-rgb-190-190-190,
            AccountRegister-section-bottom-background: $white-rgb-190-190-190,
            AccountRegister-section-header-title-color: $red-rgb-255-0-0,
            AccountRegister-section-header-title-size: $font-size-16,
            AccountRegister-section-header-title-font: $font-family,
            //10.2.1 Account Register Section Body
            AccountRegister-sectionBody-background: $black-rgb-35-35-35,
            AccountRegister-sectionBody-input-background: $white-rgb-166-166-166,
            AccountRegister-sectionBody-input-height: $height-30,
            AccountRegister-sectionBody-input-hover: $orange-rbg-255-172-66,
            AccountRegister-sectionBody-infotitle-color: $black-rgb-35-35-35,
            AccountRegister-sectionBody-infotitle-size: $font-size-16,
            AccountRegister-sectionBody-infotitle-font: $font-family,
            AccountRegister-sectionBody-text-color: $black-rgb-100-100-100,
            AccountRegister-sectionBody-text-size: $font-size-13,
            AccountRegister-sectionBody-text-font: $font-family,
            //10.2.1.1 Account Register Section Body Step 1
            //10.2.1.2 Account Register Section Body Step 2
            //10.2.1.3 Account Register Section Body Step 3
            //10.2.1.4 Account Register Section Body Step 4
            //10.2.1.5 Account Register Section Body Step 5
            AccountRegister-sectionBody-s5-btn-bg: $black-rgb-18-18-18,
            AccountRegister-sectionBody-s5-btn-color: $white-rgb-251-251-251,
            AccountRegister-sectionBody-s5-btn-height: $height-30,
            //10.3 Account Register Bottom Note
            AccountRegister-bottomNote-background: $white-rgb-190-190-190,
            //10.4 Account Register Bottom
            AccountRegister-bottom-button-background: $black-rgb-35-35-35,
            AccountRegister-bottom-button-hover: $orange-rbg-255-172-66,

            // -- 10. Account Register === [End]
            // =====================================================================================================
            // -- 11. Create Account === [Start]
            // Common
            CreateAccount-section-title-bg: #121212,
            CreateAccount-section-title-color: $white-rgb-255-255-255,
            CreateAccount-section-asterisk-color: $rgb-255-55-55,
            CreateAccount-section-btn-color: $white-rgb-255-255-255,
            CreateAccount-step-bg-color: $black-rgb-37-37-37,
            // 11.0 Introduction Create Account
            AccountRegister-sectionBody-title-size: $font-size-28,
            AccountRegister-section-header-btnStart-color: $black-rgb-35-35-35,
            AccountRegister-sectionBody-btnStart-bg: $rgb-0-192-135,
            AccountRegister-sectionBody-btnStart-effect: #90EE90,
            AccountRegister-sectionBody-btnBack-bg: $black-rgb-49-49-49,
            // 11.1 Create Account Step 1
            CreateAccount-sectionBody-step1-color: $black-rgb-18-18-18,
            CreateAccount-sectionBody-step1-input-bg-color: $white-rgb-204-204-204,
            CreateAccount-section-step1-bg-tr-even-color: $white-rgb-231-231-231,
            CreateAccount-section-step1-bg-tr-odd-color: $white-rgb-242-242-242,
            CreateAccount-section-step1-table-border: $white-rgb-255-255-255,
            CreateAccount-section-step1-mapTitle-color: $white-rgb-255-255-255,
            CreateAccount-section-step1-map-content-color: $white-rgb-255-255-255,
            CreateAccount-section-step1-map-icon-color: $primary,
            CreateAccount-section-step1-map-icon-bg-color: $rgb-0-192-135-01,
            // 11.2 Create Account Step 2
            // 11.3 Create Account Step 3
            CreateAccount-section-step3-input-bg-color: $white-rgb-204-204-204,
            CreateAccount-section-step3-input-text-color: $black-rgb-18-18-18,
            CreateAccount-section-step3-input-effect-color: $primary,
            // 11.4 Create Account Step 4
            CreateAccount-section-step4-input-bg-color: $white-rgb-204-204-204,
            CreateAccount-sectionBody-step4-color: $black-rgb-18-18-18,
            // 11.5 Create Account Step 5
            CreateAccount-section-step5-para-color: $white-rgb-204-204-204,
            // 11.5 Create Account Step 5OTP
            AccountRegister-step5otp-bg-color: $black-rgb-37-37-37,
            AccountRegister-step5otp-btnCancel-color: $black-rgb-58-58-58,
            // 11.5 Create Account Step 5OTP1
            AccountRegister-step5otp1-label-color: $white-rgb-204-204-204,
            AccountRegister-step5otp1-input-bg-color: $white-rgb-204-204-204,
            AccountRegister-step5otp1-input-color: $black-rgb-18-18-18,
            AccountRegister-step5otp1-bottom-bg-color: $black-rgb-18-18-18,
            // 11.5 Create Account Step 5OTP2
            AccountRegister-step5otp2-effect-color: $primary,
            AccountRegister-step5otp2-bottom-bg-color: $black-rgb-18-18-18,
            AccountRegister-step5otp2-otp-content: $white-rgb-204-204-204,
            AccountRegister-step5otp2-otp-color: $white-rgb-204-204-204,
            // -- 11. Create Account === [End]


            // =====================================================================================================
            //50.MarketWatch - Widget Market === [Begin]
            MarketWatchTextCeilPrice: $purple-rgb-162-37-138,
            MarketWatchTextFloorPrice: $blue-rgb-30-151-190,
            MarketWatchTextRedPrice: $red-rgb-188-58-54,
            MarketWatchTextGreenPrice: $green-rgb-90-181-92,
            MarketWatchTextRefPrice: $yellow-rgb-252-218-80,

            MW-WidgetMarket-popover-parent-background: $white-rgb-238-238-238,
            MW-WidgetMarket-popover-child-background: $white-rgb-214-214-214,
            MW-WidgetMarket-popover-active-button-color: $orange-rbg-217-122-8,
            MW-WidgetMarket-color-text-normal: $white-rgb-100-100-100,

            //50.MarketWatch - Widget Market === [End]
            // =====================================================================================================

            //51.Trade - SymbolSearch === [Begin]
            Trade-SymboSelector-input-background: $white-rgb-251-251-251,
            Trade-SymboSelector-input-text-color: $black-rgb-0-0-0,
            Trade-SymboSelector-icon-color: $black-rgb-0-0-0,
            //51.MarketWatch - Widget Market === [End]
            // =====================================================================================================

            //52.Trade - OrderInputContainer === [Begin]
            Trade-OrderInputContainer-checkbox-label-background: $white-rgb-204-204-204,
            Trade-OrderInputContainer-checkbox-label-checked-background: $rgb-0-192-135,
            Trade-OrderInputContainer-checkbox-disable-label-text-color:$white-rgb-153-153-153,
            Trade-OrderInputContainer-checkbox-span-color: $black-rgb-0-0-0,
            Trade-OrderInputContainer-capacity-container-hr: $black-rgb-68-68-68,
            Trade-OrderInputContainer-btn-sell: $rgb-255-55-55,
            Trade-OrderInputContainer-btn-sell-hover: $rgb-255-105-105,
            Trade-OrderInputContainer-btn-buy: $rgb-0-192-135,
            Trade-OrderInputContainer-btn-buy-hover: $rgb-30-222-165,
            //52.Trade - OrderInputContainer === [End]
            // =====================================================================================================

            //53.Trade - OrderActionSelector === [Begin]
            Trade-OrderActionSelector-sell-tab-item-background: $rgb-255-55-55,
            Trade-OrderActionSelector-buy-tab-item-background: $rgb-0-192-135,
            //53.Trade - OrderActionSelector === [End]
            // =====================================================================================================

            //54.SideMenu === [Begin]
            SideMenu-menu-list-menu-link-color-hover: $rgb-0-192-135,
            //54.SideMenu === [End]
            // =====================================================================================================

            //55.Account Balance=== [Begin]
            AccountBalance-custom-div-redirect-color-hover: $rgb-0-192-135,
            AccountBalance-custom-div-redirect-label-color-hover: $rgb-0-192-135,
            //55.SideMenu === [End]

            // =====================================================================================================
            //70.NotRight - Widget NotRight === [Begin]
            NotRight-width: 100%,
            NotRight-height: 30px,
            NotRight-font-family: $font-family,
            NotRight-font-size: $font-size-13,
            NotRight-text-color: $black-rgb-68-68-68,
            NotRight-a-font-size: $font-size-14,
            NotRight-a-hover-font-size: $font-size-15,
            NotRight-a-font-weight: 700,
            NotRight-a-color: $orange-rbg-217-122-8,
            NotRight-a-color-active: $orange-rbg-255-172-66,
            NotRight-a-color-hover: $orange-rbg-255-172-66,


            //70.NotRight - Widget NotRight === [End]

            //100.B.PageHeader === [Begin]
            //--100.1.PageHeader basic
            PageFooter-width: 100%,
            PageFooter-height: 40px,
            PageFooter-font-family: $font-family,
            PageFooter-font-size: $font-size-14,
            PageFooter-z-index: $z-index-layer-1,
            PageFooter-text-color: $black-rgb-68-68-68,
            PageFooter-background: $white-rgb-207-207-207,
            PageFooter-shadow: $white-rgb-207-207-207,
            PageFooter-menu-not-right-text-color: $white-rgb-166-166-166,
            //--100.E.WidgetBase === [End]

            //--102 Filter Algo ===  [Begin]
            Filter-color-text-input-placeholder: $black-rgb-68-68-68,
            Filter-header-name-input-color: $black-rgb-68-68-68,
            Filter-input-search-color: $black-rgb-68-68-68,
            Filter-dropdown-color: $black-rgb-68-68-68,
            Filter-input-select-color: $black-rgb-68-68-68,
            Filter-header-button-color: $black-rgb-68-68-68,
            Filter-filter-color: $black-rgb-68-68-68,

            Filter-input-search-background: $white-rgb-207-207-207,
            Filter-dropdown-background:$white-rgb-207-207-207,
            Filter-input-select-background: $white-rgb-207-207-207,
            Filter-filters-background-color: $white-rgb-207-207-207,
            Filter-filter-content-background: $white-rgb-207-207-207,
            Filter-filters-border-color: $white-rgb-207-207-207,
            Filter-filter-content-border-color:$white-rgb-207-207-207,

            Filter-filter-content-icon-color: $rgb-0-192-135,
            Filter-header-button-background: $rgb-0-192-135,
            Filter-header-name-input-border-bottom: $rgb-0-192-135,

            Filter-filters-active-background-color: $white-rgb-229-229-229,
            Filter-filter-hover-backgroud-color: $white-rgb-229-229-229,

            Filter-filter-item-remove-color: $rgb-255-55-55,

            Filter-filter-range-input-color: $black-rgb-35-35-35,

            //--102 Filter Algo ===  [End]

            //--200.Custom Table New
            CustomTableNew-th-background: $white-rgb-193-193-193,
            CustomTableNew-th-input-background: $white-rgb-204-204-204,
            CustomTableNew-td-background: $black-rgb-18-18-18,
            CustomTableNew-tfoot-color: $rgb-0-192-135,
            CustomTableNew-tfoot-input-color: $black-rgb-0-0-0,
            CustomTableNew-paging-hover-backgrond: $rgb-30-222-165,
            CustomTableNew-paging-color: $white-rgb-255-255-255,
            CustomTableNew-enter-item-border-color: $rgb-0-192-135,
            CustomTableNew-tr-background-hover: $white-rgb-218-218-218,
            //--200.Custom Table New === [End]

            //--201.LayoutSettingTable
            LayoutSettingTable-drag-row-item-color: $white-rgb-255-255-255,
            LayoutSettingTable-drag-row-item-selector: $rgb-0-192-135,
            LayoutSettingTable-drag-row-item-background: $rgb-66-133-244,
            LayoutSettingTable-drag-row-item-border: $white-rgb-240-240-240,
            LayoutSettingTable-ant-card-h2-color: $rgb-0-192-135,
            LayoutSettingTable-ant-card-background: $white-rgb-255-255-255,
            LayoutSettingTable-ant-table-color: $black-rgb-20-20-20,
            LayoutSettingTable-ant-table-th-background: $white-rgb-193-193-193,
            LayoutSettingTable-ant-table-td-background: $white-rgb-240-240-240,
            LayoutSettingTable-ant-table-border-color: $black-rgb-68-68-68,
            LayoutSettingTable-modal-footer-button-background: $rgb-0-192-135,
            //--201.LayoutSettingTable === [End]

            // =====================================================================================================

            //--500.Customize dropdown component === [Begin]
            Dropdown-button-background: $white-rgb-214-214-214,
            Dropdown-body-parent-background: $white-rgb-238-238-238,
            Dropdown-body-child-background: $white-rgb-214-214-214,
            Dropdown-child-active: $orange-rbg-217-122-8,
            Dropdown-text-normal: $white-rgb-100-100-100,
            //--500.Customize dropdown component === [End]

            //--600.Quick Order component === [Begin]
            Quickorder-background: $white-rgb-207-207-207,
            Quickorder-buy-button-background: $rgb-0-192-135,
            Quickorder-buy-button-border-color:$rgb-17-40-33,
            Quickorder-sell-button-background: $rgb-255-55-55,
            Quickorder-sell-button-border-color:$rgb-52-20-29,
            Quickorder-notifi-error-background: $rgb-0-192-135,
            Quickorder-notifi-error-color:$white-rgb-255-255-255,
            //--600.Quick Order component === [End]


            common-red: $rgb-255-55-55,
            common-white: $theme-light-white,
            common-gray: $theme-light-gray,
            common-green: $theme-light-green,
            common-orange: $theme-light-orange,
            common-blue: $theme-light-blue,
            common-purple: $theme-light-purple,
            common-black: $theme-light-white,
            common-dark-light: #f5f5f5,
            // PRICEBOARD
            table-header-color: $theme-light-table-header-color,
            table-header-text-color: $theme-light-table-header-text-color,
            table-border: $theme-light-table-border,
            text-floor-price: $theme-light-text-floor-price,
            text-ceil-price: $theme-light-text-ceil-price,
            text-ref-price: $theme-light-text-ref-price,
            text-priceboard-green: $theme-light-text-priceboard-green,
            text-priceboard-red: $theme-light-text-priceboard-red,
            cell-group-item:$theme-light-cell-group-item,
            cell-group-item-lighter:$theme-light-cell-group-item-lighter,
            // BODY
            label-text: $theme-dark-black,
            body-bg: #ebeff2,
            carbon-bg: #eeeeee,
            scrollbar-track-bg: #eee,
            scrollbar-thumb-bg: rgba(0, 0, 0, 0.4),
            scrollbar-thumb-hover-bg: rgba(0, 0, 0, 0.7),
            // HEADER
            header-bg: $fss-blue,
            header-bg-hover: url("../../assets/images/header-active-bg-pattern.jpg") top repeat-x,
            header-shadow: 'none',
            header-border: $fss-blue-darkest,
            header-text-color: $theme-light-white,
            header-active-text-color: $rgb-0-192-135,
            header-dropdown-menu-bg: #efefef,
            header-dropdown-menu-bg-hover: #dddddd,
            header-dropdown-text-color: #000000,
            header-dropdown-active-text-color: rgba($fss-blue-darker, 0.95),
            header-dropdown-menu-border: #0000004d,
            header-combobox-border: $fss-blue-darkest,
            header-combobox-bg: $fss-blue-darker,
            header-notification: $theme-light-white,
            // SECTION
            section-bg: rgba($theme-light-white, 0.8),
            section-bg-strong: $theme-light-white,
            section-header-bg: #e2e2e2,
            section-header-hover-bg: #dddddd,
            section-header-text-color: #424242,
            section-header-active-text-color: #424242,
            section-header-text-gray: lighten(#424242, 30),
            section-header-border-top: $fss-orange,
            section-body-dark-1: $theme-light-white,
            section-body-dark-2: #f5f5f5,
            section-body-dark-3: #c3d6ec,
            section-body-border: #cccccc,
            section-body-border-2: $theme-light-border-2,
            section-body-border-highlight: $fss-blue,
            section-body-text-normal: #000,
            section-body-text-gray: $theme-light-gray,
            section-body-text-acitve: $fss-blue, //light: blue, dark orange
            section-body-gray-bg    : lighten($theme-light-gray, 30),
            // FOOTER BACKGROUND
            footer-bg: #e2e2e2,
            // WIDGET SIDEBAR
            widget-sidebar-bg: $fss-blue,
            widget-sidebar-bg-hover: $fss-blue-darker,
            widget-sidebar-border: $fss-blue-darkest,
            widget-sidebar-text-color: $theme-light-white,
            widget-sidebar-active-text-color: $fss-orange,
            widget-sidebar-hider-border: $fss-blue-darker,
            widget-sidebar-hider-bg: $fss-blue,
            widget-floating-item-border: $theme-light-orange,
            widget-floating-item-bg:$theme-light-white,
            widget-box-shadow: #0000004d,
            react-table-input-filter: $white,
            // ACCOUNT + SETTINGS
            left-sidebar-bg: rgba($theme-light-white, 0.8),
            left-sidebar-text-level-1: $theme-light-black,
            left-sidebar-text-level-2: $theme-light-black,
            left-sidebar-text-level-2-active: $fss-blue,
            basic-table-header-bg: #e2e2e2,
            basic-table-border: $white-rgb-193-193-193,
            basic-table-text: $black-rgb-38-38-38,
            basic-table-header-text: $theme-light-white,
            basic-table-header-border: #ebeff2,
            basic-table-body-bg: $theme-light-white,
            basic-table-row-odd-bg: #f1f1f1,
            basic-table-footer-bg: #e2e2e2, //#D7E4F3
            basic-table-body-text: $theme-dark-black,
            ultility-finish-icon: $fss-blue,
            // FORM
            form-control-label: #262626,
            form-control-text-color: #000,
            form-control-bg: $theme-light-white,
            form-control-readonly-text-color: #000,
            //form-control-readonly-bg: lighten($theme-light-gray, 30),
            form-control-readonly-bg: #f0f0f0,
            form-control-select-bg-img: url("../assets/images/dropdown-light.svg"),
            form-control-select-suggest-bg-img: url("../assets/images/down-light-arrow.svg"),
            form-control-focus-color: $rgb-30-222-165,
            wizard-icon-active: $rgb-0-192-135,
            wizard-icon-bg: #d0ddec,
            wizard-icon-active-color: $theme-light-white,
            form-control-border-focus:$rgb-0-192-135,
            // TOAST
            toast-success-bg: $fss-blue,
            toast-error-bg: $theme-light-red,
            toast-warn-bg: $theme-light-orange,
            toast-info-bg: $theme-light-blue,
            toast-shadow-color: $gray,
            toast-close-color: $theme-light-white,
            toast-text-color: $theme-light-white,
            // MODAL
            modal-header-bg: $fss-blue,
            modal-header-hover-bg: $fss-blue-darker,
            modal-header-icon-color: $theme-light-white,
            modal-header-text-color: $theme-light-white,
            modal-body-bg: $theme-light-white,
            modal-body-text-normal: #000,
            modal-body-text-total: $fss-blue,
            modal-footer-bg: $theme-light-white,
            modal-warning-bg: $theme-light-red,
            modal-tpsl-item-bg: $white-rgb-238-238-238,
            // DATE PICKER
            date-picker-bg: $theme-light-white,
            date-picker-border: darken(#3f4458, 50%),
            date-picker-arrow-hover: $theme-light-orange,
            date-picker-month-fg: #000,
            date-picker-month-bg: $theme-light-white,
            date-picker-weekdays-fg: #000,
            date-picker-weekdays-bg: transparent,
            date-picker-day-fg: #000,
            date-picker-day-hover-bg: darken($theme-light-white, 25%),
            date-picker-today-border: $theme-light-orange,
            date-picker-today-bg: $theme-light-orange,
            date-picker-today-fg: #000,
            date-picker-day-selected-fg: $theme-light-white,
            date-picker-day-selected-bg: $theme-light-green,
            //NOTIFICATION
            notification-bg: #efefef,
            notification-inner: #f5f5f5,
            notification-date: $gray,
            notification-title: $fss-blue,
            notification-inactive:#666666,
            notification-active:#000000,
            // BUTTON
            button-bg: $fss-blue,
            btn-buy-bg: $fss-blue,
            btn-cancel-color: #00000080,
            btn-cancel-bg: #dbdbdb,
            // INPUT
            input-active-bg: $fss-blue,
            input-disable-bg: lighten($fss-blue, 20%),
            background-order-input: $theme-light-white,
            text-color-order-input: #000,
            text-note-color-order-input: $theme-light-gray,
            range-input-background-mark: #cccccc,
            range-input-background-mark-light: #848E9C,
            range-input-border-mark: #e6e6e6,
            range-input-background-thumb: #e6e6e6,
            range-input-border-thumb: #848E9C,
            // separator
            separate-line: #dddddd));