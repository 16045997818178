@import '../../styles/variables';
@import '../../styles/mixin';

.register-step2 {

    .row_container {
        padding   : 50px 6.25vw 250px;
        margin    : 0 18.75vw;
        background: #E5E5E5;

        @media (max-width: 1919px) {
            padding: 50px 4.25vw 250px;
            margin : 0 15.75vw;
        }

        @media (max-width: 1439px) {
            margin: 0 10vw;
        }

        @media (max-width: 991px) {
            margin: 0;
        }

        @media (max-width: 767px) {
            margin : 0;
            padding: 15px;
        }

        .title {
            font-family: $body-font;
            font-style : normal;
            font-weight: 325;
            font-size  : 13px;

            .note {
                color      : #27388E;
                font-weight: 600;
            }
        }

        .input-files {
            width         : 100%;
            display       : flex;
            flex-direction: column;

            .input-child {
                width: 100%;
            }

            @media (min-width: 576px) {
                width         : 100%;
                display       : flex;
                flex-direction: row;
            }
        }

        .upload-files {
            padding-top: 40px;
            width      : 100%;

            label {
                color           : black;
                height          : fit-content;
                background      : #c4c4c4;
                padding         : 10px 30px;
                border-radius   : 5px;
                // margin-right : 35px;
                cursor          : pointer;
            }
        }


        .preview-mobile {
            display: block;

            .img-pre {
                width              : 100%;
                height             : 200px;
                background-size    : contain;
                background-repeat  : no-repeat;
                background-position: center center;
            }

            @media (min-width: 576px) {
                display: none;
            }
        }

        .preview {
            width  : 100%;
            display: flex;

            .img-pre {
                width              : 100%;
                height             : 300px;
                background-size    : contain;
                background-repeat  : no-repeat;
                background-position: center center;
            }

            @media (max-width: 576px) {
                display: none;
            }

            .img-front {
                background-color: #DFDFDF;
            }

            .img-back {
                background-color: #DFDFDF;
            }

            .img-name {
                height: 20px;
            }


        }


        .err-message {
            display   : flex;
            width     : 100%;
            text-align: center;

            @media (max-width: 576px) {
                flex-direction: column;
                text-align    : left;
            }

            .lbl_error {
                width: 100%;

                @media (min-width: 576px) {
                    width: 50% !important;
                }
            }
        }

    }

    .btn-back {
        &:hover {
            opacity: 0.9;
        }
    }

    .lbl_error {
        color: red;
    }

}