@import '../../styles/variables';
@import '../../styles/mixin';

.register-step1-confirm {
    position: relative;
    overflow: hidden;

    .row_container {
        padding   : 38px 5% 100px;
        margin    : 0 auto;
        max-width : 1192px;
        background: #E5E5E5;

        @media screen and (max-width: 1919px) {
            margin: 0 14%;
        }

        @media (max-width: 1439px) {
            margin: 0 10%;
        }

        @media screen and (max-width: 1279px) {
            margin: 0 6%;
        }

        @media (max-width: 991px) {
            margin: 0;
        }

        .box-tieude {
            text-align    : center;
            padding-bottom: 50px;

            .title-row {
                font-family   : $heading-font;
                font-style    : normal;
                font-weight   : bold;
                font-size     : 30px;
                line-height   : 1.11;
                text-align    : center;
                text-transform: uppercase;
                color         : #283991;
                padding-bottom: 18px;
                position      : relative;

                &:after {
                    content   : '';
                    position  : absolute;
                    bottom    : 0;
                    left      : 10%;
                    width     : 80%;
                    height    : 1px;
                    background: linear-gradient(90deg, rgba(193, 193, 193, 0) 0.06%, #C1C1C1 53.4%, rgba(193, 193, 193, 0) 98.54%);
                    z-index   : 5;
                }
            }
        }

        .tien-trinh-dang-ky {
            margin-bottom: 80px;
        }

        .title {
            font-family   : $body-font2;
            font-style    : normal;
            font-weight   : 450;
            font-size     : 17px;
            line-height   : 20px;
            color         : #FF7801;
            text-align    : center;
            padding-bottom: 30px;
        }

        .notification {
            background   : #FFFFFF;
            border-radius: 8px;
            padding      : 22px 8% 37px;
            position     : relative;

            @media (max-width: 1439px) and(min-width: 1280) {
                margin: 0 18%;
            }

            @media (max-width: 1279px) and (min-width: 768px) {
                margin: 0 15%;
            }

            @media (max-width: 767px) {
                margin: 0;
            }

            .button-close {
                position: absolute;
                top     : -11.5px;
                right   : -11.5px;
            }
        }

        .box-info {
            .row-info {
                border-bottom  : 0.5px solid #C4C4C4;
                margin-bottom  : 25px;
                padding-bottom : 5px;
                font-family    : $body-font;
                font-style     : normal;
                font-weight    : bold;
                font-size      : 14px;
                line-height    : 20px;
                color          : #212121;
                display        : flex;
                justify-content: space-between;
                width          : 100%;

                .title-info {
                    display  : inline-block;
                    max-width: 45%;
                }

                .value-info {
                    max-width : 45%;
                    word-break: break-all;
                }
            }
        }

        .box-click {
            position    : relative;
            padding-top : 20px;
            margin-right: -5px;
            text-align  : right;

            @media (max-width: 767px) {
                padding-top: 0;
            }

            .button-submit {
                text-align   : center;
                border-radius: 50px;
                font-family  : $heading-font;
                font-style   : normal;
                font-weight  : 400;
                line-height  : 1.11;
                padding      : 10px 30px 14px;
                font-family  : $heading-font;
                font-style   : normal;
                font-weight  : bold;
                font-size    : 14px;
                background   : #C4C4C4;
                color        : #212121;
                margin       : 0 5px;
                outline      : none;
                border       : none;

                @media (max-width: 767px) {
                    width        : 100%;
                    margin-bottom: 20px;
                }

                &:hover {
                    color     : #FFFFFF;
                    background: linear-gradient(89.34deg, #FF4913 1.12%, #FF5C00 95.42%);
                }
            }

            .button-submit.active {
                color     : #FFFFFF;
                background: linear-gradient(89.34deg, #FF4913 1.12%, #FF5C00 95.42%);
            }
        }
    }
}