@import "../styles/common";

.not-right-modal {
  @include media-breakpoint-up(sm) {
    &.modal-dialog {
      margin   : auto;
      max-width: 50vw !important;
    }
  }

  @include themify($themes) {
    .header {
      background: themed('modal-header-bg');

      .title {
        color: themed('modal-header-text-color');
      }

      button {
        color: themed('modal-header-icon-color');

        &:hover {
          background: themed('modal-header-hover-bg');
        }
      }
    }

    .body {
      background: themed('modal-body-bg');
      color     : themed('modal-body-text-normal');
      height    : 150px;
    }
  }

  .header {
    height: 30px;

    .title {
      font-size  : $font-size-medium;
      line-height: 30px;
      padding    : 0 10px;
    }

    button {
      background : transparent;
      border     : none;
      font-size  : $font-size-medium;
      height     : 30px;
      line-height: 30px;
      padding    : 0 10px;
      text-align : center;
      width      : auto;

      i {
        line-height: 30px;
      }

      .fa-times {
        font-size: $font-size-big + 2px;
      }
    }
  }

  .body {
    padding: 5px 10px;
  }

  .footer {
    @include themify($themes) {
      @keyframes blinkingText {
        0% {
          color: themed('modal-header-text-color')
        }

        49% {
          color: themed('modal-header-text-color')
        }

        60% {
          color: darken(themed('modal-header-text-color'), 50%)
        }

        99% {
          color: darken(themed('modal-header-text-color'), 50%)
        }

        100% {
          color: themed('modal-header-text-color')
        }
      }

      animation  :blinkingText 1.2s infinite;
      background : themed('modal-warning-bg');
      color      : themed('modal-header-text-color');
      font-weight: bold;
      margin-top : 5px;
      padding    : 5px;
      text-align : center;
    }
  }
}