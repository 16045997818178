@import '../../styles/variables';
@import '../../styles/mixin';

.register-step32 {
    .row_container {
        padding   : 50px 6.25vw 250px;
        margin    : 0 18.75vw;
        background: #E5E5E5;

        @media (max-width: 1549px) {
            margin: 0 15.75vw;
        }

        @media (max-width: 1439px) {
            margin: 0 12.75vw;
        }

        @media (max-width: 1279px) {
            margin : 0 7.75vw;
            padding: 50px 4.25vw 250px;
        }

        @media (max-width: 991px) {
            margin : 0;
            padding: 50px 4.25vw 250px;
        }

        @media (max-width: 767px) {
            margin: 0;
        }

        .box-tieude {
            text-align: center;

            .title-row {
                font-family   : $heading-font;
                font-style    : normal;
                font-weight   : bold;
                font-size     : 30px;
                line-height   : 1.11;
                text-align    : center;
                text-transform: uppercase;
                color         : #283991;
                padding-bottom: 35px;
                position      : relative;

                @media (max-width: 767px) {
                    font-size: 28px;
                }

                @media (max-width: 413px) {
                    font-size: 25px;
                }

                &:after {
                    content   : '';
                    position  : absolute;
                    bottom    : 0;
                    left      : 0;
                    width     : 100%;
                    height    : 1px;
                    background: linear-gradient(90deg, rgba(193, 193, 193, 0) 0.06%, #C1C1C1 53.4%, rgba(193, 193, 193, 0) 98.54%);
                    z-index   : 5;
                }
            }
        }

        .tien-trinh-dang-ky {
            padding: 30px 0 11vw 0;
        }

        .tieu-de-box-1 {
            font-family            : SVN-Avo;
            font-style             : normal;
            font-weight            : bold;
            font-size              : 20px;
            line-height            : 1.11;
            /* or 22px */
            text-transform         : uppercase;
            background             : -webkit-linear-gradient(89.3deg, #283991 13.41%, #3298D1 118%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            border-bottom          : 1px solid #C4C4C4;
            padding-bottom         : 12px;

            @media (max-width: 767px) {
                font-size: 18px;
            }

            @media (max-width: 413px) {
                font-size: 18px;
            }
        }

        .wrapper-content {
            padding-right: 11.5vw;
            padding-top  : 30px;

            @media (max-width: 767px) {
                padding-right: 0;
            }

            .row {
                margin         : 0;
                justify-content: space-between;

            }

            .wrapper-row {
                margin-bottom: 15px;

                input[type="radio"] {
                    cursor: pointer;
                }

                .col1 {
                    width  : 7.25vw;
                    padding: 0;

                    @media (max-width: 1279px) {
                        width: 15vw;
                    }

                    @media (max-width: 767px) {
                        width: 35vw;
                    }
                }

                .col2 {
                    width  : 27.5vw;
                    padding: 0;

                    @media (max-width: 1279px) {
                        width: 45vw;
                    }

                    @media (max-width: 767px) {
                        width: 55vw;
                    }
                }

                .input-1 {
                    width        : 100%;
                    border       : 1px solid #C4C4C4;
                    box-sizing   : border-box;
                    border-radius: 5px;
                    outline      : none;
                    font-family  : $body-font;
                    font-style   : normal;
                    font-weight  : bold;
                    font-size    : 14px;
                    line-height  : 20px;
                    /* or 143% */
                    text-align   : right;
                    color        : #212121;
                    height       : 30px;
                    padding      : 0px 10px 0px 10px;
                    background   : #E5E5E5;

                    @media (max-width: 767px) {
                        padding: 0px 5px 0px 5px;
                    }

                    @media (max-width: 320px) {
                        padding: 0px 5px 0px 5px;
                    }
                }

                textarea {
                    resize: none;
                }

                .o-label {
                    font-family: $body-font;
                    font-style : normal;
                    font-weight: bold;
                    font-size  : 14px;
                    line-height: 20px;
                    /* or 143% */
                    color      : #212121;

                    &.sex {
                        cursor: pointer;
                    }
                }
            }
        }

        .noti {
            margin-top: 0.5vw !important;
        }


        .txt-noti-2 {
            font-style: italic;
        }

        .btn-mobile {
            display: none;

            @media (max-width: 767px) {
                display: block;
            }

            @media (max-width: 320px) {
                display: block;
            }

            .button-xac-nhan {
                text-align      : right;
                padding-right   : 11.5vw;
                // margin-top   : 10.5vw;
                margin-top      : 3.5vw;

                @media (max-width: 767px) {
                    padding-right: 0;
                    text-align   : center;

                    button {
                        width  : 100% !important;
                        padding: 0;
                    }


                    button:nth-child(2) {
                        margin-top: 20px !important;
                    }
                }



                button {
                    outline    : none;
                    border     : none;
                    font-family: SVN-Avo;
                    font-style : normal;
                    font-weight: bold;
                    font-size  : 14px;
                    line-height: 1.11;
                    text-align : center;
                    color      : #FFFFFF;
                    min-height : 38px;
                    min-width  : 190px;

                    &.confirm {
                        background: linear-gradient(89.34deg, #FF4913 1.12%, #FF5C00 95.42%);
                    }

                    &.delete {
                        background: grey;
                    }

                    &:hover {
                        opacity: 0.9;
                    }

                    border-radius   : 50px;
                    padding         : 12px 50px;
                    // margin       : 0 10px;

                    // @media (max-width: 767px) {
                    //     padding: 12px 70px;
                    // }
                }

                .txt-noti {
                    line-height: 30px;
                }

                .refresh {
                    font-family  : SVN-Avo;
                    font-style   : normal;
                    font-weight  : bold;
                    font-size    : 14px;
                    line-height  : 1.11;
                    text-align   : center;
                    color        : #FFFFFF;
                    min-height   : 38px;
                    min-width    : 190px;
                    background   : linear-gradient(89.34deg, #FF4913 1.12%, #FF5C00 95.42%);
                    border-radius: 50px;
                    padding      : 12px 112px;
                    margin       : 0 10px;

                    @media (max-width: 767px) {
                        padding: 12px 95px;
                    }
                }
            }
        }

        .btn-desktop {
            display: block;

            @media (max-width: 767px) {
                display: none;
            }

            @media (max-width: 320px) {
                display: none;
            }

            .button-xac-nhan {
                text-align      : right;
                padding-right   : 11.5vw;
                // margin-top   : 10.5vw;
                margin-top      : 3.5vw;
                display         : flex;

                @media (max-width: 767px) {
                    padding-right: 0;
                    text-align   : center;

                    button {
                        width  : 100% !important;
                        padding: 0;
                    }


                    button:nth-child(2) {
                        margin-top: 20px !important;
                    }
                }

                button {
                    outline    : none;
                    border     : none;
                    font-family: SVN-Avo;
                    font-style : normal;
                    font-weight: bold;
                    font-size  : 14px;
                    line-height: 1.11;
                    text-align : center;
                    color      : #FFFFFF;
                    min-height : 38px;
                    min-width  : 190px;

                    &.confirm {
                        background: linear-gradient(89.34deg, #FF4913 1.12%, #FF5C00 95.42%);
                    }

                    &.delete {
                        background: grey;
                    }

                    &:hover {
                        opacity: 0.9;
                    }

                    border-radius: 50px;
                    padding      : 12px 50px;
                    margin       : 0 10px;

                    // @media (max-width: 767px) {
                    //     padding: 12px 70px;
                    // }
                }

                .refresh {
                    font-family  : SVN-Avo;
                    font-style   : normal;
                    font-weight  : bold;
                    font-size    : 14px;
                    line-height  : 1.11;
                    text-align   : center;
                    color        : #FFFFFF;
                    min-height   : 38px;
                    min-width    : 190px;
                    background   : linear-gradient(89.34deg, #FF4913 1.12%, #FF5C00 95.42%);
                    border-radius: 50px;
                    padding      : 12px 112px;
                    margin       : 0 10px;

                    @media (max-width: 767px) {
                        padding: 12px 95px;
                    }
                }
            }
        }

        .box-1 {
            margin-bottom: 50px;
        }

        .input-2 {
            font-family  : $body-font;
            font-style   : normal;
            font-weight  : bold;
            font-size    : 14px;
            line-height  : 20px;
            /* or 143% */
            text-align   : right;
            color        : #212121;
            padding-right: 15px;
        }

        .txt-wrap {
            word-wrap: break-word;
        }
    }

    .box-luu-y {
        font-family   : $body-font2;
        padding-top   : 30px;
        font-family   : $body-font;
        font-style    : italic;
        font-weight   : 300;
        font-size     : 11px;
        line-height   : 20px;
        color         : #000000;
        // display    : flex;

        p {
            font-weight        : 700;
            display            : inline-block;
            margin-bottom      : 0;
            // color           : #283991;
            padding-right      : 10px;
            // width        : 100px;
            text-align         : center;
        }
    }

    .lbl_error {
        width     : 100%;
        color     : red;
        padding   : 5px;
        text-align: center;
    }
}