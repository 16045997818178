@import '../../styles/variables';
@import '../../styles/mixin';

.register-step4 {

    .row_container {
        padding   : 50px 6.25vw 250px;
        margin    : 0 18.75vw;
        background: #E5E5E5;

        .input-radio {

            input[type="radio"],
            label {
                cursor: pointer;
            }
        }

        @media (max-width: 1919px) {
            padding: 50px 4.25vw 250px;
            margin : 0 15.75vw;
        }

        @media (max-width: 1439px) {
            margin: 0 10vw;
        }

        @media (max-width: 991px) {
            margin: 0;
        }

        @media (max-width: 767px) {
            margin: 0;
        }

        .box-tieude {
            text-align: center;

            .title-row {
                font-family   : $heading-font;
                font-style    : normal;
                font-weight   : bold;
                font-size     : 30px;
                line-height   : 1.11;
                text-align    : center;
                text-transform: uppercase;
                color         : #283991;
                padding-bottom: 35px;
                position      : relative;

                @media (max-width: 767px) {
                    font-size: 28px;
                }

                @media (max-width: 413px) {
                    font-size: 25px;
                }

                &:after {
                    content   : '';
                    position  : absolute;
                    bottom    : 0;
                    left      : 0;
                    width     : 100%;
                    height    : 1px;
                    background: linear-gradient(90deg, rgba(193, 193, 193, 0) 0.06%, #C1C1C1 53.4%, rgba(193, 193, 193, 0) 98.54%);
                    z-index   : 5;
                }
            }
        }

        .tien-trinh-dang-ky {
            padding: 30px 0 7vw 0;
        }

        .tieu-de-box-1 {
            font-family            : SVN-Avo;
            font-style             : normal;
            font-weight            : bold;
            font-size              : 20px;
            line-height            : 1.11;
            /* or 22px */
            text-transform         : uppercase;
            background             : -webkit-linear-gradient(89.3deg, #283991 13.41%, #3298D1 118%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            border-bottom          : 1px solid #C4C4C4;
            padding-bottom         : 12px;
            padding-top            : 5px;

            @media (max-width: 767px) {
                font-size: 18px;
            }

            @media (max-width: 413px) {
                font-size: 18px;
            }
        }

        .wrapper-content {
            margin-right: 6.25vw;

            @media (max-width: 767px) {
                padding-right: 0;
            }

            .row {
                margin         : 0;
                justify-content: space-between;

            }

            .wrapper-row {
                margin-bottom: 15px;

                .col-auto {
                    @media (max-width: 567px) {
                        min-width: 80px;
                    }


                    @media (min-width: 568px) {
                        min-width: 120px;
                    }
                }

                .col1 {
                    width  : 10.75vw;
                    padding: 0;

                    @media (max-width: 1279px) {
                        width: 15vw;
                    }

                    @media (max-width: 767px) {
                        width: 30vw;
                    }
                }

                .col2 {
                    width  : 26vw;
                    padding: 0;

                    @media (max-width: 1919px) {
                        width: 29vw;
                    }

                    @media (max-width: 1279px) {
                        width: 45vw;
                    }

                    @media (max-width: 767px) {
                        width: 55vw;
                    }
                }

                .input-1 {
                    width        : 100%;
                    border       : 1px solid #C4C4C4;
                    box-sizing   : border-box;
                    border-radius: 5px;
                    outline      : none;
                    font-family  : $body-font;
                    font-style   : normal;
                    font-weight  : bold;
                    font-size    : 14px;
                    line-height  : 20px;
                    /* or 143% */
                    text-align   : left;
                    color        : #212121;
                    height       : 30px;
                    padding-left : 15px;
                    background   : #E5E5E5;

                    &.error {
                        border: 1px solid red;
                    }
                }

                .text-err {
                    color     : red;
                    font-style: italic;
                }

                .o-label {
                    font-family            : $body-font;
                    font-style             : normal;
                    font-weight            : bold;
                    font-size              : 14px;
                    line-height            : 20px;
                    /* or 143% */
                    background             : -webkit-linear-gradient(89.3deg, #283991 13.41%, #3298D1 118%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                .o-label-1 {
                    font-family: $body-font;
                    font-style : normal;
                    font-weight: bold;
                    font-size  : 14px;
                    line-height: 1.43;
                    /* or 143% */
                    text-align : right;
                    /* gray 1 */
                    color      : #9F9F9F;
                }
            }

            .wrapper-row-1 {
                padding-bottom: 0px;
                border-bottom : 1px solid #000000;
            }
        }

        .button-xac-nhan {
            text-align   : right;
            padding-right: 6.5vw;
            margin-top   : 3.5vw;

            @media (max-width: 767px) {
                padding-right: 0;
                text-align   : center;
                margin-top   : 30px;
            }

            button {
                font-family  : SVN-Avo;
                font-style   : normal;
                font-weight  : bold;
                font-size    : 14px;
                line-height  : 1.11;
                text-align   : center;
                color        : #FFFFFF;
                min-height   : 38px;
                min-width    : 190px;
                background   : linear-gradient(89.34deg, #FF4913 1.12%, #FF5C00 95.42%);
                border-radius: 50px;
                padding      : 12px 112px;
                border       : none;
                outline      : none;
                opacity      : 1;

                &:hover {
                    opacity: 0.9;
                }

                @media (max-width: 767px) {
                    padding: 12px 95px;
                }
            }
        }

        .box-1 {
            margin-bottom: 50px;
        }

        .box-2 {
            .wrapper-content {
                margin-right: 0;

                .col1 {
                    width: 13vw;

                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }

                .col2 {
                    width: 33vw;

                    @media (max-width: 1919px) {
                        width: 38vw;
                    }

                    @media (max-width: 1659px) {
                        width: 40vw;
                    }

                    @media (max-width: 1549px) {
                        width: 43vw;
                    }

                    @media (max-width: 1439px) {
                        width: 50vw;
                    }

                    @media (max-width: 1199px) {
                        width: 55vw;
                    }

                    @media (max-width: 991px) {
                        width: 74vw;
                    }

                    @media (max-width: 767px) {
                        width: 100%;
                    }

                }

                .o-input-1 {
                    font-family: SVN-Avo;
                    font-style : normal;
                    font-weight: normal;
                    font-size  : 14px;
                    line-height: 1.11;
                    /* or 16px */
                    color      : #646464;

                    @media (max-width: 1199px) {
                        font-size: 12px;
                    }
                }

                .o-input-2 {
                    font-family: SVN-Avo;
                    font-style : normal;
                    font-weight: bold;
                    font-size  : 14px;
                    line-height: 111%;
                    /* or 16px */
                    color      : #FF7802;

                    @media (max-width: 1199px) {
                        font-size: 12px;
                    }
                }
            }

        }
    }

    textarea {
        resize: none;
    }
}