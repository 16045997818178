@import '../../styles/variables';
@import '../../styles/mixin';

.register-step6 {

    .iframe {
        border-radius: 5px;
    }

    .contract-mobile {
        @media (max-width: 576px) {
            display: block !important;
        }

        @media (min-width: 577px) {
            display: none !important;
        }
    }

    .contract-laptop {
        @media (max-width: 576px) {
            display: none !important;
        }

        @media (min-width: 577px) {
            display: block !important;
        }
    }

    ol {
        padding-left: 10px !important;
    }

    ul {
        li {
            list-style-type: lower-alpha;
        }
    }

    .row_container {
        padding   : 50px 8% 250px;
        margin    : 0 18%;
        background: #E5E5E5;

        @media screen and (max-width: 1919px) {
            margin : 0 14%;
            padding: 50px 8% 150px;
        }

        @media (max-width: 1199px) {
            margin : 0 5%;
            padding: 50px 5% 150px;
        }

        @media (max-width: 991px) {
            margin : 0;
            padding: 50px 2% 150px;
        }

        @media (max-width: 767px) {
            margin : 0;
            padding: 50px 5% 50px;
        }


        .box-tieude {
            text-align: center;

            .title-row {
                font-family   : $heading-font;
                font-style    : normal;
                font-weight   : bold;
                font-size     : 30px;
                line-height   : 1.11;
                text-align    : center;
                text-transform: uppercase;
                color         : #283991;
                padding-bottom: 15px;
                position      : relative;

                @media (max-width: 767px) {
                    font-size: 28px;
                }

                @media (max-width: 413px) {
                    font-size: 25px;
                }

                &:after {
                    content   : '';
                    position  : absolute;
                    bottom    : 0;
                    left      : 0;
                    width     : 100%;
                    height    : 1px;
                    background: linear-gradient(90deg, rgba(193, 193, 193, 0) 0.06%, #C1C1C1 53.4%, rgba(193, 193, 193, 0) 98.54%);
                    z-index   : 5;
                }
            }

            .box-thong-tin {
                .lien-he {
                    font-family  : $body-font;
                    font-style   : normal;
                    font-weight  : bold;
                    font-size    : 13px;
                    line-height  : 1.54;
                    text-align   : center;
                    color        : #212121;
                    padding-top  : 5px;
                    margin-bottom: 10px;

                    a {
                        color: #283991;
                    }
                }

                .o-p {
                    font-family: $body-font;
                    font-style : normal;
                    font-weight: bold;
                    font-size  : 13px;
                    line-height: 1.54;
                    text-align : center;
                    color      : #283991;
                }
            }
        }

        .tien-trinh-dang-ky {
            padding: 50px 0 63px 0;
        }

        .chu-y {
            font-family   : $body-font2;
            font-style    : normal;
            font-weight   : bold;
            font-size     : 14px;
            line-height   : 1.43;
            color         : #FF7802;
            padding-bottom: 40px;
        }

        .box-content {
            display: flex;
            width  : 100%;

            @media (max-width: 727px) {
                width  : 100%;
                display: block;
            }
        }

        .box-1 {
            width   : 18.75vw;
            position: relative;

            @media (max-width: 1549px) {
                width: 20vw;
            }

            @media (max-width: 1199px) {
                width: 30vw;
            }

            @media (max-width: 991px) {
                width: 35vw;
            }

            @media (max-width: 727px) {
                width         : 100%;
                padding-bottom: 30px;
            }

            &:after {
                content   : '';
                position  : absolute;
                right     : 0;
                top       : 0;
                width     : 1px;
                height    : 100%;
                background: linear-gradient(180deg, rgba(193, 193, 193, 0) 0.06%, #C1C1C1 53.4%, rgba(193, 193, 193, 0) 98.54%);
                z-index   : 5;
            }

            .tieu-de-box-1 {
                font-family            : $body-font2;
                font-style             : normal;
                font-weight            : bold;
                font-size              : 14px;
                line-height            : 1.43;
                /* or 143% */
                background             : -webkit-linear-gradient(89.3deg, #283991 13.41%, #3298D1 118%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                padding-bottom         : 35px;

                @media (max-width: 767px) {
                    font-size: 18px;
                }

                @media (max-width: 413px) {
                    font-size: 18px;
                }
            }

            .noi-dung-1 {
                position: relative;

                span {
                    font-family         : $body-font;
                    font-style          : normal;
                    font-weight         : 325;
                    font-size           : 14px;
                    line-height         : 1.43;
                    text-decoration-line: underline;
                    color               : #1E2F86;
                }

                margin-left  : -10px;
                display      : block;
                margin-bottom: 20px;

                img {
                    position: absolute;
                    left    : 0;
                    top     : 7px;
                }
            }

            .background-a {
                background: #DDDDDD;
                display   : inline-block;
                padding   : 0 30px 7px 15px;
            }

            .noi-dung-11 {
                position: relative;

                span {
                    font-family         : $body-font;
                    font-style          : normal;
                    font-weight         : 325;
                    font-size           : 14px;
                    line-height         : 1.43;
                    text-decoration-line: underline;
                    color               : #1E2F86;
                    padding             : 0 50px 0 15px;
                    display             : block;
                }

                margin-left  : -10px;
                display      : block;
                margin-bottom: 20px;

                img {
                    position: absolute;
                    left    : 0;
                    top     : 7px;
                }
            }

            .noi-dung-2 {
                position     : relative;
                margin-left  : -10px;
                display      : flex;
                margin-bottom: 20px;

                p {
                    font-family: $body-font;
                    font-style : normal;
                    font-weight: 325;
                    font-size  : 14px;
                    line-height: 1.43;
                    text-align : justify;
                    color      : #000000;
                    padding    : 0 50px 0 3px;
                    display    : block;
                }

                img {
                    position: absolute;
                    left    : 0;
                    top     : 7px;
                }


                /* Customize the label (the container) */
                .container-checkmark {
                    display            : block;
                    position           : relative;
                    padding-left       : 15px;
                    margin-bottom      : 12px;
                    cursor             : pointer;
                    font-size          : 22px;
                    -webkit-user-select: none;
                    -moz-user-select   : none;
                    -ms-user-select    : none;
                    user-select        : none;

                    span {
                        padding: 0;
                    }
                }

                /* Hide the browser's default checkbox */
                .container-checkmark input {
                    position: absolute;
                    opacity : 0;
                    cursor  : pointer;
                    height  : 0;
                    width   : 0;
                }

                /* Create a custom checkbox */
                .checkmark {
                    position        : absolute;
                    top             : 5px;
                    left            : 0px;
                    height          : 13px;
                    width           : 13px;
                    background-color: #FF6409;
                }

                /* On mouse-over, add a grey background color */
                .container-checkmark:hover input~.checkmark {
                    // background-color: #eee;
                }

                /* When the checkbox is checked, add a blue background */
                .container-checkmark input:checked~.checkmark {
                    background-color: #FF6409;
                }

                /* Create the checkmark/indicator (hidden when not checked) */
                .checkmark:after {
                    content : "";
                    position: absolute;
                    display : none;
                }

                /* Show the checkmark when checked */
                .container-checkmark input:checked~.checkmark:after {
                    display: block;
                }

                /* Style the checkmark/indicator */
                .container-checkmark .checkmark:after {
                    left             : 5px;
                    top              : 1px;
                    width            : 5px;
                    height           : 10px;
                    border           : solid white;
                    border-width     : 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform    : rotate(45deg);
                    transform        : rotate(45deg);
                }
            }

            button:disabled,
            button[disabled] {
                cursor: no-drop;

                span {
                    color: grey;
                }
            }

            .xac-nhan {
                position  : relative;
                display   : inline-block;
                border    : none;
                outline   : none;
                background: transparent;



                span {
                    position   : absolute;
                    top        : 50%;
                    left       : 50%;
                    transform  : translate(-50%, -50%);
                    font-family: $body-font2;
                    font-style : normal;
                    font-weight: bold;
                    font-size  : 10px;
                    line-height: 1.50;
                    /* identical to box height, or 150% */
                    text-align : center;
                    color      : #212121;
                    display    : block;
                }
            }
        }

        .box-2 {
            width     : 31.25vw;
            overflow-y: scroll;
            height    : 500px;
            direction : rtl;

            @media (max-width: 1919px) {
                width: 35vw;
            }

            @media (max-width: 1199px) {
                width: 50vw;
            }

            @media (max-width: 991px) {
                width: 60vw;
            }

            @media (max-width: 727px) {
                width: 100%;
            }

            .tieu-de-box-2 {
                font-family   : $body-font2;
                font-style    : normal;
                font-weight   : bold;
                font-size     : 14px;
                line-height   : 1.11;
                text-align    : center;
                color         : #000000;
                padding-left  : 15px;
                padding-bottom: 20px;
            }

            .noi-dung {
                font-family : $body-font;
                font-style  : normal;
                font-weight : 325;
                font-size   : 14px;
                line-height : 1.11;
                text-align  : justify;
                color       : #000000;
                padding-left: 15px;
            }

            .Flipped {
                direction: ltr;
            }
        }

        /* width */
        ::-webkit-scrollbar {
            width: 7px;

        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: none;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background   : #C4C4C4;
            opacity      : 0.5;
            border-radius: 2px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #C4C4C4;
        }

        .note-create-success {
            position        : relative;
            padding-top     : 35px;
            padding-left    : 45px;
            padding-right   : 45px;
            padding-bottom  : 35px;
            min-height      : 240px;
            background-color: white;
            max-width       : 530px;
            text-align      : center;
            border-radius   : 10px;
            margin          : 0 auto;

            justify-content: center;
            align-items    : center;
            display        : flex;
            color          : #FF6209;
            font-size      : 18px;

        }
    }

}


.modal-otp {
    max-width: 650px;
}

.modal-contract {
    max-width: 90vw;
    height   : 90vh;

    .close-btn {
        position : absolute;
        right    : 5px;
        font-size: 18px;
        color    : orange;
        cursor   : pointer;

        &:hover {
            opacity: 0.9;
        }
    }

    .content-modal-contract {
        width : 90vw;
        height: 90vh;
    }
}

.modal-otp,
.modal-open-acc,
.modal-sign-acc,
.modal-contract {

    border-radius: 10px;

    .modal-content {
        border-radius: 10px !important;
        box-shadow   : none !important;
        border       : none !important;
    }

    button:disabled,
    button[disabled] {
        cursor: no-drop !important;
    }

    .thong-bao-popup {
        position        : relative;
        padding-top     : 35px;
        padding-left    : 45px;
        padding-right   : 45px;
        padding-bottom  : 35px;
        min-height      : 240px;
        background-color: white;
        max-width       : 530px;
        text-align      : center;
        border-radius   : 10px;



        .input-info {
            font-size   : 12px;
            color       : #FF4913;
            text-align  : left;
            padding-left: 15px;
            margin-top  : 5px;
            text-align  : center;
        }

        .img-icon-close-modal {
            position                 : absolute;
            right                    : 0;
            top                      : 0;
            width                    : 20px;
            height                   : 20px;
            display                  : block;
            // // background-image   : url(../images/icon-x-circle-orange.png);
            background-size          : 100% auto;
            background-repeat        : no-repeat;
            background-position      : center center;
            transform                : translate(50%, -50%);
            cursor                   : pointer;
        }

        .img-icon {
            margin-bottom: 25px;
        }

        .text-gradient {
            background             : linear-gradient(179.98deg, #283991 0.02%, #3298D1 99.98%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .o-h3 {
            font-weight   : bold;
            font-size     : 14px;
            line-height   : 143%;
            margin-bottom : 20px;
            font-family   : $body-font2;
            text-transform: uppercase;
        }

        .o-h4 {
            font-weight   : bold;
            font-size     : 18px;
            line-height   : 111%;
            margin-bottom : 20px;
            text-transform: uppercase;
            font-family   : $body-font2;
        }

        .o-wysiwyg {
            text-align : justify;
            font-weight: bold;
            font-size  : 14px;
            line-height: 143%;

            ul {
                list-style  : none;
                padding-left: 10px;

                li {
                    // position: relative;

                    &::before {
                        position              : relative;
                        width                 : 6px;
                        height                : 6px;
                        // // background-image: url(../images/icon-li-mark-blue.png);
                        content               : '';
                        display               : inline-block;
                        margin-left           : -14px;
                        margin-right          : 7px;
                    }
                }
            }


        }

        &.popup-text-red {
            display        : flex;
            align-items    : center;
            justify-content: center;

            .o-wysiwyg {
                font-weight: 450;
                font-size  : 15px;
                line-height: 133%;
                color      : #FF4913;
            }
        }

        &.popup-text-black {
            padding-left : 15px;
            padding-right: 15px;
            max-width    : 550px;

            &::before {
                display: none;
            }

            .o-wysiwyg {
                color        : #212121;
                text-align   : center;
                margin-bottom: 30px;

                &.row-lower {
                    color: #797979;
                }
            }

            .btn-manhtq {
                margin-left : auto;
                margin-right: auto;
                font-size   : 14px;
                min-height  : 43px;
            }

            &.row-narrow {
                .o-wysiwyg {
                    &.row-upper {
                        margin-bottom: 15px;
                    }
                }
            }


            &.text-light {
                .o-wysiwyg {
                    font-weight: normal;

                    &.row-lower {
                        color: #7D7D7D;
                    }
                }
            }

        }

        .btn-wrapper {
            display        : flex;
            justify-content: center;

            .btn-manhtq {
                min-width   : 135px;
                margin-left : 10px;
                margin-right: 10px;
                font-weight : bold;
            }
        }

        &.popup-otp {
            max-width: 650px;

            .input-wrapper {
                position: relative;

                @media (max-width: 767px) {
                    max-width: 250px;

                }

                @media (max-width: 320px) {
                    max-width: 200px;

                }

                max-width : 370px;

                margin-left  : auto;
                margin-right : auto;
                margin-bottom: 25px;


                .input-label {
                    background-color: white;
                    position        : absolute;
                    left            : 25px;
                    top             : -20px;
                    font-size       : 12px;
                    color           : #FF4913;
                    padding         : 10px;
                }


                .input-label-time {
                    top: 15px !important;
                }

                input {
                    display      : block;
                    width        : 100%;
                    border-radius: 10px;
                    border       : 2px solid #FF4913;
                    min-height   : 60px;
                    padding      : 10px;
                    outline      : none;
                }

                &.input-otp {
                    .input-label {
                        left: 15px;
                    }
                }

                .remaining-time {
                    font-weight: 325;
                    font-size  : 14px;
                    color      : #FF4913;
                    line-height: 140%;
                    position   : absolute;
                    bottom     : 10px;
                    right      : -75px;

                    @media (max-width: 767px) {
                        right: -52px;
                    }

                    @media (max-width: 320px) {
                        right: -52px;
                    }


                    display     : inline-block;
                    padding-top : 17px;
                    padding-left: 20px;

                    &::before {
                        content            : '';
                        width              : 28px;
                        height             : 32px;
                        display            : block;
                        background-size    : 100% auto;
                        background-repeat  : no-repeat;
                        background-position: center center;
                        background-image   : url(../../assets/images/register/icon-clock-orange.png);

                        position: absolute;
                        left    : 0;
                        top     : 0;
                    }
                }

                i {
                    color      : black;
                    position   : relative;
                    top        : -60px;
                    /* left    : -16px; */
                    display    : block;
                    float      : right;
                    cursor     : pointer;
                    line-height: 40px;
                    padding    : 10px;
                }
            }

            .resend-otp {
                font-weight    : bold;
                color          : #FF4913;
                font-size      : 14px;
                opacity        : 0.6;
                display        : inline-flex;
                justify-content: center;
                align-items    : center;
                margin-bottom  : 25px;
                cursor         : pointer;

                img {
                    margin-right: 5px;
                }
            }
        }



        .btn-manhtq {
            border-radius: 50px;
            max-width    : 297px;
            display      : block;

            font-family    : $heading-font;
            font-style     : normal;
            font-weight    : normal;
            font-size      : 20px;
            line-height    : 111%;
            text-align     : center;
            color          : #212121;
            min-height     : 2.15em;
            display        : flex;
            justify-content: center;
            align-items    : center;
            padding-left   : 7em;
            padding-right  : 7em;

            @include mobile {
                padding-left : 7em;
                padding-right: 7em;
            }

            &:hover {
                cursor: pointer;
            }

            span {
                color: inherit;
            }

            &.btn-grey {
                background: #DFDFDF;
            }

            &.btn-grey-bang-gia-1 {
                background: #C4C4C4;
                color     : #727272;
            }

            &.btn-orange {
                background: linear-gradient(89.34deg, #FF4913 1.12%, #FF5C00 95.42%);
                color     : white;
            }

            &.btn-transparent {
                border: 1px solid #121212;
            }

            &.btn-text2 {
                font-family   : $body-font2;
                font-style    : normal;
                font-weight   : bold;
                font-size     : 13px;
                line-height   : 106.19%;
                text-transform: uppercase;
                min-height    : 3.08em;
                white-space   : nowrap;
                margin-bottom : 15px;
            }

            &.btn-square {
                border       : 1px solid #B6B6B6;
                border-radius: 2px;
                font-weight  : bold;
                font-size    : 18px;
                color        : #535353;
                margin-bottom: 15px;
                min-height   : 2.7em;
                max-width    : unset;
            }

            &.btn-blue {
                background: #283991;
                color     : white;
            }

            &.btn-bo-loc {
                min-width: 140px;

                &::before {
                    content               : '';
                    display               : inline-block;
                    width                 : 15px;
                    height                : 10px;
                    margin-right          : 10px;
                    // background-image   : url(../images/icon-filter-white.png);
                    background-repeat     : no-repeat;
                    background-size       : 100% auto;
                    background-position   : center center;
                }
            }

            &.btn-dang-ky {
                display: inline-flex;

                &::before {
                    content               : '';
                    width                 : 16px;
                    height                : 16px;
                    display               : inline-block;
                    // background-image   : url(../images/icon-add-friend-white.png);
                    background-repeat     : no-repeat;
                    background-size       : 100% auto;
                    background-position   : center center;
                    margin-right          : 10px;
                    flex-shrink           : 0;
                }
            }

            &.btn-dat-mua {
                display      : inline-flex;
                min-height   : 2.39em;
                padding-right: 3em;

                &::before {
                    content               : '';
                    width                 : 22px;
                    height                : 17px;
                    display               : inline-block;
                    // background-image   : url(../images/icon-gio-hang.png);
                    background-repeat     : no-repeat;
                    background-size       : 100% auto;
                    background-position   : center center;
                    margin-right          : 10px;
                    flex-shrink           : 0;
                }
            }

            &.btn-green {
                background   : #009400;
                border-radius: 5px;
                color        : white;
                font-size    : inherit;
                min-height   : 1.92em;
                margin-top   : 2px;
                margin-bottom: 2px;
                min-width    : 90px;
            }
        }

    }

}