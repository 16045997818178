@import "../styles/common.scss";

.main-container {
      font-family      : 'Roboto Condensed', tahoma, Arial, Helvetica, sans-serif;
      // display       : flex;
      // flex-grow     : 1;
      // flex-direction: column;
      // max-width     : 100%;

      position: relative;
}

.content-container {
      position        : absolute;
      width           : 100%;
      background-color: $body_rgb;
      height          : calc(100% - 200px);
      margin-top      : 70px;
}

.diplay_flex {
      display: flex;
}

/* CSS: padding */

.col-fix-0 {
      padding: 0px !important;
}

.col-fix-5 {
      padding: 5px !important;
}

.pdt-0 {
      padding-top: 0px !important
}

.pdbt-0 {
      padding-bottom: 0px !important
}

.pdl-0 {
      padding-left: 0px !important
}

.pdr-0 {
      padding-right: 0px !important
}

.pdt-5 {
      padding-top: 5px !important
}

.pdbt-5 {
      padding-bottom: 5px !important
}

.pdl-5 {
      padding-left: 5px !important
}

.pdr-5 {
      padding-right: 5px !important
}

.pdt-8 {
      padding-top: 8px !important
}

.pdbt-8 {
      padding-bottom: 8px !important
}

.pdl-8 {
      padding-left: 8px !important
}

.pdr-8 {
      padding-right: 8px !important
}

.pdt-10 {
      padding-top: 10px !important
}

.pdbt-10 {
      padding-bottom: 10px !important
}

.pdl-10 {
      padding-left: 10px !important
}

.pdr-10 {
      padding-right: 10px !important
}

.pdt-15 {
      padding-top: 15px !important
}

.pdbt-15 {
      padding-bottom: 15px !important
}

.pdl-15 {
      padding-left: 15px !important
}

.pdr-15 {
      padding-right: 15px !important
}

/* CSS: magrin */
.mgt-0 {
      margin-top: 0px !important
}

.mgbt-0 {
      margin-bottom: 0px !important
}

.mgl-0 {
      margin-left: 0px !important
}

.mgr-0 {
      margin-right: 0px !important
}

.mgt-5 {
      margin-top: 5px !important
}

.mgbt-5 {
      margin-bottom: 5px !important
}

.mgl-5 {
      margin-left: 5px !important
}

.mgr-5 {
      margin-right: 5px !important
}

.mgt-10 {
      margin-top: 10px !important
}

.mgbt-10 {
      margin-bottom: 10px !important
}

.mgl-10 {
      margin-left: 10px !important
}

.mgr-10 {
      margin-right: 10px !important
}

.mgt-15 {
      margin-top: 15px !important
}

.mgbt-15 {
      margin-bottom: 15px !important
}

.mgl-15 {
      margin-left: 15px !important
}

.mgr-15 {
      margin-right: 15px !important
}



.textL {
      text-align: left !important;
}

.textC {
      text-align: center !important;
}

.textR {
      text-align: right !important;
}

.floatL {
      float: left !important;
}

.floatR {
      float: right !important;
}




.Widget {
      // border       : 1px solid rgb(72, 72, 72);
      border-radius: 5px;

      .Hearder-Widget {
            height             : 40px !important;
            color              : #fff;
            // background-color: rgb(68, 68, 68);
            background-color   : #121212;
            border-radius      : 5px 5px 0px 0px;

            .header_title {
                  line-height: 40px;
                  margin-left: 10px;
                  font-size  : 16px;
                  font-weight: bold;
            }

            .header_time {
                  line-height : 40px;
                  margin-right: 30px;
            }
      }

      .Body-Widget {
            height             : calc(100% - 40px) !important;
            // background-color: rgb(35, 35, 35);
            background-color   : #252525;
            border-radius      : 0px 0px 5px 5px;

            .Body-Widget-Content {
                  margin-left : 5px;
                  margin-right: 5px;
            }
      }

      .txt_connected {
            // color: #33e433;
            color: #19D9A0;
      }

      .txt_active {
            color: #34c9d8;
      }

      .txt_disconnected {
            // color: #e63830;
            color: #ff3737;
      }

      .txt_defautl {
            color: #fff;
      }

      .icon_status {
            font-size    : 12px;
            padding-right: 5px;
      }

      .icon_status_text {
            font-size   : 14px;
            padding-left: 2px;
      }

      .div_header_table {
            color: rgb(160, 160, 160);
      }

      .Footer-Widget {
            height          : 44px !important;
            line-height     : 44px;
            background-color: rgb(30, 30, 30);
            border-radius   : 0px 0px 5px 5px;
            margin-top      : -44px !important;

            .icon_status_text {
                  font-size: 16px;
            }

            i {
                  font-size: 13px;
            }
      }
}

#loaderDiv {
      /* display: inline; */
      position  : absolute;
      width     : 100%;
      height    : 100vh;
      background: #545454;
      opacity   : 0.6;
      z-index   : 9999;
}

.display_none {
      display: none !important;
}