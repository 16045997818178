/* Font */
@font-face {
    font-family: 'UTM Avo';
    src        : url('./fonts/UTMAvo.eot');
    src        : url('./fonts/UTMAvo.eot?#iefix') format('embedded-opentype'),
        url('./fonts/UTMAvo.woff2') format('woff2'),
        url('./fonts/UTMAvo.woff') format('woff'),
        url('./fonts/UTMAvo.ttf') format('truetype');
    font-weight : normal;
    font-style  : normal;
    font-display: swap;
}

@font-face {
    font-family: 'UTM Avo';
    src        : url('./fonts/UTMAvo-Italic.eot');
    src        : url('./fonts/UTMAvo-Italic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/UTMAvo-Italic.woff2') format('woff2'),
        url('./fonts/UTMAvo-Italic.woff') format('woff'),
        url('./fonts/UTMAvo-Italic.ttf') format('truetype');
    font-weight : normal;
    font-style  : italic;
    font-display: swap;
}

@font-face {
    font-family: 'UTM Avo';
    src        : url('./fonts/UTMAvo-BoldItalic.eot');
    src        : url('./fonts/UTMAvo-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/UTMAvo-BoldItalic.woff2') format('woff2'),
        url('./fonts/UTMAvo-BoldItalic.woff') format('woff'),
        url('./fonts/UTMAvo-BoldItalic.ttf') format('truetype');
    font-weight : bold;
    font-style  : italic;
    font-display: swap;
}


@font-face {
    font-family: 'SVN-Avo';
    src        : url('./fonts/SVN-Avo.eot');
    src        : url('./fonts/SVN-Avo.eot?#iefix') format('embedded-opentype'),
        url('./fonts/SVN-Avo.woff2') format('woff2'),
        url('./fonts/SVN-Avo.woff') format('woff'),
        url('./fonts/SVN-Avo.ttf') format('truetype');
    font-weight : normal;
    font-style  : normal;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Avo';
    src        : url('./fonts/SVN-Avoitalic.eot');
    src        : url('./fonts/SVN-Avoitalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/SVN-Avoitalic.woff2') format('woff2'),
        url('./fonts/SVN-Avoitalic.woff') format('woff'),
        url('./fonts/SVN-Avoitalic.ttf') format('truetype');
    font-weight : normal;
    font-style  : italic;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Avo';
    src        : url('./fonts/SVN-Avobold.eot');
    src        : url('./fonts/SVN-Avobold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/SVN-Avobold.woff2') format('woff2'),
        url('./fonts/SVN-Avobold.woff') format('woff'),
        url('./fonts/SVN-Avobold.ttf') format('truetype');
    font-weight : bold;
    font-style  : normal;
    font-display: swap;
}


@font-face {
    font-family: 'SVN-Avo';
    src        : url('./fonts/SVN-Avobolditalic.eot');
    src        : url('./fonts/SVN-Avobolditalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/SVN-Avobolditalic.woff2') format('woff2'),
        url('./fonts/SVN-Avobolditalic.woff') format('woff'),
        url('./fonts/SVN-Avobolditalic.ttf') format('truetype');
    font-weight : bold;
    font-style  : italic;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Gotham';
    font-style : normal;
    font-weight: 300;
    src        : url('./fonts/SVN-GothamLight.eot');
    src        : url('./fonts/SVN-GothamLight.eot?#iefix') format('embedded-opentype'),
        url('./fonts/SVN-GothamLight.woff2') format('woff2'),
        url('./fonts/SVN-GothamLight.woff') format('woff'),
        url('./fonts/SVN-GothamLight.ttf') format('truetype'),
        url('./fonts/SVN-GothamLight.svg#SVN-GothamLight') format('svg');
}

@font-face {
    font-family: 'SVN-Gotham';
    src        : url('./fonts/SVN-GothamLightItalic.eot');
    src        : url('./fonts/SVN-GothamLightItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/SVN-GothamLightItalic.woff2') format('woff2'),
        url('./fonts/SVN-GothamLightItalic.woff') format('woff'),
        url('./fonts/SVN-GothamLightItalic.ttf') format('truetype');
    font-weight : 300;
    font-style  : italic;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Gotham';
    font-style : normal;
    font-weight: 500;
    src        : url('./fonts/SVN-Gotham.eot');
    src        : url('./fonts/SVN-Gotham.eot?#iefix') format('embedded-opentype'),
        url('./fonts/SVN-Gotham.woff2') format('woff2'),
        url('./fonts/SVN-Gotham.woff') format('woff'),
        url('./fonts/SVN-Gotham.ttf') format('truetype'),
        url('./fonts/SVN-Gotham.svg#SVN-Gotham') format('svg');
}

@font-face {
    font-family: 'SVN-Gotham';
    font-style : normal;
    font-weight: bold;
    src        : url('./fonts/SVN-GothamBold.eot');
    src        : url('./fonts/SVN-GothamBold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/SVN-GothamBold.woff2') format('woff2'),
        url('./fonts/SVN-GothamBold.woff') format('woff'),
        url('./fonts/SVN-GothamBold.ttf') format('truetype'),
        url('./fonts/SVN-GothamBold.svg#SVN-GothamBold') format('svg');
}


@font-face {
    font-family: 'SVN-Gotham';
    src        : url('./fonts/SVN-GothamBlack.eot');
    src        : url('./fonts/SVN-GothamBlack.eot?#iefix') format('embedded-opentype'),
        url('./fonts/SVN-GothamBlack.woff2') format('woff2'),
        url('./fonts/SVN-GothamBlack.woff') format('woff'),
        url('./fonts/SVN-GothamBlack.ttf') format('truetype');
    font-weight : 900;
    font-style  : normal;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Gotham';
    src        : url('./fonts/SVN-GothamBlackItalic.eot');
    src        : url('./fonts/SVN-GothamBlackItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/SVN-GothamBlackItalic.woff2') format('woff2'),
        url('./fonts/SVN-GothamBlackItalic.woff') format('woff'),
        url('./fonts/SVN-GothamBlackItalic.ttf') format('truetype');
    font-weight : 900;
    font-style  : italic;
    font-display: swap;
}


@font-face {
    font-family: 'SVN-Gotham Book';
    src        : url('./fonts/SVN-GothamBook.eot');
    src        : url('./fonts/SVN-GothamBook.eot?#iefix') format('embedded-opentype'),
        url('./fonts/SVN-GothamBook.woff2') format('woff2'),
        url('./fonts/SVN-GothamBook.woff') format('woff'),
        url('./fonts/SVN-GothamBook.ttf') format('truetype');
    font-weight : normal;
    font-style  : normal;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Gotham Book';
    src        : url('./fonts/SVN-GothamBookItalic.eot');
    src        : url('./fonts/SVN-GothamBookItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/SVN-GothamBookItalic.woff2') format('woff2'),
        url('./fonts/SVN-GothamBookItalic.woff') format('woff'),
        url('./fonts/SVN-GothamBookItalic.ttf') format('truetype');
    font-weight : normal;
    font-style  : italic;
    font-display: swap;
}

@font-face {
    font-family: 'UTM Bebas';
    src        : url('./fonts/UTMBebas.eot');
    src        : url('./fonts/UTMBebas.eot?#iefix') format('embedded-opentype'),
        url('./fonts/UTMBebas.woff2') format('woff2'),
        url('./fonts/UTMBebas.woff') format('woff'),
        url('./fonts/UTMBebas.ttf') format('truetype');
    font-weight : normal;
    font-style  : normal;
    font-display: swap;
}