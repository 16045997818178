@import "../../../styles/common";


@mixin active-text-color {
  @include themify($themes) {
    color: themed("section-body-text-normal");
  }
}

.account-register-container {

  .flow-Create-acc {
    .wizard {
      margin-bottom: 10px;

      .col {
        width: 20% !important;
      }

      .step {
        &:before {
          border-color: grey;
          background  : grey;
          color       : white;
        }

        &:after {
          border-color: grey;
          background  : grey;
          color       : white;
        }

        .step-icon {
          border-color: grey;
          background  : grey;
          color       : white;

          font-size    : $font-size-medium;
          height       : 36px;
          line-height  : 36px;
          margin       : 0 auto 5px auto;
          text-align   : center;
          width        : 36px;
          border-radius: 50%;

          @media (max-width: 767px) {
            height     : 25px;
            line-height: 25px;
            width      : 25px;
          }
        }
      }

      .step-first {

        // @media (max-width: 767px) {
        //   width: auto;
        // }

        // width: 100px;

        &:after {
          content : "";
          height  : 4px;
          position: absolute;
          right   : 0;
          top     : 15px;
          @include calc("width", "50% - 18px");

          @media (max-width: 767px) {
            @include calc("width", "50% - 13px");
            top: 10px !important;
          }

          @media (max-width: 320px) {
            @include calc("width", "50% - 12px");
            top: 10px !important;
          }
        }

        &.active {
          .step-legend {
            color: #212121;
          }

          .step-icon {
            background: #FF7802;
          }

          .step-legend {
            color: #212121;
          }
        }

        &.actived {
          .step-legend {
            color: #212121;
          }

          .step-icon {
            background: #FF7802;
          }

          &:after {

            background: #FF7802;

          }
        }
      }

      .step-middle {
        color: #212121;

        &:before {
          content : "";
          height  : 4px;
          left    : 0;
          position: absolute;
          top     : 15px;
          @include calc("width", "50% - 18px");

          @media (max-width: 767px) {
            @include calc("width", "50% - 13px");
            top: 10px !important;
          }

          @media (max-width: 320px) {
            @include calc("width", "50% - 12px");
            top: 10px !important;
          }
        }

        &:after {
          content : "";
          height  : 4px;
          position: absolute;
          right   : 0;
          top     : 15px;
          @include calc("width", "50% - 18px");

          @media (max-width: 767px) {
            @include calc("width", "50% - 13px");
            top: 10px !important;
          }

          @media (max-width: 320px) {
            @include calc("width", "50% - 12px");
            top: 10px !important;
          }
        }
      }

      .step-last {
        // width: 100px;
        color: #212121;

        &:before {
          content : "";
          height  : 4px;
          left    : 0;
          position: absolute;
          top     : 15px;
          @include calc("width", "50% - 18px");

          @media (max-width: 767px) {
            @include calc("width", "50% - 13px");
            top: 10px !important;
          }

          @media (max-width: 320px) {
            @include calc("width", "50% - 12px");
            top: 10px !important;
          }
        }

        &.active {
          .step-icon {
            background: #FF7802;
          }

          .step-legend {
            color: #212121;
          }

          &:before {

            background: #FF7802;

          }
        }
      }

      .active {
        cursor: pointer;

        .step-legend {
          color: #212121;
        }

        .step-icon {

          background: #FF7802;

        }

        &:before {

          background: #FF7802;

        }
      }

      .actived {
        cursor: pointer;

        &:before {

          background: #FF7802;

        }

        &:after {

          background: #FF7802;

        }

        .step-icon {

          background: #FF7802;

        }

        .step-legend {
          color: #212121;
        }
      }

      .step-legend {
        @media (max-width: 767px) {
          font-size: 10px;
        }

        font-size : $font-size-medium;
        text-align: center;
      }
    }
  }

}