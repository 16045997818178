@import "common";

.custom-form-group {

  .custom-form-control {
    color: #000;
    background-color: #ccc;
    border: 1px solid $border;
    &.readonly, &:disabled {
      cursor: not-allowed;
      background-color: #9a9a9a;
    }
    &:focus {
      outline: none;
      border: 1px solid lighten($border, 50);
    }
  }

  select.custom-form-control {
    background-image: $dropdown-image;
  }

  margin-bottom: 10px;

  label {
    margin-bottom: 1px;
  }

  .custom-form-control {
    display: block;
    width: 100%;
    height: 28px;
    padding: 0 5px;
    line-height: 26px;
    border-radius: 5px;
  }

  textarea.custom-form-control {
    min-height: 28px;
    height: auto;
  }

  select.custom-form-control {
    -webkit-appearance: none;
    -moz-appearance: none;

    padding-right: 15px;

    background-position: right top;
    background-repeat: no-repeat;
    background-size: 15px 100%;

    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .custom-checkbox-control {
    line-height: 28px;

    input {
      cursor: pointer;
    }

    label {
      margin-left: 5px;
    }
  }

}
